import React, { ReactNode, Ref } from 'react';

// material-ui
import { Box, CardContent, CardContentProps, CardProps, CircularProgress, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps {
    border?: boolean;
    boxShadow?: boolean;
    children: React.ReactNode | string;
    style?: React.CSSProperties;
    content?: boolean;
    className?: string;
    contentClass?: string;
    contentSX?: CardContentProps['sx'];
    sx?: CardProps['sx'];
    Header?: ReactNode;
    shadow?: string | number;
    elevation?: number;
    title?: React.ReactNode | string;
    description?: React.ReactNode | string;
    isLoading?: boolean;
    transparent?: boolean;
    dense?: boolean;
    divider?: boolean;
}

const MainCard = React.forwardRef(
    (
        {
            border = false,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},

            Header,
            shadow,
            sx = {},
            isLoading = false,
            title,
            transparent = false,
            dense = true,
            description,
            divider = false,

            ...others
        }: MainCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const theme = useTheme();

        const p = dense ? 1.5 : 2;

        return (
            <Box
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid rgb(211, 226, 253)' : 'none',
                    borderRadius: { xs: 0, sm: 4 },
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[300] + 98,
                    height: '100%',
                    bgcolor: transparent ? 'transparent' : 'white',
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                              (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {(title || Header) && (
                    <Box
                        sx={{
                            ...headerSX,
                            p,
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: { xs: 1, sm: 0 }
                        }}
                    >
                        {title && (
                            <Box sx={{ display: 'flex', gap: 2, alignItems: { sm: 'center' } }}>
                                {title && <Typography variant={dense ? 'h3' : 'h2'}>{title}</Typography>}
                                {isLoading && <CircularProgress color="secondary" size={14} sx={{ my: 'auto' }} />}
                            </Box>
                        )}
                        <Box sx={{ flexGrow: 1 }}>{Header && Header}</Box>
                    </Box>
                )}

                {/* content & header divider */}
                {divider && title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={{ p, overflow: 'auto', ...contentSX }} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Box>
        );
    }
);

export default MainCard;
