import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import getMenuItems from 'menu-items';
import { useSelector } from 'store';
import { drawerWidth } from 'store/constant';

// assets
import { NavItemType } from 'types/index.types';
import { ChevronRight } from '@mui/icons-material';

// styles
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
//     ...theme.typography.mainContent,
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//     ...(!open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.shorter + 200
//         }),
//         [theme.breakpoints.up('md')]: {
//             marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : 0,
//             width: `calc(100% - ${drawerWidth}px)`,
//             marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 62 : 88
//         },
//         [theme.breakpoints.down('md')]: {
//             marginLeft: 10,
//             width: `calc(100% - ${drawerWidth}px)`,
//             marginTop: 88
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: 10,
//             marginRight: 10,
//             width: `calc(100% - ${drawerWidth}px)`,
//             marginTop: 88
//         }
//     }),
//     ...(open && {
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.shorter + 200
//         }),
//         marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
//         marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 62 : 88,
//         width: `calc(100% - ${drawerWidth}px)`,
//         [theme.breakpoints.up('md')]: {
//             marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 62 : 88
//         },
//         [theme.breakpoints.down('md')]: {
//             marginLeft: '20px',
//             marginTop: 88
//         },
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: '0px',
//             marginTop: 88
//         }
//     })
// }));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, layout } = useConfig();

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? 0 : '16px', px: condition ? '12px' : '8px' }} disableGutters>
                <Header />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    const [navigation, setNavigation] = useState<{ items: NavItemType[] }>({ items: [] });

    useEffect(() => {
        getMenuItems().then((result) => {
            setNavigation(result);
        });
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ bgcolor: theme.palette.background.default }}>
                {header}
            </AppBar>

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

            {/* main content */}
            <Box
                component={'main'}
                sx={{
                    ...theme.typography.mainContent,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    bgcolor: 'rgb(245, 248, 252)',
                    mt: 9.5,
                    pt: 1,
                    px: 1.25,
                    mx: '0 !important',
                    [theme.breakpoints.up('md')]: {
                        mt: 7.75
                    },
                    ...(!drawerOpen && {
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.shorter + 200
                        }),
                        width: `calc(100% - ${drawerWidth}px)`
                    }),
                    ...(drawerOpen && {
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.shorter + 200
                        }),
                        width: `calc(100% - ${drawerWidth}px)`
                    })
                }}
            >
                <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 }, height: '100%' } })}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={ChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Container>
            </Box>
        </Box>
    );
};

export default MainLayout;
