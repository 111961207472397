import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types/index.types';
import { RoleId } from 'types/user.types';
import Loader from 'ui-component/Loader';
import Error from 'views/pages/maintenance/Error';
import { isUserSuperAdmin } from 'helpers/users.helper';

type RoleGuardProps = GuardProps & {
    isAdminOnly?: boolean;
} & (
        | {
              allowedRoles?: RoleId[];
          }
        | {
              probhitedRoles?: RoleId[];
          }
    );

const RoleGuard = (props: RoleGuardProps) => {
    const { user } = useAuth();

    if (!user) return <Loader />;

    if ('isAdminOnly' in props) {
        if (props.isAdminOnly && isUserSuperAdmin(user.role!)) return props.children;
        else return <Error />;
    }
    if ('allowedRoles' in props) {
        if (props.allowedRoles!.some((role) => role === user.role)) return props.children;
        else return <Error />;
    } else if ('probhitedRoles' in props) {
        if (!props.probhitedRoles!.some((role) => role === user.role)) return props.children;
        else return <Error />;
    }

    return props.children;
};

export default RoleGuard;
