import { OrderFields, OrderFieldsEnum } from 'types/orders.types';

export const shuffleArray = (array: any[]) => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const openInNewTab = (url: string) => {
    const win = window.open(url, '_blank');
    if (win) win.focus();
};

export const slugToLabel = (slug: string) =>
    slug
        .toLowerCase()
        .split(/[-_.\s]/)
        .map((w) => `${w.charAt(0).toUpperCase()}${w.substr(1)}`)
        .join(' ');

export const loadCheck = ({ currentData, isFetching }: { isFetching: boolean; currentData?: any }) => {
    if (currentData === undefined && isFetching) return true;
    else return false;
};

export const handlePermissionCheck = (visibilityList: OrderFields | boolean, field: OrderFieldsEnum) =>
    // @ts-ignore
    typeof visibilityList === 'boolean' ? true : visibilityList[`${field}`];

export const decodeHtmlEntities = (string: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = string;
    return txt.value;
};

export const hexToRgbA = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
};

export const getTextContentOnly = (html: string) => {
    const regex = /(<([^>]+)>)/gi;
    return html.replace(regex, '');
};

export const hideToast = (hide: boolean | undefined) => (hide ? { display: 'none', padding: '0px !important' } : {});

export const syncLanguage = (lng: '') => {};
