// LEGACY CODE
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Box,
    Button,
    Grid2 as Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Controller, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAddFactoryMutation, useDeleteFactoryMutation, useEditFactoryMutation, useFetchFactoriesQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { Factory, FactorySchema } from 'types/global.types';
import MainCard from 'ui-component/cards/MainCard';
import ModalFormCard from 'ui-component/cards/ModalCard';
import { getMessage } from 'utils/messages';

const SystemFactoryPage = () => {
    const { control, handleSubmit } = useForm<Factory>({
        resolver: zodResolver(FactorySchema.omit({ id: true })),
        defaultValues: { abbr: undefined, name: undefined }
    });
    const { t } = useTranslation();

    const { data: factories } = useFetchFactoriesQuery({});

    const [addFactory] = useAddFactoryMutation();
    const [deleteFactory] = useDeleteFactoryMutation();

    const onSubmit: SubmitHandler<Factory> = (data) => {
        addFactory({
            factory_name: data.name,
            factory_name_abbreviation: data.abbr
        }).then(() => toast.success(getMessage('added')));
    };

    const handleDelete = (factoryId: number) => {
        Swal.fire({
            title: t('notifications.swal.title.are_you_sure'),
            text: t('notifications.swal.text.you_wont_be_able_to_revert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('notifications.swal.confirm_button.yes_delete_it')
        }).then((result) => {
            if (result.value) {
                deleteFactory({
                    factory_id: factoryId
                }).then(() => toast.success(t('notifications.success.deleted')));
            }
        });
    };

    return (
        <MainCard
            title={t('pages.factories_manage_page.title')}
            content={false}
            dense
            sx={{ width: '100%' }}
            Header={
                <Grid
                    container
                    spacing={2}
                    component={'form'}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        mx: 2
                    }}
                >
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.factories_manage_page.factory_name_field')}
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    label={t('pages.factories_manage_page.factory_abbr_field')}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Button size="small" variant="contained" fullWidth type="submit">
                            {t('global.buttons.add_new')}
                        </Button>
                    </Grid>
                </Grid>
            }
            divider
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pages.factories_manage_page.factory_name')}</TableCell>
                            <TableCell>{t('pages.factories_manage_page.factory_name_abbr')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {factories &&
                            factories.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.abbr}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => NiceModal.show(EditModal, { factory: row })}
                                                    sx={{ width: 80 }}
                                                >
                                                    {t('global.buttons.edit')}
                                                </Button>
                                                <Button variant="contained" color="error" onClick={() => handleDelete(row.id)}>
                                                    {t('global.buttons.delete')}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};

const EditModal = NiceModal.create(({ factory }: { factory: Factory }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<Factory>({
        resolver: zodResolver(FactorySchema.omit({ id: true })),
        defaultValues: factory
    });
    const [editFactory] = useEditFactoryMutation();

    const handleEdit: SubmitHandler<Factory> = (data) => {
        editFactory({
            factory_id: factory.id!,
            edited_factory_name: data.name,
            edited_abbreviation_name: data.abbr
        }).then(() => toast.success(getMessage('edited')));
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <ModalFormCard title={t('pages.factories_manage_page.factory_edit_modal_title')}>
                <Paper
                    component={'form'}
                    onSubmit={handleSubmit(handleEdit)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: 1
                    }}
                >
                    <Stack gap={2}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.factories_manage_page.factory_name_field')}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.factories_manage_page.factory_abbr_field')}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />

                        <Button sx={{ height: 50 }} variant="contained" type="submit">
                            {t('global.buttons.submit')}
                        </Button>
                    </Stack>
                </Paper>
            </ModalFormCard>
        </Modal>
    );
});

export default SystemFactoryPage;
