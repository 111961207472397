import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    ArrowUpward,
    CheckCircleOutlineRounded,
    Close,
    Delete,
    Done,
    Download,
    Error,
    EventBusy,
    HighlightOff,
    Info,
    MoreHoriz,
    RotateLeft
} from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    FormControlLabel,
    Grid2 as Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Paper,
    PaperProps,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { handlePermissionCheck } from 'helpers/global.helper';
import { isUserClient, isUserSuperAdmin } from 'helpers/users.helper';
import moment from 'moment';
import { forwardRef, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import { OrderFile, OrderFileWithAdditions, OrderFileWithAdditionsRoot } from 'types/orders.types';
import ModalFormCard from 'ui-component/cards/ModalCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import FileIcon from 'ui-component/files/FileIcon';
import FileList from 'ui-component/files/FileList';
import { dateFormat } from 'utils/data/constant';
import { z } from 'zod';
import { downloadUrl } from '../../helpers/files.helper';
import useAuth from '../../hooks/useAuth';
import { File as CustomFile, Subtype, Type } from '../../types/file.types';
import { getMessage } from '../../utils/messages';
import FileGrid from '../files/FileGrid';
import { useTranslation } from 'react-i18next';

interface OrderFileDisplayWithAdditionsProps extends PaperProps {
    file: OrderFileWithAdditions | OrderFileWithAdditionsRoot;
    handleDelete: () => any;
    handleImprove?: () => any;
    handleApprove?: () => any;
    handleDisapprove?: () => any;
    handleReset?: () => any;
    handleStatusChange?: (value: boolean) => Promise<any>;
    handleClientStatusChange?: (value: boolean) => any;
    handleChangeResolveDeadline?: (value: number, value2: string) => any;
    isDisabled?: boolean;
    fileIndex?: number;
    isVersions?: boolean;
}

const approveEligibleRoles = ['Super Admin', 'Manager', 'Country Manager', 'Factory Manager'];

export const OrderFileDisplayWithAdditions = forwardRef<HTMLDivElement, OrderFileDisplayWithAdditionsProps>(
    (
        {
            file,
            handleDelete,
            handleApprove,
            handleDisapprove,
            handleStatusChange,
            handleReset,
            handleClientStatusChange,
            handleChangeResolveDeadline,
            handleImprove,
            isDisabled = false,
            fileIndex,
            isVersions,
            ...props
        },
        ref
    ) => {
        const { user } = useAuth();
        const { t } = useTranslation();
        const [menuShow, setMenuShow] = useState(false);
        const anchorEl = useRef(null);
        const handleDownload = async () => {
            downloadUrl(file);
            toast.success(t('notifications.success.downloaded'));
        };

        const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

        const handleDeleteAndCloseMenu = () => {
            handleDelete();
            setMenuShow(false);
        };

        const isApprovedByMe =
            (file.first_approved_user && file.first_approved_user.id) === user?.id! ||
            (file.second_approved_user && file.second_approved_user.id) === user?.id ||
            false;

        return (
            <>
                <Paper elevation={4} ref={ref} {...props} sx={{ p: 2, ...props.sx }}>
                    <Grid container spacing={0} columnSpacing={2}>
                        <Grid size={12}>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                {(fileIndex !== undefined && isVersions && !isUserClient(user?.role!) && (
                                    <Typography>V{fileIndex + 1}</Typography>
                                )) || <Box />}
                                <IconButton onClick={() => setMenuShow(true)} ref={anchorEl}>
                                    <MoreHoriz />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid size={12} display={'flex'} justifyContent={'center'}>
                            <FileIcon
                                type={file.type}
                                src={file.src}
                                id={file.id}
                                name={file.name}
                                subtype={file.subtype}
                                live={true}
                                style={{ width: '50%', height: 'initial' }}
                            />
                        </Grid>
                        <Grid size={12}>
                            <Typography
                                variant="body2"
                                sx={{ width: 'calc(100%)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {file.name}
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    width: 'calc(100%)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginBottom: isUserClient(user?.role!) ? '10px' : 'auto'
                                }}
                            >
                                {file.date}
                            </Typography>
                        </Grid>
                        {!isUserClient(user?.role!) &&
                            //@ts-ignore
                            !file.disapproved_by_client && (
                                <Tooltip
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [20, -5]
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                    title={
                                        file.status === 'waiting' ? null : (
                                            <>
                                                {file.status === 'approved' && (
                                                    <Box>
                                                        <Typography variant="h5" color={'white'}>
                                                            {t('components.file_display.approved_by')}
                                                        </Typography>
                                                        <Typography variant={'h6'} color={'white'}>
                                                            1. {file.first_approved_user?.username} - {file.first_approved_date}
                                                        </Typography>
                                                        <Typography variant={'h6'} color={'white'}>
                                                            2. {file.second_approved_user?.username} - {file.second_approved_date}
                                                        </Typography>
                                                        <br />
                                                    </Box>
                                                )}
                                                {file.status === 'disproved' && (
                                                    <Box>
                                                        <Typography variant="h5" color={'white'}>
                                                            {t('components.file_display.approved_by')}
                                                        </Typography>
                                                        <Typography variant={'h6'} color={'white'}>
                                                            1. {file.disapproved_user} - {file.disapproved_date}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </>
                                        )
                                    }
                                >
                                    <Grid size={12} sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color:
                                                        file.status === 'approved' ? 'green' : file.status === 'disproved' ? 'red' : 'black'
                                                }}
                                                fontWeight={600}
                                                textTransform={'uppercase'}
                                            >
                                                {file.status === 'disproved' ? t('components.file_display.disapproved') : file.status}
                                            </Typography>
                                            {(file.disapproved_files.length > 0 || file.disapprove_reason) &&
                                                file.status === 'disproved' && (
                                                    <Info
                                                        sx={{
                                                            width: 20,
                                                            height: 28,
                                                            cursor: 'pointer',
                                                            ml: 0.5,
                                                            '&:hover': {
                                                                transform: 'scale(1.2)'
                                                            }
                                                        }}
                                                        onClick={() =>
                                                            NiceModal.show(disapproveInfoDialog, {
                                                                reason: file.disapprove_reason!,
                                                                files: file.disapproved_files,
                                                                author: file.disapproved_user,
                                                                date: file.disapproved_date
                                                            })
                                                        }
                                                    />
                                                )}
                                        </Box>
                                        {file.status === 'approved' && (
                                            <Box>
                                                <Typography variant={'h6'} color={'green'}>
                                                    1. {file.first_approved_user?.username}
                                                </Typography>
                                                <Typography variant={'h6'} color={'green'}>
                                                    2. {file.second_approved_user?.username}
                                                </Typography>
                                            </Box>
                                        )}
                                        {file.status !== 'disproved' && file.status !== 'approved' && file.first_approved_date && (
                                            <Box>
                                                <Tooltip
                                                    title={
                                                        <Stack>
                                                            <Typography
                                                                sx={{ overflow: 'hidden', textOverflow: 'hidden', mb: 2, color: 'white' }}
                                                                variant="h6"
                                                            >
                                                                {t('components.file_display.approved_by')}{' '}
                                                                {file.first_approved_user?.username} {t('components.file_display.at')}{' '}
                                                                {file.first_approved_date}
                                                            </Typography>
                                                            <Typography>
                                                                {t('components.file_display.waiting_for_one_of_these')}:
                                                            </Typography>
                                                            {approveEligibleRoles
                                                                .filter((role) => role !== file.first_approved_user?.role)
                                                                .map((role) => (
                                                                    <Typography variant={'subtitle2'} color={'white'}>
                                                                        {role}
                                                                    </Typography>
                                                                ))}
                                                        </Stack>
                                                    }
                                                >
                                                    <Box>
                                                        <Typography component={'span'} variant={'h5'}>
                                                            {t('components.file_display.half_approved')}
                                                        </Typography>{' '}
                                                        <Typography
                                                            variant={'h6'}
                                                            sx={{ whitespace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                        >
                                                            {file.first_approved_user?.username}
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        )}
                                    </Grid>
                                </Tooltip>
                            )}
                        {!isUserClient(user?.role!) &&
                            //@ts-ignore
                            file.disapproved_by_client === '1' && (
                                <Tooltip
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [20, -5]
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                    title={
                                        <>
                                            <Box>
                                                <Typography variant="h6" color={'white'}>
                                                    {t('components.file_display.disapproved_by_client')} {t('components.file_display.at')}{' '}
                                                    {file.disapproved_by_client_date}
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                >
                                    <Grid size={12} sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color:
                                                        file.status === 'approved' ? 'green' : file.status === 'disproved' ? 'red' : 'black'
                                                }}
                                                fontWeight={600}
                                                textTransform={'uppercase'}
                                            >
                                                {t('components.file_display.disapproved_by_client')}
                                            </Typography>
                                            {(file.disapproved_files.length > 0 || file.disapprove_reason) &&
                                                file.status === 'disproved' && (
                                                    <Info
                                                        sx={{
                                                            width: 20,
                                                            height: 28,
                                                            cursor: 'pointer',
                                                            ml: 0.5,
                                                            '&:hover': {
                                                                transform: 'scale(1.2)'
                                                            }
                                                        }}
                                                        onClick={() =>
                                                            NiceModal.show(disapproveInfoDialog, {
                                                                reason: file.disapprove_reason!,
                                                                files: file.disapproved_files,
                                                                author: 'Client',
                                                                date: file.disapproved_by_client_date
                                                            })
                                                        }
                                                    />
                                                )}
                                        </Box>
                                    </Grid>
                                </Tooltip>
                            )}
                        {!isUserClient(user?.role!) && handleStatusChange && (
                            <>
                                <Grid size={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip
                                        title={
                                            file.sent_to_client_date ? (
                                                <Typography variant={'h6'} color={'white'}>
                                                    {file.sent_to_client
                                                        ? t('components.file_display.checked')
                                                        : t('components.file_display.unchecked')}{' '}
                                                    {t('components.file_display.by')} {file.sent_to_client_user}{' '}
                                                    {t('components.file_display.at')} {file.sent_to_client_date}
                                                </Typography>
                                            ) : null
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(event) => {
                                                        handleStatusChange(event.target.checked).then(() => {
                                                            if (
                                                                handleChangeResolveDeadline &&
                                                                !event.target.checked &&
                                                                !file.resolve_deadline
                                                            )
                                                                NiceModal.show(changeResolveDeadlineDialog, {
                                                                    submit: (data) => {
                                                                        handleChangeResolveDeadline(data.deadlineDate, data.comment);
                                                                    },
                                                                    deadlineDate: file.resolve_deadline!,
                                                                    comment: file.resolve_deadline_comment!
                                                                });
                                                        });
                                                    }}
                                                    disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                                    checked={file.sent_to_client}
                                                />
                                            }
                                            label={
                                                file.sent_to_client
                                                    ? t('components.file_display.sent_to_client')
                                                    : t('components.file_display.send_to_client')
                                            }
                                            sx={{
                                                mr: 0.5
                                            }}
                                            disabled={
                                                !handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ||
                                                file.status === 'disproved' ||
                                                file.status === 'approved' ||
                                                file.sent_to_client
                                            }
                                        />
                                    </Tooltip>
                                    {(file.sent_to_client === false ||
                                        file.resolve_deadline_notification_id ||
                                        file.resolve_deadline !== null) &&
                                        file.status === 'waiting' && (
                                            <Tooltip
                                                slotProps={{
                                                    popper: {
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [20, -5]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                }}
                                                title={
                                                    <>
                                                        <Box>
                                                            <Typography variant="h6" color={'white'}>
                                                                {file.sent_to_client === false && (
                                                                    <Box>
                                                                        <Typography variant="h5" color={'white'}>
                                                                            {t('components.file_display.client_inform_deadline')}
                                                                        </Typography>
                                                                        {file.sent_to_client_notification_id !== null ? (
                                                                            <Typography variant="h6" color={'white'}>
                                                                                {t('components.file_display.waiting_period_exceeded')}
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography variant="h6" color={'white'}>
                                                                                {t('components.file_display.waiting_until')}{' '}
                                                                                {moment(file.date, `${dateFormat} HH:mm`)
                                                                                    .add(1, 'day')
                                                                                    .format(`${dateFormat} HH:mm`)}
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                                {file.resolve_deadline_notification_id && (
                                                                    <Box>
                                                                        <Typography variant="h5" color={'white'}>
                                                                            {t('components.file_display.client_feedback_deadline_exceeded')}
                                                                        </Typography>
                                                                        {file.resolve_deadline_comment !== '' &&
                                                                            file.resolve_deadline_comment !== null && (
                                                                                <Typography variant={'h6'} color={'white'}>
                                                                                    {t('components.file_display.comment')}:{' '}
                                                                                    {file.resolve_deadline_comment}
                                                                                </Typography>
                                                                            )}
                                                                    </Box>
                                                                )}
                                                                {file.resolve_deadline !== null &&
                                                                    !file.resolve_deadline_notification_id && (
                                                                        <Box>
                                                                            <Typography variant="h5" color={'white'}>
                                                                                {t('components.file_display.client_feedback_deadline')}
                                                                            </Typography>
                                                                            <Typography variant={'h6'} color={'white'}>
                                                                                {t('components.file_display.date')}:{' '}
                                                                                {moment.unix(file.resolve_deadline).format()}
                                                                                {file.resolve_deadline_comment !== '' &&
                                                                                    file.resolve_deadline_comment !== null && (
                                                                                        <Typography variant={'h6'} color={'white'}>
                                                                                            {t('components.file_display.comment')}:{' '}
                                                                                            {file.resolve_deadline_comment}
                                                                                        </Typography>
                                                                                    )}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                }
                                            >
                                                {file.sent_to_client_notification_id !== null || file.resolve_deadline_notification_id ? (
                                                    <Error
                                                        sx={{
                                                            width: 16,
                                                            height: 24,
                                                            color: 'red',
                                                            cursor: 'pointer',
                                                            ml: 0.5,
                                                            '&:hover': {
                                                                transform: 'scale(1.2)'
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <Info
                                                        sx={{
                                                            width: 16,
                                                            height: 24,
                                                            cursor: 'pointer',
                                                            ml: 0.5,
                                                            '&:hover': {
                                                                transform: 'scale(1.2)'
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Tooltip>
                                        )}
                                </Grid>
                            </>
                        )}
                        {handleClientStatusChange &&
                            file.sent_to_client &&
                            (!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ? (
                                <>
                                    <Tooltip
                                        title={
                                            file.client_approved_date ? (
                                                <>
                                                    <Typography variant={'h5'} color={'white'}>
                                                        {t('components.file_display.approved_by_client')}
                                                    </Typography>

                                                    <Typography variant={'h6'} color={'white'}>
                                                        {file.client_approved
                                                            ? t('components.file_display.checked')
                                                            : t('components.file_display.unchecked')}{' '}
                                                        {t('components.file_display.at')} {file.client_approved_date}
                                                    </Typography>
                                                </>
                                            ) : null
                                        }
                                    >
                                        <Grid size={12}>
                                            <label>
                                                <FormControlLabel
                                                    control={<Checkbox disabled={true} checked={file.client_approved} />}
                                                    label="Approved by client"
                                                />
                                            </label>
                                        </Grid>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    {file.client_approved === true && !file.disapproved_date && (
                                        <Tooltip title={t('components.file_display.you_approved_this_file')}>
                                            <Grid size={12} sx={{ textAlign: 'center' }}>
                                                <CheckCircleOutlineRounded sx={{ fontSize: 40 }} color="success" />
                                            </Grid>
                                        </Tooltip>
                                    )}

                                    {
                                        //@ts-ignore
                                        !file.disapproved_date && file.disapproved_by_client && (
                                            <Tooltip title={t('components.file_display.you_disapproved_this_file')}>
                                                <Grid size={12} sx={{ textAlign: 'center' }}>
                                                    <HighlightOff sx={{ fontSize: 40 }} color="error" />
                                                </Grid>
                                            </Tooltip>
                                        )
                                    }
                                    {
                                        //@ts-ignore
                                        file.disapproved_date && (
                                            <Tooltip title={t('components.file_display.factory_disapproved_this_file')}>
                                                <Grid size={12} sx={{ textAlign: 'center' }}>
                                                    <HighlightOff sx={{ fontSize: 40 }} color="error" />
                                                </Grid>
                                            </Tooltip>
                                        )
                                    }
                                </>
                            ))}

                        {(handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') ||
                            (handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && !file.client_approved)) &&
                            file.status !== 'disproved' && (
                                <>
                                    {handleApprove && file.status !== 'approved' && !file.disapproved_by_client && (
                                        <Grid size={6}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                disabled={isApprovedByMe || isDisabled}
                                                sx={{ minWidth: '32px', minHeight: '32px' }}
                                                onClick={
                                                    //@ts-ignore
                                                    !isUserClient(user?.role!) ? handleApprove : (event) => handleClientStatusChange(true)
                                                }
                                                fullWidth
                                            >
                                                <Done />
                                            </Button>
                                        </Grid>
                                    )}
                                    {handleDisapprove && !file.disapproved_by_client && (
                                        <Grid size={file.status === 'approved' ? 12 : 6}>
                                            <Button
                                                variant="contained"
                                                disabled={isDisabled}
                                                size="small"
                                                sx={{ minWidth: '32px', minHeight: '32px' }}
                                                onClick={handleDisapprove}
                                                fullWidth
                                            >
                                                <Close />
                                            </Button>
                                        </Grid>
                                    )}
                                </>
                            )}
                        {!isUserClient(user?.role!) && file.status === 'disproved' && (
                            <Grid size={12}>
                                <AnimateButton>
                                    <Button variant={'contained'} size={'small'} disabled={isDisabled} fullWidth onClick={handleImprove}>
                                        {t('components.file_display.improve')} <ArrowUpward sx={{ ml: 1 }} />
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
                <Menu open={menuShow} onClose={() => setMenuShow(false)} anchorEl={anchorEl.current}>
                    {!isUserClient(user?.role!) && (
                        <MenuItem onClick={handleDeleteAndCloseMenu}>
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            <ListItemText>{t('global.buttons.delete')}</ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            handleDownload();
                            setMenuShow(false);
                        }}
                    >
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText>{t('global.buttons.download')}</ListItemText>
                    </MenuItem>
                    {isUserSuperAdmin(user?.role!) && handleReset && (
                        <MenuItem
                            onClick={() => {
                                handleReset();
                                setMenuShow(false);
                            }}
                            disabled={isDisabled}
                        >
                            <ListItemIcon>
                                <RotateLeft />
                            </ListItemIcon>
                            <ListItemText>{t('global.buttons.reset')}</ListItemText>
                        </MenuItem>
                    )}
                    {handleChangeResolveDeadline && (
                        <MenuItem
                            disabled={isDisabled || file.sent_to_client === false || file.status !== 'waiting'}
                            onClick={() => {
                                NiceModal.show(changeResolveDeadlineDialog, {
                                    submit: (data) => {
                                        handleChangeResolveDeadline(data.deadlineDate, data.comment);
                                    },
                                    deadlineDate: file.resolve_deadline!,
                                    comment: file.resolve_deadline_comment!
                                });
                                setMenuShow(false);
                            }}
                        >
                            <ListItemIcon>
                                <EventBusy />
                            </ListItemIcon>
                            <ListItemText>
                                {t('components.file_display.deadline')}
                                {file.resolve_deadline ? (
                                    <>
                                        <br />
                                        {moment.unix(file.resolve_deadline).format()}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </ListItemText>
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    }
);

const disapproveDialogSchema = z.object({
    comment: z.string().min(10),
    files: z.instanceof(File).array()
});

type DisapproveDialog = z.infer<typeof disapproveDialogSchema>;

export const disapproveConfirmDialog = NiceModal.create(({ submit }: { submit: (data: DisapproveDialog) => void }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const { handleSubmit, watch, control, setValue, getValues } = useForm<DisapproveDialog>({
        resolver: zodResolver(disapproveDialogSchema),
        defaultValues: { comment: '', files: [] }
    });

    const onSubmit: SubmitHandler<DisapproveDialog> = (data) => {
        submit(data);
        modal.remove();
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        onDrop: (files) => setValue('files', [...watch('files'), ...files])
    });

    return (
        <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard title={t('components.file_display.add_comment_and_sketches')} onClose={modal.remove}>
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Stack {...getRootProps()} gap={2} sx={{ border: '1px dashed rgba(0,0,0,0.2)', p: 1 }}>
                        <Controller
                            control={control}
                            name="comment"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    label={t('components.file_display.comment')}
                                    size="small"
                                    variant="standard"
                                    rows={3}
                                    multiline
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Button variant="contained" size="small" onClick={() => open()}>
                            {t('global.buttons.upload')}
                        </Button>
                        <input {...getInputProps()} />
                        <Typography sx={{ opacity: '0.5' }}>{t('components.file_display.or_drag')}</Typography>
                        <FileList
                            files={watch().files}
                            handleRemove={(index) => {
                                const files: File[] = getValues('files');
                                files.splice(index, 1);
                                setValue('files', files);
                            }}
                        />
                    </Stack>
                    <DialogActions sx={{ p: 0, pt: 1 }}>
                        <Button variant="contained" size="small" type="submit">
                            {t('global.buttons.save')}
                        </Button>
                    </DialogActions>
                </Box>
            </ModalFormCard>
        </Modal>
    );
});

const improveDialogSchema = z.object({
    file: z.instanceof(File)
});

type ImproveDialog = z.infer<typeof improveDialogSchema>;

export const improveDialog = NiceModal.create(({ submit }: { submit: (data: ImproveDialog) => void }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const { handleSubmit, watch, setValue } = useForm<ImproveDialog>({
        resolver: zodResolver(improveDialogSchema),
        defaultValues: { file: undefined }
    });

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        multiple: false,
        onDrop: (files) => setValue('file', files[0])
    });

    const onSubmit: SubmitHandler<ImproveDialog> = (data) => {
        submit(data);
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard title={t('components.file_display.upload_file_for_improvement')} onClose={modal.remove}>
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Stack {...getRootProps()} gap={2} sx={{ border: '1px dashed rgba(0,0,0,0.2)', p: 1 }}>
                        <Button variant="contained" size="small" onClick={() => open()}>
                            {t('global.buttons.upload')}
                        </Button>
                        <input {...getInputProps()} />
                        <Typography sx={{ opacity: '0.5' }}>{t('components.file_display.or_drag')}</Typography>
                        {watch('file') && (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <FileIcon
                                    id={0}
                                    name={watch('file').name}
                                    src={URL.createObjectURL(watch('file'))}
                                    type={watch('file').type.split('/')[0] as Type}
                                    subtype={watch('file').type.split('/')[1] as Subtype}
                                    live={false}
                                />
                                <Typography variant={'subtitle2'}>{watch('file').name}</Typography>
                            </Box>
                        )}
                    </Stack>
                    <DialogActions sx={{ p: 0, pt: 1 }}>
                        <Button variant="contained" size="small" type="submit">
                            {t('global.buttons.continue')}
                        </Button>
                    </DialogActions>
                </Box>
            </ModalFormCard>
        </Modal>
    );
});

export const disapproveInfoDialog = NiceModal.create(
    ({ reason, files, date, author }: { reason: string; files: CustomFile[]; date: string; author: string }) => {
        const modal = useModal();
        const { t } = useTranslation();

        return (
            <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
                <ModalFormCard title={t('components.file_display.disapproved_information')} onClose={modal.remove}>
                    <Grid container spacing={1}>
                        <Grid size={12} sx={{ display: 'flex', gap: 1 }}>
                            <Typography>{t('components.file_display.author')}:</Typography>
                            <Typography component={'span'}>{author}</Typography>
                        </Grid>
                        <Grid size={12} sx={{ display: 'flex', gap: 1 }}>
                            <Typography>{t('components.file_display.timestamp')}:</Typography>
                            <Typography component={'span'}>{date}</Typography>
                        </Grid>
                        <Grid size={12} sx={{ display: 'flex', gap: 1 }}>
                            <Typography>{t('components.file_display.reason')}:</Typography>
                            <Typography sx={{ whiteSpace: 'pre-wrap' }} component={'span'}>
                                {reason}
                            </Typography>
                        </Grid>
                        <Grid size={12} sx={{ display: 'flex', gap: 1 }}>
                            <FileGrid files={files} />
                        </Grid>
                    </Grid>
                    <DialogActions sx={{ p: 0, pt: 1 }}>
                        <Button variant="contained" size="small" color="error" onClick={modal.remove}>
                            {t('global.buttons.close')}
                        </Button>
                    </DialogActions>
                </ModalFormCard>
            </Modal>
        );
    }
);

const resolveDeadlineDialogSchema = z.object({
    deadlineDate: z.number(),
    comment: z.string()
});

type ResolveDeadlineDialog = z.infer<typeof resolveDeadlineDialogSchema>;

export const changeResolveDeadlineDialog = NiceModal.create(
    ({ deadlineDate, comment, submit }: { deadlineDate: number; comment: string; submit: (data: ResolveDeadlineDialog) => void }) => {
        const modal = useModal();
        const { t } = useTranslation();

        const { control, handleSubmit } = useForm<ResolveDeadlineDialog>({
            resolver: zodResolver(resolveDeadlineDialogSchema),
            defaultValues: { deadlineDate: deadlineDate || undefined, comment: comment || '' }
        });

        const onSubmit: SubmitHandler<ResolveDeadlineDialog> = (data) => {
            submit(data);
            modal.remove();
        };

        return (
            <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
                <ModalFormCard title={t('components.file_display.client_feedback_deadline')} onClose={modal.remove}>
                    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1} sx={{ mt: 0.5 }}>
                            <Grid size={12}>
                                <Controller
                                    control={control}
                                    name="deadlineDate"
                                    render={({ field, fieldState: { error } }) => (
                                        <DatePicker
                                            {...field}
                                            value={moment.unix(field.value)}
                                            format={dateFormat}
                                            onChange={(value) => field.onChange(value?.unix())}
                                            label={t('components.file_display.deadline')}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message
                                                    // onClick: () => setIsOpenDeadline(true)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={12}>
                                <Controller
                                    control={control}
                                    name="comment"
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            label={t('components.file_display.comment')}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions sx={{ p: 0, pt: 1 }}>
                            <Button variant="contained" size="small" type="submit">
                                {t('global.buttons.save')}
                            </Button>
                        </DialogActions>
                    </Box>
                </ModalFormCard>
            </Modal>
        );
    }
);

interface OrderFileDisplayProps extends PaperProps {
    file: OrderFile;
    handleDelete: () => any;
    isDisabled?: boolean;
}

export const OrderFileDisplay = forwardRef<HTMLDivElement, OrderFileDisplayProps>(
    ({ file, handleDelete, isDisabled = false, ...props }, ref) => {
        const { user } = useAuth();
        const { t } = useTranslation();
        const [menuShow, setMenuShow] = useState(false);
        const anchorEl = useRef(null);
        const handleDownload = async () => {
            downloadUrl(file);
            toast.success(t('notifications.success.downloaded'));
        };

        const handleDeleteAndCloseMenu = () => {
            handleDelete();
            setMenuShow(false);
        };

        return (
            <>
                <Paper elevation={4} ref={ref} {...props} sx={{ p: 2, ...props.sx }}>
                    <Grid container spacing={0} columnSpacing={2}>
                        <Grid size={12}>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                                <IconButton onClick={() => setMenuShow(true)} ref={anchorEl}>
                                    <MoreHoriz />
                                </IconButton>
                            </Box>
                        </Grid>
                        {/* <Typography variant="h6">{file.author}</Typography> */}
                        <Grid size={12} display={'flex'} justifyContent={'center'}>
                            <FileIcon
                                type={file.type}
                                src={file.src}
                                id={file.id}
                                name={file.name}
                                subtype={file.subtype}
                                live={true}
                                style={{ width: '50%', height: 'initial' }}
                            />
                        </Grid>
                        <Grid size={12}>
                            <Typography
                                variant="body2"
                                sx={{ width: 'calc(100%)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                                {file.name}
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography variant="subtitle2" sx={{ width: 'calc(100%)', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {file.upload_date}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Menu open={menuShow} onClose={() => setMenuShow(false)} anchorEl={anchorEl.current}>
                    {!isUserClient(user?.role!) && (
                        <MenuItem onClick={handleDeleteAndCloseMenu}>
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            <ListItemText>{t('global.buttons.delete')}</ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem onClick={handleDownload}>
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText>{t('global.buttons.download')}</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        );
    }
);
