import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { z } from 'zod';
import LanguageDetector from 'i18next-browser-languagedetector';
import { zodI18nMap } from 'zod-i18n-map';
import enTranslation from 'zod-i18n-map/locales/en/zod.json';
import nbTranslation from 'zod-i18n-map/locales/nb/zod.json';
import deTranslation from 'zod-i18n-map/locales/de/zod.json';

type SupportedLngs = 'en' | 'nb' | 'de';
const supportedLngsArray = [
    { abbr: 'en', title: 'English' },
    { abbr: 'nb', title: 'Norsk' },
    { abbr: 'de', title: 'German' }
];
const defaultNS = 'translation';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: supportedLngsArray.map((language) => language.abbr),
        fallbackLng: 'en',
        debug: process.env.NODE_ENV !== 'production',
        detection: {
            order: ['cookie'],
            caches: ['cookie'],
            // One year
            cookieMinutes: 525600
        },
        ns: ['translation', 'zod', 'zod_custom'],
        contextSeparator: '-',
        defaultNS,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
    });

z.setErrorMap(zodI18nMap);

i18n.addResourceBundle('en', 'zod', enTranslation, true);
i18n.addResourceBundle('nb', 'zod', nbTranslation, true);
i18n.addResourceBundle('de', 'zod', deTranslation, true);

export { i18n, z, supportedLngsArray, defaultNS };
export type { SupportedLngs };
