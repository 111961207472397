import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import getMainRoutes from './MainRoutes';
import { useEffect, useState } from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const [mainRoutes, setMainRoutes] = useState<any>({});

    useEffect(() => {
        getMainRoutes().then((result) => setMainRoutes(result));
    }, []);

    return useRoutes([LoginRoutes, mainRoutes]);
}
