import { createRoot } from 'react-dom/client';

// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import reportWebVitals from 'reportWebVitals';
import { store } from 'store';
import 'assets/scss/style.scss';
import { dateFormat } from 'utils/data/constant';
import './i18n';

moment.updateLocale('en', {
    week: {
        dow: 1
    }
});

declare module 'moment' {
    interface Moment {
        originalFormat?: (format?: string) => string;
    }
}
moment.fn.originalFormat = moment.fn.format;
moment.fn.format = function (format?: string) {
    format = format || dateFormat;
    return this.originalFormat!(format);
};

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <BrowserRouter basename={BASE_PATH}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
