import { TLike } from 'types/global.types';
import { OrderFieldsEnum, OrderStatus } from 'types/orders.types';

export const orderStatuses: OrderStatus[] = [
    {
        id: 'confirmed'
    },
    {
        id: 'request'
    },
    {
        id: 'on_hold'
    },
    {
        id: 'cancelled'
    },
    {
        id: 'passive_request'
    },
    {
        id: 'supplier_request'
    },
    {
        id: 'supplier_confirmed'
    }
];

export const orderFields: { id: OrderFieldsEnum; label: TLike }[] = [
    { id: 'orderTitle', label: 'pages.order_manage_page.main_details.order_title' },
    { id: 'orderStatus', label: 'pages.system_orders_visibility_page.order_status' },
    { id: 'shippingAddress', label: 'pages.order_manage_page.main_details.shipping_address.title' },
    { id: 'onHoldReason', label: 'pages.order_manage_page.main_details.on_hold_reason' },
    { id: 'customAddress', label: 'pages.order_manage_page.main_details.custom_shipping_address' },
    { id: 'client', label: 'pages.system_orders_visibility_page.client' },
    { id: 'factory', label: 'pages.order_manage_page.main_details.factory.title' },
    { id: 'mounting', label: 'pages.order_manage_page.main_details.mounting.title' },
    { id: 'country', label: 'pages.order_manage_page.main_details.country.title' },
    { id: 'employees', label: 'pages.order_manage_page.main_details.employees.title' },
    { id: 'deadlineDate', label: 'pages.order_manage_page.main_details.deadline_date' },
    { id: 'shippingDate', label: 'pages.order_manage_page.main_details.shipping_date.title' },
    { id: 'inquiry', label: 'pages.order_manage_page.order_details' },
    { id: 'relatedOrders', label: 'pages.order_manage_page.related_order.title' },
    { id: 'shippingOrders', label: 'pages.order_manage_page.shipping_orders' },
    { id: 'products', label: 'pages.order_manage_page.products' },
    { id: 'orderSurveyFiles', label: 'pages.order_manage_page.files_from_survey_and_client' },
    { id: 'orderFactoryFiles', label: 'components.file_display.factory_disapproved_this_file' },
    { id: 'orderExternalDesignerFiles', label: 'pages.order_manage_page.external_designer_files_for_approval' },
    { id: 'orderFabricationFiles', label: 'pages.order_manage_page.fabrication_drawings' },
    { id: 'order3dFiles', label: 'pages.order_manage_page.3DFiles' },
    { id: 'orderChat', label: 'pages.order_manage_page.chat' },
    { id: 'orderNotifications', label: 'pages.system_orders_visibility_page.order_notifications' },
    { id: 'globalChat', label: 'pages.system_orders_visibility_page.global_chat' },
    { id: 'globalNotifications', label: 'pages.system_orders_visibility_page.global_notifications' },
    { id: 'scheduler', label: 'pages.system_orders_visibility_page.scheduler' },
    { id: 'gallery', label: 'pages.order_manage_page.gallery' },
    { id: 'orderAllowChange', label: 'pages.system_orders_visibility_page.order_allow_changes' }
];
