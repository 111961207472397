import { Box, BoxProps, CircularProgress, Typography } from '@mui/material';
import { shuffleArray } from 'helpers/global.helper';
import { Typewriter } from 'react-simple-typewriter';
import { loadingMessages } from 'utils/data/ux.';

interface Props extends BoxProps {
    message?: boolean;
}

const Cover = ({ message = false, ...props }: Props) => {
    return (
        <Box
            {...props}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 2,
                p: 2,
                ...props.sx
            }}
        >
            <CircularProgress size={40} />
            {message && (
                <Typography
                    variant="h4"
                    sx={{
                        width: 200,
                        overflow: 'visible'
                    }}
                >
                    <Typewriter words={shuffleArray(loadingMessages)} cursor />
                </Typography>
            )}
        </Box>
    );
};

export default Cover;
