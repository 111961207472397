import { NavItemType } from 'types/index.types';
import { LocalShipping } from '@mui/icons-material';

import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { filterCriteriaAtom } from 'ui-component/orders/OrdersTable';
import { jotaiStore } from 'App';

const OrdersTablePage = Loadable(lazy(() => import('views/pages/orders/OrdersTablePage')));

const orders: NavItemType = {
    id: 'orders',
    title: 'components.menu.orders',
    icon: LocalShipping,
    type: 'item',
    url: '/orders',
    breadcrumbs: false,
    onClick: () =>
        jotaiStore.set(filterCriteriaAtom, {
            orderNum: '',
            orderOwner: 'All',
            orderEmployee: 'All',
            country: 'All',
            factory: 'All',
            mainStatus: 'All',
            orderStatus: 'All',
            shippingStatus: 'All',
            requiresAttention: false
        }),
    component: <OrdersTablePage />
};

export default orders;
