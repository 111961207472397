import NiceModal from '@ebay/nice-modal-react';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { Button, CircularProgress, Stack } from '@mui/material';
import { downloadUrl } from 'helpers/files.helper';
import { handlePermissionCheck } from 'helpers/global.helper';
import { isUserClient } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
    useChangeFabricationFileSentToClientStatusMutation,
    useDeleteOrderFabricationFileMutation,
    useFetchOrderFabricationFilesQuery,
    useImproveOrderFabricationFileMutation,
    useLazyDownloadAllFabricationFilesQuery,
    useOrderClientFabricationFileChangeStatusMutation,
    useOrderFabricationFileChangeStatusMutation,
    useResetFabricationDrawingFilesMutation,
    useUploadOrderFabricationFileMutation
} from 'store/api/orders.api';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import Cover from 'ui-component/Cover';
import { getMessage } from 'utils/messages';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import AnimateButton from '../extended/AnimateButton';
import { disapproveConfirmDialog, improveDialog, OrderFileDisplayWithAdditions } from './OrderFileDisplayWithAdditions';
import { useTranslation } from 'react-i18next';

const OrderFabricationDrawingsFiles = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    const { t } = useTranslation();

    const { data: orderFiles, isFetching: isFetchingFiles } = useFetchOrderFabricationFilesQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user?.id }
    );
    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const [changeFileStatus] = useOrderFabricationFileChangeStatusMutation();
    const [changeClientFileStatus] = useOrderClientFabricationFileChangeStatusMutation();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteOrderFabricationFileMutation();
    const [resetFile, { isLoading: isResetting }] = useResetFabricationDrawingFilesMutation();
    const [improveFile] = useImproveOrderFabricationFileMutation();
    const [uploadFile, { isLoading: isUploading }] = useUploadOrderFabricationFileMutation();
    const [changeSentToClientStatus] = useChangeFabricationFileSentToClientStatusMutation();
    const [downloadAllFiles] = useLazyDownloadAllFabricationFilesQuery();

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: (acceptedFiles) => {
            uploadFile({ orderId: orderId!, uploadedFiles: acceptedFiles, userId: user?.id! });
        },
        noClick: true
    });

    if (!orderId) return <Cover />;

    return (
        <Stack direction={'column'} {...getRootProps()} sx={{ border: '1px dashed rgba(0,0,0,0.2)', p: 1 }}>
            <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ flexWrap: 'wrap', minHeight: 100 }}>
                <input {...getInputProps()} />
                {orderFiles &&
                    orderFiles.length > 0 &&
                    orderFiles
                        .filter((item) => (isUserClient(user?.role!) ? item.sent_to_client : true))
                        .map((file, fileIndex) => {
                            return (
                                <Stack
                                    gap={2}
                                    direction={{ xs: 'column', sm: 'row-reverse' }}
                                    sx={{
                                        border: file.improved_files.length > 0 ? '0.5px rgba(0,0,0,0.5) solid' : 'none',
                                        p: 2,
                                        borderRadius: 2,
                                        height: '100%',
                                        opacity: isUploading || isFetchingFiles || isDeleting ? 0 : 1,
                                        width: { xs: '100%', sm: 'initial' }
                                    }}
                                >
                                    <OrderFileDisplayWithAdditions
                                        file={file}
                                        fileIndex={0}
                                        isVersions={!!file.improved_files.length}
                                        sx={{ width: { sx: '100%', sm: 160, md: 200 } }}
                                        handleReset={() => {
                                            Swal.fire({
                                                title: t('pages.order_manage_page.order_files.are_you_sure_reset'),
                                                icon: 'warning',
                                                showCancelButton: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    resetFile({ fileId: file.id, orderId, userId: user?.id! });
                                                }
                                            });
                                        }}
                                        handleDelete={() =>
                                            Swal.fire({
                                                title: t('pages.order_manage_page.order_files.are_you_sure_to_delete_file'),
                                                text: t('pages.order_manage_page.order_files.once_deleted_cant_recover'),
                                                icon: 'warning',
                                                showCancelButton: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteFile({ fileId: file.id, orderId, userId: user?.id! });
                                                }
                                            })
                                        }
                                        handleDisapprove={() => {
                                            NiceModal.show(disapproveConfirmDialog, {
                                                submit: (data) =>
                                                    toast.promise(
                                                        changeFileStatus({
                                                            comment: data.comment,
                                                            fileId: file.id,
                                                            orderId,
                                                            status: 'disproved',
                                                            uploadedFiles: data.files,
                                                            userId: user?.id!
                                                        }),
                                                        {
                                                            pending: t('notifications.pending.uploadPending'),
                                                            success: t('notifications.success.uploaded')
                                                        },
                                                        { autoClose: 1000 }
                                                    )
                                            });
                                        }}
                                        handleStatusChange={async (value: boolean) => {
                                            toast.promise(
                                                changeSentToClientStatus({ orderId, userId: user?.id!, fileId: file.id, newStatus: value }),
                                                {
                                                    pending: t('notifications.pending.updatePending'),
                                                    success: t('notifications.success.updated')
                                                }
                                            );
                                        }}
                                        handleClientStatusChange={(value: boolean) => {
                                            toast.promise(
                                                changeClientFileStatus({
                                                    orderId,
                                                    userId: user?.id!,
                                                    fileId: file.id,
                                                    newStatus: value
                                                }),
                                                {
                                                    pending: t('notifications.pending.updatePending'),
                                                    success: t('notifications.success.updated')
                                                }
                                            );
                                        }}
                                        handleApprove={() => {
                                            toast.promise(
                                                changeFileStatus({
                                                    fileId: file.id,
                                                    orderId,
                                                    status: 'approved',
                                                    userId: user?.id!
                                                }),
                                                {
                                                    pending: t('notifications.pending.updatePending'),
                                                    success: t('notifications.success.updated')
                                                },
                                                { autoClose: 1000 }
                                            );
                                        }}
                                        handleImprove={() => {
                                            NiceModal.show(improveDialog, {
                                                submit: (data) => {
                                                    toast.promise(
                                                        improveFile({
                                                            fileId: file.id,
                                                            orderId,
                                                            uploadedFiles: [data.file],
                                                            userId: user?.id!
                                                        }),
                                                        {
                                                            pending: t('notifications.pending.updatePending'),
                                                            success: t('notifications.success.updated')
                                                        },
                                                        { autoClose: 1000 }
                                                    );
                                                }
                                            });
                                        }}
                                        isDisabled={file.improved_files.length > 0}
                                    />
                                    {file.improved_files.length > 0 && <KeyboardDoubleArrowLeft sx={{ my: 'auto' }} />}

                                    {file.improved_files
                                        .slice(0)
                                        .sort((a, b) => {
                                            if (a.id > b.id) return 1;
                                            else if (a.id < b.id) return -1;
                                            else return 0;
                                        })
                                        .map((improvedFile, improvedIndex) => {
                                            const isNotLast = improvedIndex !== file.improved_files.length - 1;

                                            return (
                                                <>
                                                    <OrderFileDisplayWithAdditions
                                                        file={improvedFile}
                                                        fileIndex={improvedIndex + 1}
                                                        isVersions={!!file.improved_files.length}
                                                        sx={{ width: { sx: '100%', sm: 160, md: 200 } }}
                                                        handleReset={() => {
                                                            Swal.fire({
                                                                title: t('pages.order_manage_page.order_files.are_you_sure_reset'),
                                                                icon: 'warning',
                                                                showCancelButton: true
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    resetFile({ fileId: improvedFile.id, orderId, userId: user?.id! });
                                                                }
                                                            });
                                                        }}
                                                        handleDelete={() =>
                                                            Swal.fire({
                                                                title: t('pages.order_manage_page.order_files.are_you_sure_to_delete_file'),
                                                                text: t('pages.order_manage_page.order_files.once_deleted_cant_recover'),
                                                                icon: 'warning',
                                                                showCancelButton: true
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteFile({ fileId: improvedFile.id, orderId, userId: user?.id! });
                                                                }
                                                            })
                                                        }
                                                        handleStatusChange={async (value: boolean) => {
                                                            toast.promise(
                                                                changeSentToClientStatus({
                                                                    orderId,
                                                                    userId: user?.id!,
                                                                    fileId: improvedFile.id,
                                                                    newStatus: value
                                                                }),
                                                                {
                                                                    pending: t('notifications.pending.updatePending'),
                                                                    success: t('notifications.success.updated')
                                                                }
                                                            );
                                                        }}
                                                        handleClientStatusChange={(value: boolean) => {
                                                            toast.promise(
                                                                changeClientFileStatus({
                                                                    orderId,
                                                                    userId: user?.id!,
                                                                    fileId: file.id,
                                                                    newStatus: value
                                                                }),
                                                                {
                                                                    pending: t('notifications.pending.updatePending'),
                                                                    success: t('notifications.success.updated')
                                                                }
                                                            );
                                                        }}
                                                        handleDisapprove={() => {
                                                            NiceModal.show(disapproveConfirmDialog, {
                                                                submit: (data) =>
                                                                    toast.promise(
                                                                        changeFileStatus({
                                                                            comment: data.comment,
                                                                            fileId: improvedFile.id,
                                                                            orderId,
                                                                            status: 'disproved',
                                                                            uploadedFiles: data.files,
                                                                            userId: user?.id!
                                                                        }),
                                                                        {
                                                                            pending: t('notifications.pending.uploadPending'),
                                                                            success: t('notifications.success.uploaded')
                                                                        },
                                                                        { autoClose: 1000 }
                                                                    )
                                                            });
                                                        }}
                                                        handleApprove={() => {
                                                            toast.promise(
                                                                changeFileStatus({
                                                                    fileId: improvedFile.id,
                                                                    orderId,
                                                                    status: 'approved',
                                                                    userId: user?.id!
                                                                }),
                                                                {
                                                                    pending: t('notifications.pending.updatePending'),
                                                                    success: t('notifications.success.updated')
                                                                },
                                                                { autoClose: 1000 }
                                                            );
                                                        }}
                                                        handleImprove={() => {
                                                            NiceModal.show(improveDialog, {
                                                                submit: (data) => {
                                                                    toast.promise(
                                                                        improveFile({
                                                                            fileId: improvedFile.primary_id || improvedFile.id,
                                                                            orderId,
                                                                            uploadedFiles: [data.file],
                                                                            userId: user?.id!
                                                                        }),
                                                                        {
                                                                            pending: t('notifications.pending.uploadPending'),
                                                                            success: t('notifications.success.uploaded')
                                                                        },
                                                                        { autoClose: 1000 }
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                        isDisabled={isNotLast}
                                                    />
                                                    {isNotLast && <KeyboardDoubleArrowLeft sx={{ my: 'auto' }} />}
                                                </>
                                            );
                                        })}
                                </Stack>
                            );
                        })}
                {(isUploading || isFetchingFiles || isDeleting) && (
                    <CircularProgress
                        sx={{
                            opacity: '1',
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                )}
            </Stack>
            <Stack
                direction={{ xs: 'row', sm: 'row' }}
                sx={{ justifyContent: { xs: 'center', sm: 'start' }, alignItems: { xs: 'center', sm: 'initial' }, mt: 2 }}
                gap={2}
            >
                {handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && (
                    <AnimateButton>
                        <Button variant={'contained'} onClick={open}>
                            {t('pages.order_manage_page.order_files.upload_a_file')}
                        </Button>
                    </AnimateButton>
                )}
                <Button
                    variant={'contained'}
                    disabled={orderFiles?.length === 0}
                    onClick={async () => {
                        const file = await downloadAllFiles({ orderId }).then((result) => result.data?.file);
                        if (file) downloadUrl({ src: file, name: 'files' });
                    }}
                >
                    {t('pages.order_manage_page.order_files.download_all')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default OrderFabricationDrawingsFiles;
