import { Box, Button, Container, Grid2 as Grid, Tooltip, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { atom, useAtom } from 'jotai';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
    useFetchCountriesQuery,
    useFetchFactoriesQuery,
    useFetchMountingCrewsQuery,
    useGetSystemInitStatusQuery,
    useSetSystemInitStatusMutation
} from 'store/api/system.api';
import { useFetchUserDataQuery } from 'store/api/user.api';
import { UserSchema } from 'types/user.types';
import SubCard from 'ui-component/cards/SubCard';
import LanguageInput from 'ui-component/i18n/LanguageInput';
import UserForm, { UserFormType } from 'views/forms/users/UserForm';
import AuthWrapper from 'views/pages/AuthWrapper';
import SystemCountriesPage from 'views/pages/system/SystemCountriesPage';
import SystemFactoryPage from 'views/pages/system/SystemFactoriesPage';

export const isSystemDisabledAtom = atom(false);

type WrapperProps = { debug?: boolean } & PropsWithChildren;

const Wrapper = ({ children, debug = false }: WrapperProps) => {
    const { user } = useAuth();
    const [activeStep, setActiveStep] = useState(0);
    const formRef = useRef<HTMLFormElement | null>(null);
    const { t } = useTranslation();

    const { data: systemInit } = useGetSystemInitStatusQuery(null);
    const [setSystemInit] = useSetSystemInitStatusMutation();
    const [isSystemDisabled, setIsSystemDisabled] = useAtom(isSystemDisabledAtom);

    const { data: userData } = useFetchUserDataQuery({
        userId: user?.id!
    });

    useEffect(() => {
        if (systemInit === false) setIsSystemDisabled(true);
        else setIsSystemDisabled(false);
    }, [systemInit]);

    const steps: {
        label: string;
    }[] = [
        {
            label: t('pages.wrapper.create_countries_label')
        },
        {
            label: t('pages.wrapper.create_factories_label')
        },
        {
            label: t('pages.wrapper.finish_profile_label')
        }
    ];

    const { data: factories } = useFetchFactoriesQuery({});
    const { data: countries } = useFetchCountriesQuery({});
    const { data: crews } = useFetchMountingCrewsQuery({});

    const [nextButtonData, setNextButtonData] = useState<{ disabled: boolean; tooltip: undefined | string }>({
        disabled: false,
        tooltip: undefined
    });

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (countries?.length! > 0) {
                    setNextButtonData({ disabled: false, tooltip: undefined });
                } else {
                    setNextButtonData({ disabled: true, tooltip: 'One country is required at least' });
                }
                break;
            case 1:
                if (factories?.length! > 0) {
                    setNextButtonData({ disabled: false, tooltip: undefined });
                } else {
                    setNextButtonData({ disabled: true, tooltip: 'One Factory is required at least' });
                }
                break;
            default:
                setNextButtonData({ disabled: false, tooltip: undefined });
        }
    }, [activeStep, countries, factories, crews]);

    useEffect(() => {
        if (!isSystemDisabled && !debug) return;
        else if (!user && !debug) return;
        else if (!userData) return;

        const userValues: UserFormType = {
            username: userData.username,
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.user_email,
            phone: userData.phone_number,
            avatar: userData.avatar,
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            language: 'en',
            factories: userData.user_factories!,
            countries: userData.user_countries!,
            crews: userData.user_crews,
            userOrigin: userData.user_origin_country[0],
            role: userData.user_role
        };
        const parseResult = UserSchema.safeParse(userValues);
        if (parseResult.success === true) setSystemInit({ initStatus: true, userId: user?.id! });
    }, [userData]);

    if (!isSystemDisabled && !debug) return <>{children}</>;
    else if (!user && !debug) return <>{children}</>;

    return (
        <>
            <AuthWrapper>
                <Grid container sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    {steps.map((step, index) => {
                        return (
                            <Grid size={{ xs: 12, sm: 'grow' }} sx={{ bgcolor: 'white', border: '1px solid rgb(211, 226, 253);' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                                    <Typography variant="h5">
                                        {index === activeStep ? (
                                            <Typography variant="h6" sx={{ color: 'green', fontWeight: 700 }}>
                                                {t('pages.wrapper.current')}
                                            </Typography>
                                        ) : activeStep > index ? (
                                            <Typography variant="h6" sx={{}}>
                                                {t('pages.wrapper.completed')}
                                            </Typography>
                                        ) : (
                                            <Typography variant="h6" sx={{}}>
                                                {t('pages.wrapper.upcoming')}
                                            </Typography>
                                        )}
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontWeight: index === activeStep ? 700 : 200 }}>
                                        {index + 1}. {step.label}
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                    <Grid
                        size={{ xs: 12, sm: 3.5, md: 2 }}
                        sx={{
                            bgcolor: 'white',
                            border: '1px solid rgb(211, 226, 253);',
                            display: 'flex',
                            p: 2
                        }}
                    >
                        <Button
                            color="inherit"
                            fullWidth
                            disabled={activeStep === 0}
                            onClick={() => {
                                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                            }}
                            sx={{ mr: 1 }}
                        >
                            {t('global.buttons.back')}
                        </Button>
                        <Tooltip title={nextButtonData.tooltip}>
                            <Box component={'label'} sx={{ width: '100%' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={nextButtonData.disabled}
                                    onClick={() => {
                                        if (activeStep === steps.length - 1) {
                                            // setSystemInit({ initStatus: true, userId: user?.id! });
                                            if (formRef.current) {
                                                formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                                            }
                                        } else {
                                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                        }
                                    }}
                                >
                                    {activeStep === steps.length - 1 ? t('global.buttons.finish') : t('global.buttons.next')}
                                </Button>
                            </Box>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Container
                    sx={{
                        pt: 2,
                        bgcolor: 'white',
                        height: '100%',
                        minHeight: 'calc(100vh - 72px)',
                        borderLeft: '1px solid rgb(211, 226, 253);',
                        borderRight: '1px solid rgb(211, 226, 253);',
                        display: 'flex',
                        pb: 2
                    }}
                    maxWidth={'md'}
                >
                    <Grid container sx={{ flexGrow: 1 }}>
                        <SubCard sx={{ display: activeStep === 0 ? 'initial' : 'none', width: '100%' }}>
                            <SystemCountriesPage />
                        </SubCard>
                        <SubCard sx={{ display: activeStep === 1 ? 'initial' : 'none', width: '100%' }}>
                            <SystemFactoryPage />
                        </SubCard>
                        <Box sx={{ display: activeStep === 2 ? 'initial' : 'none' }}>
                            <UserForm targetUserId={user?.id} ref={formRef} disableToasts />
                        </Box>
                    </Grid>
                    <LanguageInput sx={{ position: 'absolute', right: 8, bottom: 4 }} />
                </Container>
            </AuthWrapper>
        </>
    );
};

export default Wrapper;
