import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageInput = forwardRef<HTMLDivElement, FormControlProps>((props, ref) => {
    const { i18n, t } = useTranslation();
    return (
        <FormControl sx={{ minWidth: 120, width: '100%' }} size="small" {...props} ref={ref}>
            <InputLabel>{t('global.fields.language')}</InputLabel>
            <Select value={i18n.language} label="Language" fullWidth onChange={(event) => i18n.changeLanguage(event.target.value)}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="nb">Norsk</MenuItem>
            </Select>
        </FormControl>
    );
});

export default LanguageInput;
