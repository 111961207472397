import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Info } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid2 as Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Paper,
    Popper,
    PopperProps,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { nanoid } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { downloadUrl } from 'helpers/files.helper';
import { handlePermissionCheck } from 'helpers/global.helper';
import { isUserSuperAdmin } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import useLightbox from 'hooks/useLightbox';
import { toPng } from 'html-to-image';
import { useAtom } from 'jotai';
import JSZip from 'jszip';
import _ from 'lodash';
import moment from 'moment';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { renderToStaticMarkup } from 'react-dom/server';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import {
    useFetchOrderDataQuery,
    useFetchOrderProductsQuery,
    useSaveOrderProductsDrawingNeededMutation,
    useSaveOrderProductsMutation
} from 'store/api/orders.api';
import { useFetchProductsQuery, useFetchUserOrderFieldsVisabilityQuery, useLazyFetchProductInformationQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { MinimalProduct } from 'types/global.types';
import { OrderProduct, Product, ProductDocumentTypesConst, ProductImageTypesConst, ProductInput } from 'types/orders.types';
import ModalFormCard from 'ui-component/cards/ModalCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { dateFormat } from 'utils/data/constant';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import { z } from 'zod';

const PopperMy = function (props: PopperProps) {
    return (
        <Popper
            {...props}
            style={{
                width: 'fit-content'
            }}
            placement="bottom-start"
        />
    );
};

const OrderProducts = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const { data: products, isLoading: isProductsLoading } = useFetchProductsQuery(null);
    const { data: orderProducts } = useFetchOrderProductsQuery({ orderId: orderId! }, { skip: !orderId });

    const { data: order } = useFetchOrderDataQuery({ orderId: orderId!, userId: user?.id! }, { skip: !orderId || !user });

    const [productsInitialized, setProductsInitialiazed] = useState(false);

    const { control, handleSubmit, setValue, reset, getValues, watch, formState } = useForm<OrderProduct>({
        defaultValues: { drawingDate: undefined, isDrawingEnabled: false, products: [] }
    });

    const containerRefs = useRef<(HTMLElement | null)[]>([]);
    const isSyncingScroll = useRef<boolean>(false);

    const [selectedProduct, setSelectedProduct] = useState<MinimalProduct | null>(null);
    const [isDownloadAllProductsLoading, setIsDownloadAllProductsLoading] = useState(false);
    const [isCombinedScrollEnabled, setIsCombinedScrollEnabled] = useState(true);

    const html2imageContainerRef = useRef<HTMLDivElement>(null);

    const [saveOrderProducts] = useSaveOrderProductsMutation();
    const [saveDrawingNeeded] = useSaveOrderProductsDrawingNeededMutation();
    const [fetchProductInformation] = useLazyFetchProductInformationQuery();

    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });

    const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(null);

    const { show } = useLightbox();

    useEffect(() => {
        if (!orderProducts) return;
        setProductsInitialiazed(true);
        reset(orderProducts, { keepDefaultValues: true });
        reset({}, { keepValues: true });
    }, [orderProducts]);

    const handleScroll = useCallback((index: number): void => {
        if (isSyncingScroll.current) return;

        const container = containerRefs.current[index];
        if (container) {
            const scrollLeft = container.scrollLeft;

            isSyncingScroll.current = true;

            containerRefs.current.forEach((otherContainer, otherIndex) => {
                if (otherIndex !== index && otherContainer) {
                    const maxScrollLeft = otherContainer.scrollWidth - otherContainer.clientWidth;

                    // Ensure we don't exceed the maximum scrollLeft
                    const newScrollLeft = Math.max(0, Math.min(scrollLeft, maxScrollLeft));

                    otherContainer.scrollLeft = newScrollLeft;
                }
            });

            isSyncingScroll.current = false;
        }
    }, []);

    const handleSaveOrderProducts = (data: OrderProduct) => {
        if (!user || !orderId) return;

        toast.promise(
            saveOrderProducts({
                orderId: orderId,
                products: data.products,
                userId: user.id
            }),
            { pending: t('notifications.pending.updatePending') }
        );
        reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    };

    const handleSaveDrawingNeeded = (data: OrderProduct) => {
        if (!user || !orderId) return;

        toast.promise(
            saveDrawingNeeded({
                orderId: orderId,
                userId: user.id,
                drawingDate: data.drawingDate,
                isDrawingEnabled: Boolean(data.isDrawingEnabled),
                drawingDateChangeReason: data.drawingDateChangeReason
            }),
            { pending: t('notifications.pending.updatePending') }
        );
        reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
    };

    const handleOpenEditModal = (productIndex: number, inputIndex?: number) => {
        console.log({ productIndex, inputIndex });
        NiceModal.show(ProductEditModal, { orderProduct: getValues(), productIndex, renderFileInput, handleSaveOrderProducts, inputIndex });
    };

    const handleDeselectProducts = () => {
        const dataCopy = getValues();
        dataCopy.products.forEach((product) => {
            product.selected = false;
        });
        reset(dataCopy);
    };

    const handleCopyProduct = (data: OrderProduct, product: Product, amount: number) => {
        const dataCopy = getValues();
        [...Array(amount)].forEach(() => {
            const productCopy: Product = _.cloneDeep(product);
            productCopy.inputs.forEach((input, index) => {
                if (input.inputType === 'file') {
                    delete productCopy.inputs[index].inputFile;
                }
                productCopy.selected = false;
            });
            delete productCopy.uniqueId;
            productCopy.uniqueId = nanoid();
            dataCopy.products.push(productCopy);
        });
        handleDeselectProducts();
        reset(dataCopy);
    };

    const handleDeleteSelectedProducts: SubmitHandler<OrderProduct> = (data) => {
        if (!orderId || !user) return;

        // Check if anything is selected
        if (!data.products.find((product) => product.selected === true)) {
            toast.error(t('pages.order_manage_page.order_products.no_rows_selected'));
            return;
        }
        Swal.fire({
            title: t('pages.order_manage_page.order_products.you_are_deleting_are_you_sure', {
                length: getSelectedProducts().length,
                word: getSelectedProducts().length === 1 ? 'product' : 'products'
            }),
            text: t('notifications.swal.text.you_wont_be_able_to_revert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('notifications.swal.confirm_button.yes_delete_it')
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedProducts = getSelectedProducts();
                const selectedProductsUniqueIds = selectedProducts!.map((selectedProduct) => selectedProduct.uniqueId);
                const forDeletionIndexes = [];
                const dataCopy: OrderProduct = _.cloneDeep(data);
                for (const [productIndex, product] of dataCopy.products.entries()) {
                    if (selectedProductsUniqueIds.includes(product.uniqueId)) {
                        forDeletionIndexes.push(productIndex);
                    }
                }
                forDeletionIndexes.sort((a, b) => (a < b ? 1 : -1));

                for (const deletionIndex of forDeletionIndexes) dataCopy.products.splice(deletionIndex, 1);

                reset({ drawingDate: undefined, isDrawingEnabled: false, products: [] });
                setTimeout(() => {
                    reset({
                        drawingDate: dataCopy.drawingDate,
                        isDrawingEnabled: dataCopy.isDrawingEnabled,
                        products: dataCopy.products
                    });
                    handleSaveOrderProducts({
                        drawingDate: dataCopy.drawingDate,
                        isDrawingEnabled: dataCopy.isDrawingEnabled,
                        products: dataCopy.products,
                        drawingDateChangeReason: dataCopy.drawingDateChangeReason
                    });
                }, 500);
            }
        });
    };

    const handleAddProduct = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!orderId || !user) return;
        fetchProductInformation({ productId: selectedProduct?.id! }).then(({ data: product }) => {
            if (!product) return;
            const products: Product[] = [...watch('products'), { ...product, uniqueId: nanoid() }];
            setValue('products', products);
            handleSaveOrderProducts(getValues());
            handleOpenEditModal(getValues().products.length - 1);
        });
    };

    const renderFileInput = ({
        input,
        selectedProductIndex,
        inputIndex,
        disabled = true,
        fullWidth = false,
        autoFocus = false,
        control
    }: {
        input: ProductInput;
        selectedProductIndex: number;
        inputIndex: number;
        disabled?: boolean;
        fullWidth?: boolean;
        autoFocus?: boolean;
        control: any;
    }) => {
        if (!input.inputFile || !input.inputFile!.file_type) {
            return (
                <Controller
                    control={control}
                    name={`products.${selectedProductIndex}.inputs.${inputIndex}.inputFile`}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            sx={{
                                minWidth: fullWidth ? undefined : 40,
                                width: fullWidth ? undefined : input.width + 'px',
                                '& .MuiInputBase-input': {
                                    pointerEvents: disabled ? 'none' : 'initial'
                                }
                            }}
                            fullWidth={fullWidth || undefined}
                            label={input.inputTitle}
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            size="small"
                            type={'file'}
                            error={!!error}
                            autoFocus={autoFocus}
                            helperText={error?.message}
                            {...field}
                            value={undefined}
                            onChange={(event) => {
                                if ('files' in event.target) {
                                    field.onChange(event.target.files);
                                }
                            }}
                        />
                    )}
                />
            );
        } else if (ProductImageTypesConst.includes(input.inputFile!.file_type)) {
            return (
                <Controller
                    control={control}
                    name={`products.${selectedProductIndex}.inputs.${inputIndex}.inputFile`}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            sx={{
                                minWidth: fullWidth ? undefined : 40,
                                width: fullWidth ? undefined : input.width + 'px',
                                '& .MuiInputBase-input': {
                                    pointerEvents: disabled ? 'none' : 'initial'
                                }
                            }}
                            fullWidth={fullWidth || undefined}
                            label={input.inputTitle}
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            size="small"
                            type={'file'}
                            autoFocus={autoFocus}
                            InputProps={{
                                startAdornment: (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            maxHeight: 30,
                                            height: 'auto',
                                            minWidth: 40,
                                            maxWidth: 40,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            mr: 0.5
                                        }}
                                    >
                                        <img
                                            src={input.inputFile?.url}
                                            alt="Product"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                show(input.inputFile!.url);
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                            value={undefined}
                            onChange={(event) => {
                                if ('files' in event.target) {
                                    field.onChange(event.target.files);
                                }
                            }}
                        />
                    )}
                />
            );
        } else if (ProductDocumentTypesConst.includes(input.inputFile!.file_type)) {
            return (
                <Controller
                    control={control}
                    name={`products.${selectedProductIndex}.inputs.${inputIndex}.inputFile`}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            sx={{
                                minWidth: fullWidth ? undefined : 40,
                                width: fullWidth ? undefined : input.width + 'px',
                                '& .MuiInputBase-input': {
                                    pointerEvents: disabled ? 'none' : 'initial'
                                }
                            }}
                            fullWidth={fullWidth || undefined}
                            label={input.inputTitle}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type={'file'}
                            disabled={disabled}
                            error={!!error}
                            helperText={error?.message}
                            autoFocus={autoFocus}
                            {...field}
                            value={undefined}
                            onChange={(event) => {
                                if ('files' in event.target) {
                                    field.onChange(event.target.files);
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <Typography
                                            component={'span'}
                                            onClick={() => show(input.inputFile!.url)}
                                            sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', mr: 0.5 }}
                                        >
                                            Doc
                                        </Typography>
                                    </>
                                )
                            }}
                        />
                    )}
                />
            );
        } else {
            return (
                <Controller
                    name={`products.${selectedProductIndex}.inputs.${inputIndex}.inputFile`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            sx={{
                                minWidth: fullWidth ? undefined : 40,
                                width: fullWidth ? undefined : input.width + 'px',
                                '& .MuiInputBase-input': {
                                    pointerEvents: disabled ? 'none' : 'initial'
                                }
                            }}
                            fullWidth={fullWidth || undefined}
                            label={input.inputTitle}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            disabled={disabled}
                            type={'file'}
                            autoFocus={autoFocus}
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <Typography
                                            component={'span'}
                                            onClick={() => {
                                                //create a anchor tag with url and click it to download file
                                                const a = document.createElement('a');
                                                a.href = input.inputFile?.url!;
                                                a.download = input.inputFile?.file_name!;
                                                a.click();
                                                a.remove();
                                            }}
                                            sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', paddingRight: 0.5 }}
                                        >
                                            File
                                        </Typography>
                                    </>
                                )
                            }}
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                            value={undefined}
                            onChange={(event) => {
                                if ('files' in event.target) {
                                    field.onChange(event.target.files);
                                }
                            }}
                        />
                    )}
                />
            );
        }
    };

    const handleGenerateProductImage = async (productIndex: number) => {
        const ReturnValue = ({ product: selectedProduct, index }: { product: Product; index: number }) => {
            if (!watch().products[productIndex].inputs[index].inputValue) return <>No value provided</>;

            if (selectedProduct.inputs[index].inputType === 'date') {
                return <>{moment.utc(selectedProduct.inputs[index].inputValue).format('l LL')}</>;
            } else {
                return <>{selectedProduct.inputs[index].inputValue}</>;
            }
        };

        const htmlTable = (
            <table style={{ height: '100%' }} id="htmlTable">
                {[...Array(watch().products[productIndex].inputs.length)].map((element, index) => {
                    if (
                        typeof watch().products[productIndex].inputs[index].inputValue !== 'string' &&
                        typeof watch().products[productIndex].inputs[index].inputValue !== 'undefined'
                    )
                        return <></>;
                    if (watch().products[productIndex].inputs[index].inputType === 'file') return <></>;
                    return (
                        <tr
                            style={{
                                width: '100%',
                                background: 'lightgrey',
                                minWidth: '100%'
                            }}
                        >
                            <th
                                style={{
                                    width: '20%',
                                    border: '1px solid black',
                                    textAlign: 'left',
                                    padding: 8
                                }}
                            >
                                {watch().products[productIndex].inputs[index].inputTitle}
                            </th>
                            <th
                                style={{
                                    width: '80%',
                                    border: '1px solid black',
                                    textAlign: 'left',
                                    padding: 8
                                }}
                            >
                                <ReturnValue index={index} product={watch().products[productIndex]} />
                            </th>
                        </tr>
                    );
                })}
            </table>
        );

        const stringHtmlTable = renderToStaticMarkup(htmlTable);
        if (html2imageContainerRef.current !== null) {
            html2imageContainerRef.current.innerHTML = stringHtmlTable;
        }

        return await toPng(html2imageContainerRef.current!, {
            width: 300,
            cacheBust: true,
            style: { opacity: '100%' }
        }).then((url) => {
            if (html2imageContainerRef.current !== null) {
                html2imageContainerRef.current.innerHTML = '';
            }
            return url;
        });
    };

    const generateProductCsvArray = () => {
        const data = getSelectedProducts();
        const returnData = [];
        // Used to determine if product already has it's header.
        const writtenProducts: number[] = [];

        for (const product of data) {
            if (writtenProducts.includes(product.productId!)) continue;

            const header = [product.productTitle];
            for (const input of product.inputs) header.push(input.inputTitle);
            returnData.push(header);

            for (const absoluteProduct of data) {
                if (absoluteProduct.productId === product.productId) {
                    const line = [''];
                    for (const input of absoluteProduct.inputs) {
                        if (input.inputType === 'date') line.push(moment(input.inputValue).format('D-M-YYYY') || '');
                        else if (input.inputType === 'file') line.push(input.inputFile?.url! || '');
                        else {
                            line.push(input.inputValue || '');
                        }
                    }

                    returnData.push(line);
                }
                writtenProducts.push(product?.productId!);
            }
            returnData.push([]);
        }

        return returnData;
    };

    const getSelectedProducts = () => {
        const products = watch().products;
        const selectedProducts = [];

        for (const product of products) if (product.selected) selectedProducts.push(product);
        return selectedProducts;
    };

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 0 }}>
            <Grid container component={'form'} onSubmit={handleAddProduct} spacing={2}>
                {handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && (
                    <>
                        <Grid size={{ xs: 8, md: 3, lg: 2 }}>
                            <Autocomplete
                                disabled={isProductsLoading}
                                disablePortal
                                options={products!}
                                getOptionLabel={(option) => option.title}
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('pages.system_products_page.title')}
                                        placeholder={t('pages.order_manage_page.order_products.select_products')}
                                        required
                                        size="small"
                                        fullWidth
                                    />
                                )}
                                value={selectedProduct}
                                onChange={(event, product) => setSelectedProduct(product)}
                            />
                        </Grid>
                        <Grid size={{ xs: 2, lg: 1 }}>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!selectedProduct}
                                size="small"
                                fullWidth
                                sx={{ height: '100%' }}
                            >
                                {t('global.buttons.add')}
                            </Button>
                        </Grid>
                    </>
                )}
                <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <Controller
                        control={control}
                        name={`drawingDate`}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DatePicker
                                label={t('pages.order_manage_page.order_products.drawing_deadline')}
                                value={value ? moment(value) : null}
                                format={dateFormat}
                                onChange={async (value) => {
                                    const oldValue = watch().drawingDate;
                                    let shouldContinue = true;
                                    const resetValues = () => {
                                        setValue('drawingDate', oldValue);
                                        shouldContinue = false;
                                        return;
                                    };
                                    onChange(value);
                                    if (oldValue !== null && moment(oldValue) !== value)
                                        await NiceModal.show(drawingDeadlineReasonChangeDialog, {
                                            submit: ({ isConfirmed, reason }) => {
                                                if (isConfirmed) {
                                                    setValue('drawingDateChangeReason', reason);
                                                } else {
                                                    resetValues();
                                                }
                                            }
                                        });
                                    if (!shouldContinue) return;
                                    handleSaveDrawingNeeded(watch());
                                }}
                                disabled={!watch('isDrawingEnabled') || !handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                open={isOpen}
                                onClose={() => setIsOpen(false)}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: !!error,
                                        helperText: error?.message,
                                        fullWidth: true,
                                        onClick: () => setIsOpen(true),
                                        InputProps: {
                                            startAdornment: (
                                                <>
                                                    <Controller
                                                        name={`isDrawingEnabled`}
                                                        control={control}
                                                        render={({ field: { onChange, value } }) => (
                                                            <Checkbox
                                                                disabled={!handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange')}
                                                                checked={value}
                                                                onChange={(event, value) => {
                                                                    onChange(value);
                                                                    handleSaveDrawingNeeded(watch());
                                                                }}
                                                                sx={{ p: 0, pr: watch().drawingDateChangeReason ? 0 : 1 }}
                                                                size="small"
                                                            />
                                                        )}
                                                    />

                                                    {watch().drawingDateChangeReason && (
                                                        <Tooltip title={watch().drawingDateChangeReason}>
                                                            <IconButton sx={{ p: 0, ml: 1, mr: 1 }} size="small">
                                                                <Info sx={{ width: 16, height: 16, my: 'auto' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )
                                        }
                                    }
                                }}
                                disablePast={isUserSuperAdmin(user?.role!) ? false : true}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 1 }}>
                    <LoadingButton
                        variant="contained"
                        loading={isDownloadAllProductsLoading}
                        fullWidth
                        sx={{ whiteSpace: 'nowrap', px: 2, height: '100%' }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setExportAnchorEl(event.currentTarget);
                        }}
                        size="small"
                    >
                        {t('pages.order_manage_page.order_products.download')}
                    </LoadingButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={exportAnchorEl}
                        open={Boolean(exportAnchorEl)}
                        onClose={() => {
                            setExportAnchorEl(null);
                        }}
                    >
                        <MenuItem
                            onClick={async () => {
                                const selectedProducts = getSelectedProducts();
                                // Check if anything is selected
                                if (selectedProducts.length === 0) {
                                    toast.error(t('pages.order_manage_page.order_products.no_rows_selected'));
                                    return;
                                } else if (selectedProducts.length === 1) {
                                    setIsDownloadAllProductsLoading(true);
                                    if (isDownloadAllProductsLoading) return;

                                    const selectedProductIndex = watch().products.findIndex((product) => product.selected);

                                    const product = watch().products[selectedProductIndex];

                                    setIsDownloadAllProductsLoading(true);
                                    downloadUrl({
                                        name: `${product.productTitle} ${product.orderTitle} `,
                                        src: await handleGenerateProductImage(selectedProductIndex)
                                    });
                                    setIsDownloadAllProductsLoading(false);
                                } else {
                                    setIsDownloadAllProductsLoading(true);
                                    if (isDownloadAllProductsLoading) return;

                                    const zip = new JSZip();

                                    for (const [selectedProductIndex, product] of watch().products.entries()) {
                                        if (!product.selected) continue;

                                        const uri = await handleGenerateProductImage(selectedProductIndex);

                                        const idx = uri.indexOf('base64,') + 'base64,'.length;
                                        const content = uri.substring(idx);

                                        zip.file(
                                            `${watch().products[selectedProductIndex].productTitle} ${
                                                watch().products[selectedProductIndex].orderTitle
                                            }[${selectedProductIndex}].png`,
                                            content,
                                            { base64: true }
                                        );
                                    }

                                    zip.generateAsync({ type: 'blob' })
                                        .then((content) => {
                                            FileSaver.saveAs(content, 'product.zip');
                                        })
                                        .then(() => {
                                            setIsDownloadAllProductsLoading(false);
                                        });
                                    handleDeselectProducts();
                                }
                            }}
                        >
                            {t('pages.order_manage_page.order_products.images')}
                        </MenuItem>
                        <CSVLink
                            separator=";"
                            data={generateProductCsvArray()}
                            filename={`${order?.orderNum} - Products`}
                            style={{ color: 'initial', textDecoration: 'none' }}
                            onClick={() => {
                                const selectedProducts = getSelectedProducts();

                                if (selectedProducts.length === 0) {
                                    toast.error(t('pages.order_manage_page.order_products.no_rows_selected'));
                                    return false;
                                }
                            }}
                        >
                            <MenuItem>Csv</MenuItem>
                        </CSVLink>
                    </Menu>
                </Grid>
                {handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && (
                    <>
                        <Grid size={{ xs: 12, sm: 6, md: 1 }}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                color="warning"
                                size="small"
                                sx={{ height: '100%' }}
                                onClick={async () => {
                                    const selectedProducts = getSelectedProducts();

                                    if (selectedProducts.length === 0) {
                                        toast.error(t('pages.order_manage_page.order_products.no_rows_selected'));
                                        return;
                                    }

                                    const { value: amount } = await Swal.fire({
                                        title: t('pages.order_manage_page.order_products.how_much_times_want_to_copy'),
                                        icon: 'question',
                                        input: 'number',
                                        inputValue: '1',
                                        inputAttributes: {
                                            min: '1',
                                            style: 'appearance: textfield;'
                                        },
                                        showCancelButton: true,
                                        inputValidator: (value) => {
                                            if (isNaN(parseInt(value))) {
                                                return t('errors.invalid_type', { ns: 'zod' });
                                            }
                                            return null;
                                        }
                                    });
                                    if (amount)
                                        selectedProducts.forEach((selectedProduct) => {
                                            handleCopyProduct(watch(), selectedProduct, parseInt(amount));
                                        });

                                    handleSaveOrderProducts({
                                        drawingDate: getValues().drawingDate,
                                        isDrawingEnabled: getValues().isDrawingEnabled,
                                        products: getValues().products,
                                        drawingDateChangeReason: getValues().drawingDateChangeReason
                                    });
                                }}
                            >
                                {t('global.buttons.copy')}
                            </LoadingButton>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6, md: 1 }}>
                            <LoadingButton
                                fullWidth
                                sx={{ height: '100%' }}
                                variant="contained"
                                color="error"
                                onClick={handleSubmit(handleDeleteSelectedProducts)}
                                size="small"
                            >
                                {t('global.buttons.delete')}
                            </LoadingButton>
                        </Grid>
                    </>
                )}
                <Grid justifyContent={'flex-end'} display={'flex'} gap={2}>
                    <Paper
                        elevation={4}
                        sx={{
                            display: 'flex',
                            justifyContent: { xs: 'right', sm: 'center' },
                            height: '40px',
                            width: 'fit-content',
                            maxHeight: '40px',
                            my: 'auto',
                            px: 1
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                mr: 0,
                                '& .MuiTypography-root': {
                                    mt: 0.5
                                }
                            }}
                            control={
                                <Checkbox
                                    checked={isCombinedScrollEnabled}
                                    onChange={() => {
                                        setIsCombinedScrollEnabled((value) => !value);
                                    }}
                                />
                            }
                            label={t('pages.order_manage_page.order_products.combined_scroll')}
                        />
                    </Paper>
                    <Paper
                        elevation={4}
                        sx={{
                            display: 'flex',
                            justifyContent: { xs: 'right', sm: 'center' },
                            height: '40px',
                            width: 'fit-content',
                            maxHeight: '40px',
                            my: 'auto'
                        }}
                    >
                        <Checkbox
                            checked={getValues().products.length === 0 ? false : getValues().products.every((product) => product.selected)}
                            onChange={(event) => {
                                const form = getValues();
                                const updatedProducts = form.products.map((product) => ({
                                    ...product,
                                    selected: event.target.checked
                                }));
                                reset({ ...form, products: updatedProducts });
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Stack gap={0.5}>
                {watch('products').map((product, productIndex) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                transition: 'background-color 0.3s ease',
                                px: 1,
                                pb: 0.5,
                                borderRight: '1 solid white',
                                borderLeft: '1 solid white',
                                borderRadius: 4,
                                '&:hover': {
                                    backgroundColor: '#f2f2f2',
                                    borderRight: '1 solid #e3e8ef',
                                    borderLeft: '1 solid #e3e8ef'
                                }
                            }}
                            onClick={() => handleOpenEditModal(productIndex)}
                            key={product.uniqueId || productIndex}
                        >
                            {/* Index */}
                            <Box
                                sx={{
                                    height: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '8px',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography variant="h6">{productIndex + 1}.</Typography>
                            </Box>
                            {/* Image */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    maxHeight: 40,
                                    height: 'auto',
                                    minWidth: 40,
                                    maxWidth: 40,
                                    position: 'relative',
                                    border: 'solid 1px rgba(0,0,0,0.23)',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    mt: 1,
                                    cursor: 'pointer'
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (product.imgUrl) show(product.imgUrl);
                                    else if (product.file) show(URL.createObjectURL(product.file!));
                                }}
                            >
                                <img
                                    src={
                                        product.imgUrl
                                            ? product.imgUrl
                                            : 'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png'
                                    }
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'center center'
                                    }}
                                    alt="Product"
                                />
                            </Box>
                            {/* Title */}
                            <Tooltip title={product.productTitle}>
                                <Box
                                    sx={{
                                        border: 'solid 1px rgba(0,0,0,0.23)',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxHeight: 40,
                                        width: { xs: 50, md: 150 },
                                        maxWidth: { xs: 50, md: 150 },
                                        height: 'auto',
                                        px: 1,
                                        mt: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Typography whiteSpace={'nowrap'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {product.productTitle}
                                    </Typography>
                                </Box>
                            </Tooltip>
                            {/* Inputs */}
                            <Box
                                sx={{
                                    overflowX: 'auto',
                                    width: '100%',
                                    '& .ps__rail-x': { opacity: 0.6 }
                                }}
                                className="box-input-container"
                            >
                                <PerfectScrollbar
                                    containerRef={(el: HTMLElement | null) => {
                                        containerRefs.current[productIndex] = el;
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                    onScrollX={isCombinedScrollEnabled ? () => handleScroll(productIndex) : undefined}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            mb: 0.5,
                                            mt: 1
                                        }}
                                    >
                                        {product.inputs.map((input, inputIndex) => (
                                            <Box
                                                key={inputIndex}
                                                sx={{
                                                    '& input': { display: 'block !important', cursor: 'pointer !important' },
                                                    '& .MuiFormLabel-root': { pointerEvents: 'all' },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        pointerEvents: input.inputType === 'file' ? 'none' : 'all'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        cursor: 'pointer !important'
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        cursor: 'pointer !important'
                                                    }
                                                }}
                                                onClick={() => handleOpenEditModal(productIndex, inputIndex)}
                                            >
                                                {input.inputType === 'text' && (
                                                    <Controller
                                                        control={control}
                                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                                        render={({ field: { value } }) => (
                                                            <TextField
                                                                disabled={true}
                                                                sx={{
                                                                    minWidth: 40,
                                                                    width: input.width + 'px'
                                                                }}
                                                                label={input.inputTitle}
                                                                size="small"
                                                                value={value ? value : ''}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {input.inputType === 'select' && (
                                                    <Controller
                                                        control={control}
                                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                sx={{ minWidth: 40, width: input.width + 'px', height: '100%' }}
                                                                options={input.inputValues!}
                                                                freeSolo
                                                                disabled={true}
                                                                forcePopupIcon={input.width > 100 ? true : false}
                                                                value={value ? value : ''}
                                                                PopperComponent={PopperMy}
                                                                clearIcon={false}
                                                                isOptionEqualToValue={(option, value) => option === value}
                                                                size="small"
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={input.inputTitle}
                                                                        fullWidth
                                                                        sx={{ height: '100%', '& .MuiInputBase-root': { height: '100%' } }}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {input.inputType === 'date' && (
                                                    <Controller
                                                        control={control}
                                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                                        render={({ field: { value } }) => (
                                                            <DatePicker
                                                                label={input.inputTitle}
                                                                value={value ? moment(value) : null}
                                                                disabled={true}
                                                                format={dateFormat}
                                                                slotProps={{
                                                                    textField: {
                                                                        size: 'small',
                                                                        sx: { minWidth: 40, width: input.width + 'px' }
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {input.inputType === 'file' && (
                                                    <>
                                                        {renderFileInput({
                                                            input: input,
                                                            inputIndex: inputIndex,
                                                            selectedProductIndex: productIndex,
                                                            control: control
                                                        })}
                                                    </>
                                                )}
                                            </Box>
                                        ))}
                                        <Box
                                            sx={{
                                                '& input': { display: 'block !important', cursor: 'pointer !important' },
                                                '& .MuiFormLabel-root': { pointerEvents: 'all' },
                                                cursor: 'pointer',
                                                width: '100%'
                                            }}
                                            onClick={() => handleOpenEditModal(productIndex)}
                                        />
                                    </Box>
                                </PerfectScrollbar>
                            </Box>
                            {/* Controls */}
                            <Paper
                                elevation={4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '40px',
                                    maxHeight: '40px',
                                    my: 'auto'
                                }}
                            >
                                <Controller
                                    name={`products.${productIndex}.selected`}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox
                                            checked={watch(`products.${productIndex}.selected`) ? true : false}
                                            onChange={onChange}
                                            onClick={(event) => event.stopPropagation()}
                                        />
                                    )}
                                />
                            </Paper>
                        </Box>
                    );
                })}
            </Stack>
            <Box
                ref={html2imageContainerRef}
                id="html2image-container"
                style={{ maxWidth: 300, backgroundColor: 'white', position: 'absolute', zIndex: 1000, opacity: 0 }}
            ></Box>
        </Grid>
    );
};

type ProductEditModalProps = {
    orderProduct: OrderProduct;
    productIndex: number;
    inputIndex?: number;
    renderFileInput: ({
        input,
        selectedProductIndex,
        inputIndex,
        disabled,
        fullWidth,
        autoFocus,
        control
    }: {
        input: ProductInput;
        selectedProductIndex: number;
        inputIndex: number;
        disabled?: boolean;
        fullWidth?: boolean;
        autoFocus?: boolean;
        control: any;
    }) => JSX.Element;

    handleSaveOrderProducts: (data: OrderProduct) => void;
};

const ProductEditModal = NiceModal.create(
    ({ orderProduct, productIndex, renderFileInput, handleSaveOrderProducts, inputIndex: inputIndexProp }: ProductEditModalProps) => {
        const modal = useModal();
        const { t } = useTranslation();

        const {
            control,
            getValues,
            watch,
            formState: { errors }
        } = useForm<OrderProduct>({
            defaultValues: orderProduct
        });

        useEffect(() => {
            console.log({ errors });
        }, [errors]);

        const product = watch().products[productIndex];

        return (
            <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
                <ModalFormCard
                    title={t('pages.order_manage_page.order_products.editing_product', { product: product.productTitle })}
                    onClose={modal.remove}
                    animation={false}
                    sx={{
                        maxWidth: 500
                    }}
                >
                    <Grid
                        container
                        component={'form'}
                        onSubmit={(event) => {
                            event.preventDefault();
                            const data = getValues();
                            handleSaveOrderProducts(data);
                            modal.remove();
                        }}
                        sx={{ width: '100%', height: '100%', mt: 1 }}
                        spacing={1.5}
                    >
                        {product.inputs.map((input, inputIndex) => (
                            <Grid
                                size={{ xs: 12, md: 6 }}
                                key={inputIndex}
                                sx={{
                                    '& input': { display: 'block !important' }
                                }}
                            >
                                {input.inputType === 'text' && (
                                    <Controller
                                        control={control}
                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                label={input.inputTitle}
                                                fullWidth
                                                size="small"
                                                value={value}
                                                onChange={(data) => {
                                                    onChange(data);
                                                }}
                                                autoFocus={inputIndex === inputIndexProp ? true : false}
                                            />
                                        )}
                                    />
                                )}
                                {input.inputType === 'select' && (
                                    <Controller
                                        control={control}
                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                        render={({ field: { onChange, value } }) => (
                                            <Autocomplete
                                                fullWidth
                                                options={input.inputValues!}
                                                freeSolo
                                                value={value}
                                                PopperComponent={PopperMy}
                                                openOnFocus
                                                onChange={(event, value) => {
                                                    onChange(value);
                                                }}
                                                sx={{ height: '100%' }}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                autoFocus={inputIndex === inputIndexProp ? true : false}
                                                autoHighlight={inputIndex === inputIndexProp ? true : false}
                                                forcePopupIcon
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={input.inputTitle}
                                                        fullWidth
                                                        autoFocus={inputIndex === inputIndexProp ? true : false}
                                                        sx={{ height: '100%', '& .MuiInputBase-root': { height: '100%' } }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                                {input.inputType === 'date' && (
                                    <Controller
                                        control={control}
                                        name={`products.${productIndex}.inputs.${inputIndex}.inputValue`}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePicker
                                                label={input.inputTitle}
                                                value={value ? moment(value) : null}
                                                format={dateFormat}
                                                onChange={(data) => {
                                                    onChange(data);
                                                }}
                                                autoFocus={inputIndex === inputIndexProp ? true : false}
                                                slotProps={{
                                                    textField: {
                                                        size: 'small',
                                                        fullWidth: true,
                                                        autoFocus: inputIndex === inputIndexProp ? true : false
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )}
                                {input.inputType === 'file' && (
                                    <>
                                        {renderFileInput({
                                            input: input,
                                            inputIndex: inputIndex,
                                            selectedProductIndex: productIndex,
                                            disabled: false,
                                            fullWidth: true,
                                            autoFocus: inputIndex === inputIndexProp ? true : false,
                                            control: control
                                        })}
                                    </>
                                )}
                            </Grid>
                        ))}
                        <Grid size={12} sx={{ p: 0, px: 1 }} justifyContent={'flex-end'} display={'flex'}>
                            {/* This button disables 'enter' key form submission */}
                            <Button type="submit" disabled sx={{ display: 'none' }} aria-hidden="true"></Button>

                            <Button variant="contained" size="small" type="submit">
                                {t('global.buttons.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </ModalFormCard>
            </Modal>
        );
    }
);

const drawingDeadlineReasonChangeDialogSchema = z.object({
    reason: z.string().min(1),
    isConfirmed: z.boolean()
});

type DrawingDeadlineReasonChangeDialog = z.infer<typeof drawingDeadlineReasonChangeDialogSchema>;

const drawingDeadlineReasonChangeDialog = NiceModal.create(({ submit }: { submit: (data: DrawingDeadlineReasonChangeDialog) => void }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<DrawingDeadlineReasonChangeDialog>({
        resolver: zodResolver(drawingDeadlineReasonChangeDialogSchema),
        defaultValues: { reason: '', isConfirmed: false }
    });

    const onSubmit: SubmitHandler<DrawingDeadlineReasonChangeDialog> = (data) => {
        submit({ ...data, isConfirmed: true });
        modal.resolve();
        modal.remove();
    };

    const onCancel = () => {
        submit({ reason: '', isConfirmed: false });
        modal.resolve();
        modal.remove();
    };

    return (
        <Dialog open={modal.visible} onClose={onCancel}>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('pages.order_manage_page.order_products.reason_for_changing_drawing_deadline')}</DialogTitle>
                <DialogContent>
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                error={!!error}
                                helperText={error?.message}
                                {...field}
                                size="small"
                                label={t('pages.order_manage_page.order_products.reason')}
                                fullWidth
                                sx={{ my: 1 }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <AnimateButton>
                        <Button variant="contained" size="small" type="submit">
                            {t('global.buttons.continue')}
                        </Button>
                    </AnimateButton>
                    <AnimateButton>
                        <Button variant="contained" size="small" color="error" onClick={onCancel}>
                            {t('global.buttons.cancel')}
                        </Button>
                    </AnimateButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
});

export default OrderProducts;
