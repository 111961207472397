import { ArrowBack, Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, StackProps, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface Props extends StackProps {
    title?: string;
    onClose: () => void;
    onBack?: () => void;
}

const DrawerCard: React.FC<PropsWithChildren<Props>> = ({ children, title, onClose, onBack, ...props }) => {
    return (
        <Stack sx={{ minHeight: '100%' }} {...props}>
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    bgcolor: 'white'
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}
                >
                    {onBack ? (
                        <IconButton size="small" onClick={() => onBack()}>
                            <ArrowBack sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    ) : (
                        <IconButton size="small" onClick={() => onClose()}>
                            <Close sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    )}
                    {title ? (
                        <Typography variant="h4" sx={{ my: 'auto' }}>
                            {title}
                        </Typography>
                    ) : (
                        <Box sx={{ opacity: 0 }}>s</Box>
                    )}
                    <Box sx={{ opacity: 0 }}>s</Box>
                </Box>
                <Divider />
            </Box>
            {children}
        </Stack>
    );
};

export default DrawerCard;
