import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@mui/material';
import ModalFormCard from 'ui-component/cards/ModalCard';
import CompanyForm from 'views/forms/clients/CompanyForm';

const ComapanyAddModal = NiceModal.create(() => {
    const modal = useModal();

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard title="Company">
                <CompanyForm isRepresentativesEnabled={false} />
            </ModalFormCard>
        </Modal>
    );
});

export default ComapanyAddModal;
