import { Button, Collapse, Grid2 as Grid, Grid2Props as GridProps } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { isMobile } from 'react-device-detect';

const MobileCompactor: React.FC<
    PropsWithChildren<{
        expandedText: string;
        collapsedText: string;
    }> &
        GridProps
> = ({ children, collapsedText, expandedText, ...props }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            {isMobile ? (
                <Grid container spacing={2} {...props} sx={{ py: 1, ...props.sx }}>
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <Button variant="contained" fullWidth onClick={handleExpandClick} color="primary">
                            {expanded ? expandedText : collapsedText}
                        </Button>
                    </Grid>
                    {expanded && (
                        <Collapse in={expanded} sx={{ width: '100%' }}>
                            <Grid container spacing={2} {...props} sx={{ ...props.sx }}>
                                {children}
                            </Grid>
                        </Collapse>
                    )}
                </Grid>
            ) : (
                <Grid container spacing={2} {...props} sx={{ py: 2, ...props.sx }}>
                    {children}
                </Grid>
            )}
        </>
    );
};

export default MobileCompactor;
