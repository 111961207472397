import React, { useEffect, useMemo, useRef, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import { Inject, Month, RenderCellEventArgs, ScheduleComponent, ViewDirective, ViewsDirective } from '@syncfusion/ej2-react-schedule';
import useAuth from 'hooks/useAuth';
import { useLazyFetchEventDurationQuery, useLazyFetchJobsQuery } from 'store/api/mounting.api';
import Cover from 'ui-component/Cover';
import EventCreateForm from 'ui-component/modals/mountingScheduler/EventCreateForm';
import EventViewModal from 'ui-component/modals/mountingScheduler/EventViewModal';
import { useFetchOrderDataQuery } from 'store/api/orders.api';
// @ts-nocheck
interface Props {
    orderId?: number;
    loadButton?: boolean;
}

function ClientScheduler({ orderId, loadButton = false }: Props) {
    const { user } = useAuth();

    const order = useFetchOrderDataQuery(
        { orderId: orderId!, userId: user?.id! },
        {
            skip: !orderId || !user,
            selectFromResult: (result) => ({
                mountingCrews: result.data?.mounting_crews,
                mountingDuration: result.data?.mounting_duration,
                mountingRange: result.data?.mounting_range
            })
        }
    );

    const [fetchJobsQuery, { data: jobs, isFetching: isJobsFetching, isUninitialized: isJobsUninitialized }] = useLazyFetchJobsQuery();
    const filteredJobs = jobs?.events?.filter((job) => parseInt(job.author) === user?.id!);

    useEffect(() => {
        if (!loadButton)
            fetchJobsQuery({
                region: order.mountingCrews?.map((item) => parseInt(item.id)),
                userID: user!.id
            });
    }, []);

    useEffect(() => {
        if (loadButton || !order) return;
        fetchJobsQuery({
            region: order.mountingCrews?.map((item) => parseInt(item.id)),
            userID: user!.id
        });
    }, [order]);

    const [isCalendarReady, setIsCalendarReady] = useState(false);
    const [startEventDate, setStartEventDate] = useState<any>();
    const [selectedStartDate, setSelectedStartDate] = useState<null | Date>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null);
    const selectedStartDateRef = useRef<null | Date>(null);
    selectedStartDateRef.current = selectedStartDate;
    const selectedEndDateRef = useRef<null | Date>(null);
    selectedEndDateRef.current = selectedEndDate;
    const calendar = useRef(null);
    const [mountingInitialized, setMountingInitialized] = useState(false);
    const [startOfWeek, setStartOfWeek] = useState<null | number>(null);

    const selectedTeamRef = useRef(null);

    const [fetchEventDuration] = useLazyFetchEventDurationQuery();

    const cancelEvent = (props: any) => {
        if (!props) return;
        props.cancel = true;
    };

    const eventRendered = (args: any) => {
        if (orderId && orderId !== args.data.project) {
            args.element.style.opacity = 0.3;
        }
        args.element.style.backgroundColor = args.data.color;
    };

    const teamEventsByDate = {};

    // @ts-ignore
    order &&
        // @ts-ignore
        order.mountingCrews.forEach((team) => {
            // Create a new object for the team with days_off added or modified
            // @ts-ignore
            const updatedTeam = { ...team, days_off: JSON.parse(team.days_off) };

            // Use updatedTeam instead of team for further operations
            // @ts-ignore
            jobs &&
                jobs.events.forEach((event) => {
                    const startDateStr = new Date(event.StartTime).toDateString();
                    // @ts-ignore
                    if (!teamEventsByDate[startDateStr]) {
                        // @ts-ignore
                        teamEventsByDate[startDateStr] = 0;
                    }
                    // Assuming event.region represents the team ID and matches team.id
                    if (event.region === updatedTeam.id) {
                        // @ts-ignore
                        teamEventsByDate[startDateStr] += 1;
                    }
                });
        });

    //@ts-ignore
    const minDate = new Date(order.mountingRange[0].startDate);
    //@ts-ignore
    const maxDate = new Date(order.mountingRange[0].endDate);

    //@ts-ignore
    const allDates = [];
    while (minDate <= maxDate) {
        if (minDate.getDay() !== 0 && minDate.getDay() !== 6) {
            // Skip weekends
            allDates.push(new Date(minDate).toDateString());
        }
        minDate.setDate(minDate.getDate() + 1);
    }

    //@ts-ignore
    useEffect(() => {
        if (order && mountingInitialized && order.mountingCrews) setStartOfWeek(parseInt(order.mountingCrews[0].start_of_week));
    }, [mountingInitialized, order]);

    const isDurationConstraint = (dateStr: string, duration: number, allDates: string[]): boolean => {
        const dateIndex = allDates.indexOf(dateStr);
        if (dateIndex === -1) return false;

        for (let i = 0; i < duration; i++) {
            const futureDateStr = allDates[dateIndex + i];
            //@ts-ignore
            if (!futureDateStr || teamEventsByDate[futureDateStr] === order.mountingCrews.length) {
                // If all teams are busy on any of the future days, it's a constraint
                return true;
            }
        }
        return false;
    };

    //@ts-ignore
    const isWorkdayForTeam = (date, daysOff) => {
        const convert = JSON.parse(daysOff);
        const day = date.getDay();
        return !convert.includes(day);
    };

    const renderCell = (args: RenderCellEventArgs): void => {
        if (startOfWeek === null) return;
        if (args.elementType === 'workCells' || args.elementType === 'monthCells') {
            args.element.classList.remove('e-disable-dates');
            //@ts-ignore
            const cellDate = new Date(args.date);
            const cellDateStr = cellDate.toDateString();
            //@ts-ignore
            const startDateStr = selectedStartDateRef.current ? new Date(selectedStartDateRef.current).toDateString() : null;
            const endDateStr = selectedEndDateRef.current ? new Date(selectedEndDateRef.current).toDateString() : null;

            if (startDateStr && endDateStr) {
                //@ts-ignore
                const suitableTeam = selectedTeamRef.current;

                //@ts-ignore
                const isWorkdayForSelectedTeam = suitableTeam && isWorkdayForTeam(cellDate, suitableTeam.days_off);

                if (!isWorkdayForSelectedTeam) {
                    args.element.classList.add('e-disable-dates');
                } else {
                    args.element.classList.remove('e-disable-dates');
                }

                //@ts-ignore
                if (
                    startDateStr &&
                    endDateStr &&
                    new Date(cellDateStr) >= new Date(startDateStr) &&
                    new Date(cellDateStr) <= new Date(endDateStr)
                ) {
                    //@ts-ignore
                    if (isWorkdayForSelectedTeam) {
                        args.element.classList.add('e-selected-range');
                    } else {
                        args.element.classList.remove('e-selected-range');
                    }
                } else {
                    args.element.classList.remove('e-selected-range');
                }
            }

            //@ts-ignore
            const isWorkdayForAnyTeam = order.mountingCrews.some((team) => isWorkdayForTeam(cellDate, team.days_off));

            if (!isWorkdayForAnyTeam) {
                args.element.classList.add('e-disable-dates');
                return;
            }

            //@ts-ignore
            if (teamEventsByDate[cellDateStr] === order.mountingCrews.length) {
                args.element.classList.add('e-disable-dates');
            } else {
                // args.element.classList.remove('e-disable-dates');

                // Check if the day is a duration constraint day
                //@ts-ignore
                if (isDurationConstraint(cellDateStr, parseInt(order.mountingDuration), allDates)) {
                    args.element.classList.add('e-duration-constraint');
                } else {
                    args.element.classList.remove('e-duration-constraint');
                }
            }

            // Add/update the tooltip for duration-constrained days
            if (args.element.classList.contains('e-duration-constraint')) {
                let tooltipSpan = args.element.querySelector('.custom-info-icon');

                // If the tooltip span doesn't exist, create it
                if (!tooltipSpan) {
                    tooltipSpan = document.createElement('span');
                    tooltipSpan.classList.add('custom-info-icon');
                    args.element.appendChild(tooltipSpan);
                }

                // Set the tooltip message
                //@ts-ignore
                tooltipSpan.title = 'This date is within the duration constraint and cannot be selected as a starting point.';

                // Apply absolute positioning to cover the cell
                //@ts-ignore
                tooltipSpan.style.position = 'absolute';
                //@ts-ignore
                tooltipSpan.style.top = '0';
                //@ts-ignore
                tooltipSpan.style.left = '0';
                //@ts-ignore
                tooltipSpan.style.height = '100%';
                //@ts-ignore
                tooltipSpan.style.width = '100%';
            } else {
                // Remove the tooltip span if the cell is no longer duration-constrained
                const existingTooltipSpan = args.element.querySelector('.custom-info-icon');
                if (existingTooltipSpan) {
                    args.element.removeChild(existingTooltipSpan);
                }
            }

            const minDate = new Date(order.mountingRange![0].startDate);
            const maxDate = new Date(order.mountingRange![0].endDate);

            if (cellDate < minDate || cellDate > maxDate) {
                args.element.classList.add('e-disable-dates');
                return; // No further processing needed if the date is out of range
            }
        }
    };

    //@ts-ignore
    const calculateEndDate = (startDate, duration, daysOff) => {
        // const daysOff = '[6, 5]';
        const endDate = new Date(startDate);
        let daysAdded = 0;

        while (daysAdded < duration) {
            endDate.setDate(endDate.getDate() + 1);

            if (isWorkdayForTeam(endDate, daysOff)) {
                daysAdded++;
            }
        }

        return endDate;
    };

    const cellClick = (args: any) => {
        if (!args || !jobs || startOfWeek === null) return;

        const clickedDate = new Date(args.startTime);
        const clickedDateStr = clickedDate.toDateString();

        // Check if the clicked date is within the allowed date range
        const minDate = new Date(order.mountingRange![0].startDate);
        const maxDate = new Date(order.mountingRange![0].endDate);
        if (clickedDate < minDate || clickedDate > maxDate) {
            return; // Early return if the date is out of range
        }

        //@ts-ignore
        if (isDurationConstraint(clickedDateStr, parseInt(order.mountingDuration), allDates)) {
            Swal.fire({
                title: `Selected Date Unavailable`,
                text: `The date you've selected cannot be chosen as the starting point because there aren't enough free consecutive workdays following it to accommodate the required duration of the mounting job.`,
                icon: 'warning',
                showCancelButton: false
            });
            return;
        }
    };

    //@ts-ignore
    // const handleMultipleTeamsAvailability = (teams, clickedDate, clickedDateStr, mountingDuration) => {
    //     // Check teams' weekend availability and ask user if they want to include weekends
    //     //@ts-ignore
    //     // eslint-disable-next-line no-restricted-globals
    //     let includeWeekends = confirm("Multiple teams are available. Do you want to include weekend work?");
    //     if (includeWeekends) {
    //         // Filter out teams that do not work on weekends
    //         //@ts-ignore
    //         teams = teams.filter(team => !team.days_off.includes(6) || !team.days_off.includes(0));
    //     }

    //     // You can further refine to select a specific team based on additional criteria
    //     // For now, we'll just proceed with the first team in the filtered list
    //     if (teams.length > 0) {
    //         selectedTeamRef.current = teams[0];
    //         proceedWithTeamSelection(teams[0], clickedDate, clickedDateStr, mountingDuration);
    //     }
    // };

    //@ts-ignore
    const handleMultipleTeamsAvailability = async (teams, clickedDate, clickedDateStr, mountingDuration) => {
        // Use SweetAlert to ask the user if they want to include weekends
        const result = await Swal.fire({
            title: 'Include Weekend Work?',
            text: 'Multiple teams are available. Do you want to include weekend work?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });

        // Proceed based on the user's choice
        if (result.isConfirmed) {
            // User chose to include teams that work on weekends
            //@ts-ignore
            teams = teams.filter((team) => !team.days_off.includes(6) || !team.days_off.includes(0));
        }

        // Select the first team in the filtered list or based on other criteria
        if (teams.length > 0) {
            selectedTeamRef.current = teams[0];
            proceedWithTeamSelection(teams[0], clickedDate, clickedDateStr, mountingDuration);
        }
    };

    //@ts-ignore
    const proceedWithTeamSelection = (team, clickedDate, clickedDateStr, mountingDuration) => {
        if (mountingDuration && team) {
            const duration = parseInt(mountingDuration);
            const endDate = calculateEndDate(clickedDateStr, duration - 1, team.days_off);
            setSelectedEndDate(endDate);
        }
        selectedTeamRef.current = team;

        setSelectedStartDate(clickedDate);
    };

    //@ts-ignore
    const doesDurationTouchWeekend = (startDate, duration) => {
        let day = startDate.getDay();
        for (let i = 0; i < duration; i++) {
            if (day === 6 || day === 0) {
                // 6 = Saturday, 0 = Sunday
                return true;
            }
            day = (day + 1) % 7;
        }
        return false;
    };

    const clearSelected = () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };

    const cellSelect = async (props: any) => {
        if (!jobs || startOfWeek === null) return;
        props.cancel = true;

        if (props.data) {
            const clickedDate = new Date(props.data.StartTime);
            const clickedDateStr = clickedDate.toDateString();

            // Check if the clicked date is within the allowed date range
            const minDate = new Date(order.mountingRange![0].startDate);
            const maxDate = new Date(order.mountingRange![0].endDate);
            if (clickedDate < minDate || clickedDate > maxDate) {
                return; // Early return if the date is out of range
            }

            //@ts-ignore
            const availableTeams = order.mountingCrews.filter((team) => isWorkdayForTeam(clickedDate, team.days_off));

            //@ts-ignore
            if (availableTeams.length === 0 || teamEventsByDate[clickedDate.toDateString()] === order.mountingCrews.length) {
                return; // Early return if no teams are available or all teams are busy
            }

            //@ts-ignore
            if (isDurationConstraint(clickedDateStr, parseInt(order.mountingDuration), allDates)) {
                Swal.fire({
                    title: `Selected Date Unavailable`,
                    text: `The date you've selected cannot be chosen as the starting point because there aren't enough consecutive workdays following it to accommodate the required duration of the mounting job.`,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return; // Early return if it doesn't satisfy the duration requirement
            }

            //@ts-ignore
            if (availableTeams.length > 1 && doesDurationTouchWeekend(clickedDate, parseInt(order.mountingDuration))) {
                // If multiple teams are available, check their weekend availability
                await handleMultipleTeamsAvailability(availableTeams, clickedDate, clickedDateStr, order.mountingDuration);
            } else {
                // Proceed with the available team
                proceedWithTeamSelection(availableTeams[0], clickedDate, clickedDateStr, order.mountingDuration);
            }

            const selectedTeam = selectedTeamRef.current;
            if (!selectedTeam) {
                // Handle the case where no team is selected
                return;
            }

            // Now use selectedTeam instead of finding a new one
            //@ts-ignore
            const suitableTeamDaysOff = selectedTeam.days_off;
            //@ts-ignore
            const teamId = selectedTeam.id;

            // Check if the clicked date is fully blocked or under duration constraint
            //@ts-ignore
            const isFullyBlocked = teamEventsByDate[clickedDateStr] === order.mountingCrews.length;
            //@ts-ignore
            const isUnderDurationConstraint = isDurationConstraint(clickedDateStr, parseInt(order.mountingDuration), allDates);

            // Return early if no suitable team is found, it's fully blocked, or under duration constraint
            if (!suitableTeamDaysOff || isFullyBlocked || isUnderDurationConstraint) {
                return;
            }

            // Calculate the end date based on the suitable team's days off
            if (order.mountingDuration && suitableTeamDaysOff) {
                //@ts-ignore
                const duration = parseInt(order.mountingDuration);
                //@ts-ignore
                const endDate = calculateEndDate(clickedDateStr, duration - 1, suitableTeamDaysOff);

                fetchEventDuration({ event_id: props.data.Id }).then(({ data }) => {
                    if (!data) return;

                    if (props.data) {
                        if (props.requestType === 'cellSelect') {
                            // @ts-ignore
                            NiceModal.show(EventCreateForm, {
                                //@ts-ignore
                                calendarId: undefined,
                                endTime: endDate.toISOString(),
                                startTime: clickedDateStr,
                                orderId,
                                clientMode: true,
                                //@ts-ignore
                                daysOff: JSON.parse(suitableTeamDaysOff),
                                //@ts-ignore
                                teamId: teamId,
                                clearSelected
                            });
                        } else if (props.requestType === 'eventSelect') {
                            NiceModal.show(EventViewModal, {
                                event: { ...props.data, endTime: endDate.toISOString(), startTime: clickedDateStr },
                                calendarId: undefined
                            });
                        }
                    }
                });
            }
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (calendar.current) calendar.current.refresh();
    }, [selectedStartDate, selectedEndDate]);

    useEffect(() => {
        if (
            order &&
            order.mountingCrews &&
            order.mountingCrews.length > 0 &&
            order.mountingDuration !== null &&
            order.mountingRange !== null
        ) {
            setMountingInitialized(true);
        }
    }, [order]);

    const getDate = (dates: any[]) => {
        const currentDate = new Date();
        dates.sort((a, b) => a - b);
        // Check if there's only one date
        if (dates.length === 1) {
            return dates[0];
        }

        // Check if all the dates are in the future
        if (dates[0] > currentDate) {
            return dates[0];
        }

        // Check if all the dates are in the past
        if (dates[dates.length - 1] < currentDate) {
            return dates[dates.length - 1];
        }

        // Find the first date in the future
        let i = 0;
        if (dates[dates.length - 1] > currentDate) {
            while (i < dates.length && dates[i] < currentDate) {
                i++;
            }
        }

        // Check if the first date in the future is the closest to the current date
        if (dates[i] === currentDate) {
            return currentDate;
        } else {
            return dates[i];
        }
    };

    useEffect(() => {
        if (!jobs) return;
        if (orderId) {
            const filteredEvents = jobs.events.filter((i) => i.project === orderId);
            const dateArray: Date[] = [];
            filteredEvents.forEach((element) => {
                dateArray.push(new Date(element.StartTime));
            });
            if (filteredEvents && filteredEvents.length > 0 && filteredEvents[0].project !== 0) {
                setStartEventDate(getDate(dateArray));
            }
            setIsCalendarReady(true);
        }
    }, [jobs]);

    return useMemo(() => {
        return (
            <>
                {!mountingInitialized ? (
                    <Box sx={{ height: 650, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h5">Request mounting setup from your admin</Typography>
                    </Box>
                ) : (
                    <>
                        {loadButton && isJobsUninitialized ? (
                            <Box sx={{ height: 650, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        fetchJobsQuery({
                                            region: order.mountingCrews?.map((item) => parseInt(item.id)),
                                            userID: user!.id
                                        });
                                    }}
                                >
                                    Load Scheduler
                                </Button>
                            </Box>
                        ) : (
                            <>
                                {isCalendarReady && !isJobsFetching ? (
                                    <ScheduleComponent
                                        ref={calendar}
                                        height={650}
                                        firstDayOfWeek={order && order.mountingCrews && parseInt(order.mountingCrews[0].start_of_week)}
                                        currentView="Month"
                                        eventSettings={{ dataSource: filteredJobs }}
                                        cellClick={startEventDate ? cancelEvent : cellClick}
                                        // cellClick={cancelEvent}
                                        select={startEventDate ? cancelEvent : cellSelect}
                                        eventClick={cancelEvent}
                                        popupOpen={cancelEvent}
                                        cellDoubleClick={cancelEvent}
                                        moreEventsClick={cancelEvent}
                                        eventRendered={eventRendered}
                                        renderCell={renderCell}
                                        selectedDate={startEventDate}
                                        minDate={new Date(order.mountingRange![0].startDate)}
                                        maxDate={new Date(order.mountingRange![0].endDate)}
                                        startHour="08:00"
                                        endHour="22:00"
                                        timeScale={{ enable: false }}
                                        allowMultiRowSelection={false}
                                        allowMultiCellSelection={false}
                                    >
                                        <ViewsDirective>
                                            <ViewDirective option="Month" />
                                        </ViewsDirective>
                                        <Inject services={[Month]} />
                                    </ScheduleComponent>
                                ) : (
                                    <Cover sx={{ height: 650, width: '100%' }} />
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    }, [jobs?.events, isCalendarReady, isJobsFetching, mountingInitialized]);
}

export default ClientScheduler;
