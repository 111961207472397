import { Collections, Person2 } from '@mui/icons-material';
import { lazy } from 'react';
import { NavItemType } from 'types/index.types';
import Loadable from 'ui-component/Loadable';

const GalleryPage = Loadable(lazy(() => import('views/pages/gallery/GalleryPage')));

const clients: NavItemType = {
    id: 'gallery',
    title: 'components.menu.gallery',
    icon: Collections,
    type: 'item',
    url: '/gallery',
    breadcrumbs: false,
    component: <GalleryPage />,
    probhitedRoles: ['client', 'mounting_worker']
};

export default clients;
