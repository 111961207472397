import { ParseKeys, TOptions } from 'i18next';
import { ZodSchema, z } from 'zod';

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export const AuthorSchema = z.object({
    id: z.number(),
    name: z.string(),
    abbr: z.string().max(3)
});

export type Author = z.infer<typeof AuthorSchema>;

export const CountrySchema = z.object({
    id: z.number(),
    name: z.string(),
    abbr: z.string().max(3)
});

export type Country = z.infer<typeof CountrySchema>;

export const RoleSchema = z.object({
    id: z.string(),
    title: z.string()
});

export type Role = z.infer<typeof RoleSchema>;

export const FactorySchema = z.object(
    {
        id: z.number(),
        name: z.string(),
        abbr: z.string().max(3)
    },
    { invalid_type_error: 'Factory is required', required_error: 'Factory is required' }
);

export type Factory = z.infer<typeof CountrySchema>;

export const MinimalCrewSchema = z.object({
    id: z.number(),
    name: z.string(),
    abbr: z.string().max(3)
});

export type MinimalCrew = z.infer<typeof MinimalCrewSchema>;

export const CrewSchema = z.object({
    id: z.number(),
    name: z.string(),
    abbr: z.string(),
    email: z.string(),
    apiCredentials: z.string(),
    start_of_week: z.number(),
    days_off: z.number().array(),
    calendarId: z.string(),
    color: z.string(),
    countryId: z.number(),
    country: CountrySchema.optional()
});

export type Crew = z.infer<typeof CrewSchema>;

export const MinimalProductSchema = z.object({
    id: z.number(),
    imgUrl: z.string(),
    title: z.string()
});

export type MinimalProduct = z.infer<typeof MinimalProductSchema>;

// export const ProductInputSchema = z.object({
//     inputTitle: z.string(),
//     inputType: productInputTypesSchema,
//     inputValues: z.array(z.string()).optional(),
//     inputValue: z.string().optional(),
//     width: z.number().optional(),
//     notify: z.number().optional()
// });

// export type ProductInput = z.infer<typeof ProductInputSchema>;

// export const ProductSchema = z.object({
//     productTitle: z.string(),
//     inputs: z.array(ProductInputSchema),
//     file: z.instanceof(File).optional(),
//     img_url: z.string(),
//     productId: z.number()
// });

// export type Product = z.infer<typeof ProductSchema>;

export type BackModalState = 0;

export type BackButtonExtended = BackModalState | 'extended';

export const nullableAndRefine = (schema: ZodSchema) => {
    return schema.nullable().refine((value) => value !== null, { message: 'This value is required.' });
};

export const EmailSchema = z.object({
    id: z.number(),
    title: z.string(),
    email_object: z.string(),
    email_html: z.string(),
    subject: z.string()
});

export type Email = z.infer<typeof EmailSchema>;

export type ComponentSettings = {
    disableToasts?: boolean;
};

export type TLike = ParseKeys<'translation', TOptions, undefined> | TemplateStringsArray;
