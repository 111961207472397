import NiceModal from '@ebay/nice-modal-react';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { Button, CircularProgress, Stack } from '@mui/material';
import { downloadUrl } from 'helpers/files.helper';
import { handlePermissionCheck } from 'helpers/global.helper';
import { isUserClient } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useCreateClientUserMutation, useFetchClientsQuery, useUpdateClientEmailMutation } from 'store/api/clients.api';
import {
    useChangeFactoryFileSentToClientStatusMutation,
    useChangeFactoryFilesResolveDeadlineMutation,
    useDeleteOrderFactoryFileMutation,
    useFetchOrderDataQuery,
    useFetchOrderFactoryFilesQuery,
    useImproveOrderFactoryFileMutation,
    useLazyDownloadAllFactoryFilesQuery,
    useOrderClientFactoryFileChangeStatusMutation,
    useOrderFactoryFileChangeStatusMutation,
    useResetOrderFactoryFilesMutation,
    useUploadOrderFactoryFileMutation
} from 'store/api/orders.api';
import { useFetchUserOrderFieldsVisabilityQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import Cover from 'ui-component/Cover';
import { getMessage } from 'utils/messages';
import { orderIdAtom } from 'views/pages/order/OrderManagePage';
import AnimateButton from '../extended/AnimateButton';
import { OrderFileDisplayWithAdditions, disapproveConfirmDialog, improveDialog } from './OrderFileDisplayWithAdditions';
import { useTranslation } from 'react-i18next';

interface OrderObject {
    clientId: number | null;
}

interface ClientObject {
    id: number;
    email_address: string | null | undefined;
    clientId: number | null | undefined;
}

const OrderFactoryFiles = () => {
    const { user } = useAuth();
    const [orderId] = useAtom(orderIdAtom);
    const { t } = useTranslation();

    const { data: orderFiles, isFetching: isFetchingFiles } = useFetchOrderFactoryFilesQuery(
        { orderId: orderId!, userId: user?.id!, userRole: user?.role! },
        { skip: !orderId || !user?.id }
    );
    const { data: userFieldsVisibility } = useFetchUserOrderFieldsVisabilityQuery({ userId: user?.id! }, { skip: !user });
    const { data: clients } = useFetchClientsQuery(null);
    const { data: order } = useFetchOrderDataQuery({ orderId: orderId!, userId: user?.id! }, { skip: !orderId || !user });

    const filterClientsByOrder = (clients: ClientObject[], order: OrderObject): ClientObject[] => {
        return clients.filter((client) => client.id === order.clientId);
    };

    const [clientInformation, setClientInformation] = useState<ClientObject | null>(null);

    useEffect(() => {
        if (!clients || !order) return;

        if (clients && order) {
            const clientWithEmail = filterClientsByOrder(clients, order)[0];
            if (clientWithEmail) {
                //@ts-ignore
                setClientInformation(clientWithEmail);
            }
        }
    }, [clients, order]);

    const [changeFileStatus] = useOrderFactoryFileChangeStatusMutation();
    const [changeClientFileStatus] = useOrderClientFactoryFileChangeStatusMutation();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteOrderFactoryFileMutation();
    const [resetFile] = useResetOrderFactoryFilesMutation();
    const [improveFile] = useImproveOrderFactoryFileMutation();
    const [uploadFile, { isLoading: isUploading }] = useUploadOrderFactoryFileMutation();
    const [changeSentToClientStatus] = useChangeFactoryFileSentToClientStatusMutation();
    const [changeResolveDeadline] = useChangeFactoryFilesResolveDeadlineMutation();
    const [downloadAllFiles] = useLazyDownloadAllFactoryFilesQuery();

    const [createClientUser] = useCreateClientUserMutation();
    const [updateClientEmail] = useUpdateClientEmailMutation();

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles) => {
            uploadFile({ orderId: orderId!, uploadedFiles: acceptedFiles, userId: user?.id! });
        },
        noClick: true
    });

    if (!orderId) return <Cover />;

    return (
        <Stack direction={'column'} {...getRootProps()} sx={{ border: '1px dashed rgba(0,0,0,0.2)', p: 1 }}>
            <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ flexWrap: 'wrap', minHeight: 100 }}>
                <input {...getInputProps()} />
                {orderFiles &&
                    orderFiles.length > 0 &&
                    orderFiles.map((file) => {
                        const showFileToClient = isUserClient(user?.role!) ? file.sent_to_client : true;

                        const filteredImprovedFiles = file.improved_files.filter((improvedFile) =>
                            isUserClient(user?.role!) ? improvedFile.sent_to_client : true
                        );

                        return showFileToClient || filteredImprovedFiles.length > 0 ? (
                            <Stack
                                gap={2}
                                direction={{ xs: 'column', sm: 'row-reverse' }}
                                sx={{
                                    border: file.improved_files.length > 0 ? '0.5px rgba(0,0,0,0.5) solid' : 'none',
                                    p: 2,
                                    borderRadius: 2,
                                    height: '100%',
                                    opacity: isUploading || isFetchingFiles || isDeleting ? 0 : 1,
                                    overflow: 'auto',
                                    width: { xs: '100%', sm: 'initial' }
                                }}
                            >
                                {showFileToClient && (
                                    <OrderFileDisplayWithAdditions
                                        file={file}
                                        fileIndex={0}
                                        isVersions={!!file.improved_files.length}
                                        sx={{ width: { sx: '100%', sm: 160, md: 200 } }}
                                        handleReset={() => {
                                            Swal.fire({
                                                title: t('pages.order_manage_page.order_files.are_you_sure_reset'),
                                                icon: 'warning',
                                                showCancelButton: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    resetFile({ fileId: file.id, orderId, userId: user?.id! });
                                                }
                                            });
                                        }}
                                        handleDelete={() =>
                                            Swal.fire({
                                                title: t('pages.order_manage_page.order_files.are_you_sure_to_delete_file'),
                                                text: t('pages.order_manage_page.order_files.once_deleted_cant_recover'),
                                                icon: 'warning',
                                                showCancelButton: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    deleteFile({ fileId: file.id, orderId, userId: user?.id! });
                                                }
                                            })
                                        }
                                        handleDisapprove={() => {
                                            NiceModal.show(disapproveConfirmDialog, {
                                                submit: (data) =>
                                                    toast.promise(
                                                        changeFileStatus({
                                                            comment: data.comment,
                                                            fileId: file.id,
                                                            orderId,
                                                            status: 'disproved',
                                                            uploadedFiles: data.files,
                                                            userId: user?.id!
                                                        }),
                                                        {
                                                            pending: t('notifications.pending.uploadPending'),
                                                            success: t('notifications.success.uploaded')
                                                        },
                                                        { autoClose: 1000 }
                                                    )
                                            });
                                        }}
                                        handleStatusChange={async (value: boolean) => {
                                            clientInformation?.clientId!
                                                ? toast.promise(
                                                      changeSentToClientStatus({
                                                          orderId,
                                                          userId: user?.id!,
                                                          fileId: file.id,
                                                          newStatus: value
                                                      }),
                                                      { pending: getMessage('updatePending'), success: getMessage('updated') }
                                                  )
                                                : Swal.fire({
                                                      title: t('pages.order_manage_page.order_files.client_does_not_have_account'),
                                                      text: t('pages.order_manage_page.order_files.would_you_like_create_one'),
                                                      icon: 'question',
                                                      confirmButtonText: 'Yes',
                                                      cancelButtonText: 'No',
                                                      showCancelButton: true,
                                                      allowOutsideClick: false
                                                  }).then((result) => {
                                                      if (result.isConfirmed) {
                                                          if (
                                                              clientInformation?.email_address === null ||
                                                              clientInformation?.email_address === ''
                                                          ) {
                                                              Swal.fire({
                                                                  title: t('pages.order_manage_page.order_files.input_email_address'),
                                                                  input: 'email',
                                                                  inputLabel: t('pages.order_manage_page.order_files.client_email_address'),
                                                                  showCancelButton: true
                                                                  //@ts-ignore
                                                              }).then((res) => {
                                                                  if (res.isConfirmed) {
                                                                      updateClientEmail({
                                                                          clientId: clientInformation?.id,
                                                                          email: res.value
                                                                      }).then((response) => {
                                                                          //@ts-ignore
                                                                          if (
                                                                              response &&
                                                                              //@ts-ignore
                                                                              response.error &&
                                                                              //@ts-ignore
                                                                              response.error.status === 400 &&
                                                                              //@ts-ignore
                                                                              response.error.data.code === 'email_exists'
                                                                          ) {
                                                                              // If the email is taken, show the input again
                                                                              Swal.fire(
                                                                                  '',
                                                                                  t(
                                                                                      'pages.order_manage_page.order_files.email_already_in_use'
                                                                                  ),
                                                                                  'error'
                                                                              );
                                                                          } else {
                                                                              // Continue with creating the client user
                                                                              createClientUser({
                                                                                  clientId: clientInformation?.id
                                                                              }).then(() => {
                                                                                  Swal.fire(
                                                                                      t(
                                                                                          'pages.order_manage_page.order_files.client_account_created'
                                                                                      ),
                                                                                      '',
                                                                                      'success'
                                                                                  );
                                                                                  toast.promise(
                                                                                      changeSentToClientStatus({
                                                                                          //@ts-ignore
                                                                                          orderId,
                                                                                          userId: user?.id!,
                                                                                          fileId: file.id,
                                                                                          newStatus: value
                                                                                      }),
                                                                                      {
                                                                                          pending: t('notifications.pending.updatePending'),
                                                                                          success: t('notifications.success.updated')
                                                                                      }
                                                                                  );
                                                                              });
                                                                          }
                                                                      });
                                                                  } else {
                                                                      Swal.fire(
                                                                          t(
                                                                              'pages.order_manage_page.order_files.client_account_creation_cancelled'
                                                                          ),
                                                                          '',
                                                                          'info'
                                                                      );
                                                                  }
                                                              });
                                                          } else {
                                                              //@ts-ignore
                                                              createClientUser({ clientId: clientInformation?.id }).then(() => {
                                                                  Swal.fire(
                                                                      t('pages.order_manage_page.order_files.client_account_created'),
                                                                      '',
                                                                      'success'
                                                                  );
                                                                  toast.promise(
                                                                      changeSentToClientStatus({
                                                                          //@ts-ignore
                                                                          orderId,
                                                                          userId: user?.id!,
                                                                          fileId: file.id,
                                                                          newStatus: value
                                                                      }),
                                                                      {
                                                                          pending: t('notifications.pending.updatePending'),
                                                                          success: t('notifications.success.updated')
                                                                      }
                                                                  );
                                                              });
                                                          }
                                                      } else {
                                                          toast.promise(
                                                              changeSentToClientStatus({
                                                                  //@ts-ignore
                                                                  orderId,
                                                                  userId: user?.id!,
                                                                  fileId: file.id,
                                                                  newStatus: value
                                                              }),
                                                              {
                                                                  pending: t('notifications.pending.updatePending'),
                                                                  success: t('notifications.success.updated')
                                                              }
                                                          );
                                                      }
                                                  });
                                        }}
                                        handleChangeResolveDeadline={(value: number, value2: string) => {
                                            changeResolveDeadline({
                                                fileId: file.id,
                                                deadlineDate: value,
                                                comment: value2,
                                                userId: user?.id!,
                                                orderId
                                            });
                                        }}
                                        handleClientStatusChange={(value: boolean) => {
                                            toast.promise(
                                                changeClientFileStatus({
                                                    orderId,
                                                    userId: user?.id!,
                                                    fileId: file.id,
                                                    newStatus: value
                                                }),
                                                {
                                                    pending: t('notifications.pending.updatePending'),
                                                    success: t('notifications.success.updated')
                                                }
                                            );
                                        }}
                                        handleApprove={() => {
                                            toast.promise(
                                                changeFileStatus({
                                                    fileId: file.id,
                                                    orderId,
                                                    status: 'approved',
                                                    userId: user?.id!
                                                }),
                                                {
                                                    pending: t('notifications.pending.updatePending'),
                                                    success: t('notifications.success.updated')
                                                },
                                                { autoClose: 1000 }
                                            );
                                        }}
                                        handleImprove={() => {
                                            NiceModal.show(improveDialog, {
                                                submit: (data) => {
                                                    toast.promise(
                                                        improveFile({
                                                            fileId: file.id,
                                                            orderId,
                                                            uploadedFiles: [data.file],
                                                            userId: user?.id!
                                                        }),
                                                        {
                                                            pending: t('notifications.pending.updatePending'),
                                                            success: t('notifications.success.updated')
                                                        },
                                                        { autoClose: 1000 }
                                                    );
                                                }
                                            });
                                        }}
                                        isDisabled={file.improved_files.length > 0}
                                    />
                                )}
                                {filteredImprovedFiles.length > 0 && showFileToClient && (
                                    // Only show the arrow if the original file is displayed.
                                    <KeyboardDoubleArrowLeft sx={{ my: 'auto' }} />
                                )}

                                {filteredImprovedFiles
                                    .slice(0)
                                    .sort((a, b) => {
                                        if (a.id > b.id) return 1;
                                        else if (a.id < b.id) return -1;
                                        else return 0;
                                    })
                                    .map((improvedFile, improvedIndex) => {
                                        const isNotLast = improvedIndex !== filteredImprovedFiles.length - 1;

                                        return (
                                            <>
                                                <OrderFileDisplayWithAdditions
                                                    file={improvedFile}
                                                    fileIndex={improvedIndex + 1}
                                                    isVersions={true}
                                                    sx={{ width: { sx: '100%', sm: 160, md: 200 } }}
                                                    handleReset={() => {
                                                        Swal.fire({
                                                            title: t('pages.order_manage_page.order_files.are_you_sure_reset'),
                                                            icon: 'warning',
                                                            showCancelButton: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                resetFile({ fileId: improvedFile.id, orderId, userId: user?.id! });
                                                            }
                                                        });
                                                    }}
                                                    handleDelete={() =>
                                                        Swal.fire({
                                                            title: t('pages.order_manage_page.order_files.are_you_sure_to_delete_file'),
                                                            text: t('pages.order_manage_page.order_files.once_deleted_cant_recover'),
                                                            icon: 'warning',
                                                            showCancelButton: true
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                deleteFile({ fileId: improvedFile.id, orderId, userId: user?.id! });
                                                            }
                                                        })
                                                    }
                                                    handleStatusChange={async (value: boolean) => {
                                                        clientInformation?.clientId!
                                                            ? toast.promise(
                                                                  changeSentToClientStatus({
                                                                      orderId,
                                                                      userId: user?.id!,
                                                                      fileId: improvedFile.id,
                                                                      newStatus: value
                                                                  }),
                                                                  {
                                                                      pending: t('notifications.pending.updatePending'),
                                                                      success: t('notifications.success.updated')
                                                                  }
                                                              )
                                                            : Swal.fire({
                                                                  title: t(
                                                                      'pages.order_manage_page.order_files.client_does_not_have_account'
                                                                  ),
                                                                  text: t('pages.order_manage_page.order_files.would_you_like_create_one'),
                                                                  icon: 'question',
                                                                  confirmButtonText: 'Yes',
                                                                  cancelButtonText: 'No',
                                                                  showCancelButton: true,
                                                                  allowOutsideClick: false
                                                              }).then((result) => {
                                                                  if (result.isConfirmed) {
                                                                      if (
                                                                          clientInformation?.email_address === null ||
                                                                          clientInformation?.email_address === ''
                                                                      ) {
                                                                          Swal.fire({
                                                                              title: t(
                                                                                  'pages.order_manage_page.order_files.input_email_address'
                                                                              ),
                                                                              input: 'email',
                                                                              inputLabel: t(
                                                                                  'pages.order_manage_page.order_files.client_email_address'
                                                                              ),
                                                                              showCancelButton: true
                                                                              //@ts-ignore
                                                                          }).then((res) => {
                                                                              if (res.isConfirmed) {
                                                                                  updateClientEmail({
                                                                                      clientId: clientInformation?.id,
                                                                                      email: res.value
                                                                                  }).then((response) => {
                                                                                      //@ts-ignore
                                                                                      if (
                                                                                          //@ts-ignore
                                                                                          response.error.status === 400 &&
                                                                                          //@ts-ignore
                                                                                          response.error.data.code === 'email_exists'
                                                                                      ) {
                                                                                          // If the email is taken, show the input again
                                                                                          Swal.fire(
                                                                                              '',
                                                                                              t(
                                                                                                  'pages.order_manage_page.order_files.email_already_in_use'
                                                                                              ),
                                                                                              'error'
                                                                                          );
                                                                                      } else {
                                                                                          // Continue with creating the client user
                                                                                          createClientUser({
                                                                                              clientId: clientInformation?.id
                                                                                          }).then(() => {
                                                                                              Swal.fire(
                                                                                                  t(
                                                                                                      'pages.order_manage_page.order_files.client_account_created'
                                                                                                  ),
                                                                                                  '',
                                                                                                  'success'
                                                                                              );
                                                                                              toast.promise(
                                                                                                  changeSentToClientStatus({
                                                                                                      //@ts-ignore
                                                                                                      orderId,
                                                                                                      userId: user?.id!,
                                                                                                      fileId: improvedFile.id,
                                                                                                      newStatus: value
                                                                                                  }),
                                                                                                  {
                                                                                                      pending: t(
                                                                                                          'notifications.pending.updatePending'
                                                                                                      ),
                                                                                                      success:
                                                                                                          t('notifications.success.updated')
                                                                                                  }
                                                                                              );
                                                                                          });
                                                                                      }
                                                                                  });
                                                                              } else {
                                                                                  Swal.fire(
                                                                                      t(
                                                                                          'pages.order_manage_page.order_files.client_account_creation_cancelled'
                                                                                      ),
                                                                                      '',
                                                                                      'error'
                                                                                  );
                                                                              }
                                                                          });
                                                                      } else {
                                                                          createClientUser({
                                                                              //@ts-ignore
                                                                              clientId: clientInformation?.id
                                                                          }).then(() => {
                                                                              Swal.fire(
                                                                                  t(
                                                                                      'pages.order_manage_page.order_files.client_account_created'
                                                                                  ),
                                                                                  'success'
                                                                              );
                                                                              toast.promise(
                                                                                  changeSentToClientStatus({
                                                                                      //@ts-ignore
                                                                                      orderId,
                                                                                      userId: user?.id!,
                                                                                      fileId: improvedFile.id,
                                                                                      newStatus: value
                                                                                  }),
                                                                                  {
                                                                                      pending: t('notifications.pending.updatePending'),
                                                                                      success: t('notifications.success.updated')
                                                                                  }
                                                                              );
                                                                          });
                                                                      }
                                                                  } else {
                                                                      toast.promise(
                                                                          changeSentToClientStatus({
                                                                              //@ts-ignore
                                                                              orderId,
                                                                              userId: user?.id!,
                                                                              fileId: improvedFile.id,
                                                                              newStatus: value
                                                                          }),
                                                                          {
                                                                              pending: t('notifications.pending.updatePending'),
                                                                              success: t('notifications.success.updated')
                                                                          }
                                                                      );
                                                                  }
                                                              });
                                                    }}
                                                    handleChangeResolveDeadline={(value: number, value2: string) => {
                                                        changeResolveDeadline({
                                                            fileId: improvedFile.id,
                                                            deadlineDate: value,
                                                            comment: value2,
                                                            userId: user?.id!,
                                                            orderId
                                                        });
                                                    }}
                                                    handleClientStatusChange={(value: boolean) => {
                                                        toast.promise(
                                                            changeClientFileStatus({
                                                                orderId,
                                                                userId: user?.id!,
                                                                fileId: improvedFile.id,
                                                                newStatus: value
                                                            }),
                                                            {
                                                                pending: t('notifications.pending.updatePending'),
                                                                success: t('notifications.success.updated')
                                                            }
                                                        );
                                                    }}
                                                    handleDisapprove={() => {
                                                        NiceModal.show(disapproveConfirmDialog, {
                                                            submit: (data) =>
                                                                toast.promise(
                                                                    changeFileStatus({
                                                                        comment: data.comment,
                                                                        fileId: improvedFile.id,
                                                                        orderId,
                                                                        status: 'disproved',
                                                                        uploadedFiles: data.files,
                                                                        userId: user?.id!
                                                                    }),
                                                                    {
                                                                        pending: t('notifications.pending.updatePending'),
                                                                        success: t('notifications.success.updated')
                                                                    },
                                                                    { autoClose: 1000 }
                                                                )
                                                        });
                                                    }}
                                                    handleApprove={() => {
                                                        toast.promise(
                                                            changeFileStatus({
                                                                fileId: improvedFile.id,
                                                                orderId,
                                                                status: 'approved',
                                                                userId: user?.id!
                                                            }),

                                                            {
                                                                pending: t('notifications.pending.updatePending'),
                                                                success: t('notifications.success.updated')
                                                            },
                                                            { autoClose: 1000 }
                                                        );
                                                    }}
                                                    handleImprove={() => {
                                                        NiceModal.show(improveDialog, {
                                                            submit: (data) => {
                                                                toast.promise(
                                                                    improveFile({
                                                                        fileId: improvedFile.primary_id || improvedFile.id,
                                                                        orderId,
                                                                        uploadedFiles: [data.file],
                                                                        userId: user?.id!
                                                                    }),
                                                                    {
                                                                        pending: t('notifications.pending.updatePending'),
                                                                        success: t('notifications.success.updated')
                                                                    },
                                                                    { autoClose: 1000 }
                                                                );
                                                            }
                                                        });
                                                    }}
                                                    isDisabled={isNotLast}
                                                />
                                                {isNotLast && <KeyboardDoubleArrowLeft sx={{ my: 'auto' }} />}
                                            </>
                                        );
                                    })}
                            </Stack>
                        ) : null;
                    })}
                {(isUploading || isFetchingFiles || isDeleting) && (
                    <CircularProgress
                        sx={{
                            opacity: '1',
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                )}
            </Stack>
            <Stack
                direction={{ xs: 'row', sm: 'row' }}
                sx={{ justifyContent: { xs: 'center', sm: 'start' }, alignItems: { xs: 'center', sm: 'initial' }, mt: 2 }}
                gap={2}
            >
                {handlePermissionCheck(userFieldsVisibility!, 'orderAllowChange') && (
                    <AnimateButton>
                        <Button variant={'contained'} onClick={open}>
                            {t('pages.order_manage_page.order_files.upload_a_file')}
                        </Button>
                    </AnimateButton>
                )}
                <Button
                    variant={'contained'}
                    disabled={orderFiles?.length === 0}
                    onClick={async () => {
                        const file = await downloadAllFiles({ orderId }).then((result) => result.data?.file);
                        if (file) downloadUrl({ src: file, name: 'files' });
                    }}
                >
                    {t('pages.order_manage_page.order_files.download_all')}
                </Button>
            </Stack>
        </Stack>
    );
};

export default OrderFactoryFiles;
