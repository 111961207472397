import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Checkbox, FormControlLabel, Modal } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import the Slate editor factory.
import { Descendant } from 'slate';

// Import the Slate components and React plugin.

import { useFetchVersionQuery, useToggleVersionNotificationsMutation } from 'store/api/system.api';
import MainCard from 'ui-component/cards/MainCard';
import ModalFormCard from 'ui-component/cards/ModalCard';
import SlateWysiwyg from 'ui-component/inputs/SlateWysiwyg';

const VersionLogPage = () => {
    const { user } = useAuth();
    const { t } = useTranslation();

    const { data: version } = useFetchVersionQuery({ user_id: user?.id! }, { skip: !user });

    const [_jsonEditorValue, setJsonEditorValue] = useState('');
    const [initialValue, setInitialValue] = useState<Descendant[]>();

    useEffect(() => {
        if (!version) return;
        setJsonEditorValue(version.content);
        if (version.content && version.content !== '[]') {
            setInitialValue(JSON.parse(version.content));
        } else {
            setInitialValue([
                {
                    type: 'paragraph',
                    children: [{ text: '' }]
                }
            ]);
        }
    }, [version]);
    return (
        <MainCard description={version?.version} title={t('components.version_log.version_log')}>
            {initialValue && <SlateWysiwyg readOnly initialValue={initialValue} />}
        </MainCard>
    );
};

export const VersionLogModal = NiceModal.create(() => {
    const { user } = useAuth();
    const modal = useModal();
    const { t } = useTranslation();

    const { data: version } = useFetchVersionQuery({ user_id: user?.id! }, { skip: !user });
    const [toggleVersionNotifications] = useToggleVersionNotificationsMutation();

    const [_jsonEditorValue, setJsonEditorValue] = useState('');
    const [initialValue, setInitialValue] = useState<Descendant[]>();

    useEffect(() => {
        if (!version) return;
        setJsonEditorValue(version.content);
        if (version.content && version.content !== '[]') {
            setInitialValue(JSON.parse(version.content));
        } else {
            setInitialValue([
                {
                    type: 'paragraph',
                    children: [{ text: '' }]
                }
            ]);
        }
    }, [version]);

    return (
        <Modal open={modal.visible} onClose={modal.remove} sx={{ overflowY: 'scroll' }}>
            <ModalFormCard
                title={`Scaleerp`}
                subtitle={version?.version}
                onClose={modal.remove}
                gutter
                Header={
                    <>
                        {version && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            toggleVersionNotifications({
                                                disabled_user_notifications: event.target.checked ? false : true,
                                                user_id: user?.id!
                                            });
                                        }}
                                        checked={version.notifications_disabled ? false : true}
                                        size="small"
                                    />
                                }
                                label={t('components.version_log.stay_up_to_date')}
                                sx={{
                                    ml: 1,
                                    '& .MuiFormControlLabel-label': {
                                        mt: 0.5
                                    }
                                }}
                            />
                        )}
                    </>
                }
            >
                {initialValue && <SlateWysiwyg readOnly initialValue={initialValue} />}
            </ModalFormCard>
        </Modal>
    );
});

export default VersionLogPage;
