import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const { protocol, hostname } = window.location;
const apiPath = '/wp-json/jwt-auth';
const API_URL = `${protocol}//${hostname}${apiPath}`;

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
            headers.set('authorization', `Bearer ${serviceToken}`);
            return headers;
        }
    }
});

const baseQueryWithTokenCheck: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 403) {
        console.log('Invalid token', result);
    }
    return result;
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithTokenCheck,
    keepUnusedDataFor: 5,
    tagTypes: [
        'Orders',
        'OrderProducts',
        'OrderFactoryFiles',
        'OrderSurveyFiles',
        'OrderExternalDesignerFiles',
        'OrderFabricationFiles',
        'Order3dFiles',
        'OrderGallery',
        'OrderBackup',
        'Users',
        'Notifications',
        'OldNotifications',
        'Clients',
        'Factories',
        'Countries',
        'Crews',
        'Products',
        'Version',
        'MountingEvents',
        'OrderShippings',
        'ChatMessages',
        'Emails',
        'SystemInit'
    ],
    endpoints: () => ({})
});
