import { Crew } from 'types/mounting.types';
import { MinimalUser } from 'types/user.types';
import { UserFormType } from 'views/forms/users/UserForm';
import { Country, Factory, Role } from '../../types/global.types';
import { api } from './api';

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        fetchUserData: builder.query<
            {
                username: string;
                first_name: string;
                last_name: string;
                phone_number: string;
                user_email: string;
                avatar: string;
                all_countries: Country[];
                user_countries: Country[];
                all_factories: Factory[];
                user_factories: Factory[];
                all_crews: Crew[];
                user_crews: Crew[];
                language: 'en' | 'nb';
                user_origin_country: Country[];
                user_role: Role;
                all_roles: Role[];
            },
            { userId: number }
        >({
            providesTags: ['Users'],
            query: ({ userId }) => {
                const requestData = {
                    user_id: userId
                };
                return { url: '/v1/users/user_data', method: 'POST', body: requestData };
            }
        }),
        editUserData: builder.mutation<null, { targetId: number; userId: number; userData: UserFormType }>({
            invalidatesTags: ['Users'],
            query: ({ targetId, userId, userData }) => {
                const requestData = new FormData();
                requestData.append('user_id', userId.toString());
                requestData.append('target_id', targetId.toString());
                if (userData.avatar instanceof FileList) requestData.append('avatar', userData.avatar[0]);
                requestData.append('user_data', JSON.stringify(userData));
                return { url: '/v1/users/update_user', method: 'POST', body: requestData };
            }
        }),
        addUser: builder.mutation<number, { userId: number; userData: UserFormType }>({
            invalidatesTags: ['Users'],
            query: ({ userId, userData }) => {
                const requestData = new FormData();
                requestData.append('user_id', userId.toString());
                if (userData.avatar instanceof FileList) requestData.append('avatar', userData.avatar[0]);
                requestData.append('user_data', JSON.stringify(userData));
                return { url: '/v1/users/create_new_user', method: 'POST', body: requestData };
            }
        }),
        fetchUsers: builder.query<{ roles: string; users: MinimalUser[] }, { userId: number }>({
            providesTags: ['Users', 'Notifications'],
            query: ({ userId }) => {
                const requestData = new FormData();
                requestData.append('user_id', userId.toString());
                return { url: 'v1/users/get_all_users', method: 'POST', body: requestData };
            }
        }),
        fetchMisc: builder.query<{ all_countries: Country[]; all_factories: Factory[]; all_crews: Crew[]; all_roles: Role[] }, null>({
            providesTags: ['Users', 'Factories', 'Countries', 'Crews'],
            query: () => {
                return { url: '/v1/users/misc_data', method: 'POST' };
            }
        }),
        suspendUsers: builder.mutation<null, { userIds: number[] }>({
            invalidatesTags: ['Users'],
            query: ({ userIds }) => {
                const requestData = {
                    userIds
                };
                return { url: '/v1/users/archive_user', method: 'POST', body: requestData };
            }
        })
    })
});

export const {
    useFetchUserDataQuery,
    useEditUserDataMutation,
    useFetchUsersQuery,
    useFetchMiscQuery,
    useAddUserMutation,
    useSuspendUsersMutation
} = userApi;
