import moment from 'moment';
import { z } from 'zod';
import { MinimalClientSchema } from './client.types';
import { FileSchema } from './file.types';
import { CountrySchema, FactorySchema } from './global.types';
import { crewSchema } from './mounting.types';

export const compiledOrderSchema = z.object({
    // id: z.number(),
    clientID: z.number(),
    clientTitle: z.string(),
    countryId: z.number(),
    drawings: z.string(),
    drawingsData: z.object({ approved_by: z.string(), approved_date: z.string(), waiting_for_roles: z.string().array() }).nullable(),
    lastDrawingChangeDate: z.string(),
    employeeId: z.number(),
    factoryId: z.number(),
    factory: z.optional(FactorySchema),
    mainStatus: z.string(),
    mounting: z.string(),
    mountingComment: z.string(),
    onHoldReason: z.string().nullable(),
    statusReason: z.string().nullable(),
    orderCreated: z.string(),
    orderEmployees: z.number().array(),
    orderId: z.number(),
    orderIdNum: z.string(),
    orderNum: z.string(),
    orderOwner: z.string(),
    orderStatus: z.string(),
    orderStatusVal: z.string(),
    orderTitle: z.string(),
    ownerInitials: z.string(),
    productCount: z.string(),
    requiresAttention: z.boolean().optional(),
    shippingAddress: z.string().optional(),
    shippingDate: z.string(),
    shippingNo: z.string(),
    shippingStatus: z.any(),
    shippingStatusVal: z.any(),
    shippingId: z.number()
});
export type CompiledOrder = z.infer<typeof compiledOrderSchema>;

export const orderStatusIdSchema = z.union([
    z.literal('request'),
    z.literal('confirmed'),
    z.literal('on_hold'),
    z.literal('cancelled'),
    z.literal('passive_request'),
    z.literal('supplier_request'),
    z.literal('supplier_confirmed')
]);

export const orderStatusTextSchema = z.union([
    z.literal('Request'),
    z.literal('Confirmed'),
    z.literal('On Hold'),
    z.literal('Cancelled'),
    z.literal('Passive Request'),
    z.literal('Related Order Request'),
    z.literal('Related Order Confirmed')
]);

export const orderStatusSchema = z.object(
    {
        id: orderStatusIdSchema
    },
    { invalid_type_error: 'Order Status is required', required_error: 'Order Status is required' }
);

export type OrderStatus = z.infer<typeof orderStatusSchema>;

export const orderSchema = z.object({
    clientId: z.number().nullable(),
    countryId: z.number().nullable(),
    drawings: z.string().nullable(),
    employeeIds: z.array(z.number()),
    factoryId: z.number().nullable(),
    mainStatus: z.string(),
    mounting: z.string().nullable(),
    onHoldReason: z.string().nullable(),
    statusReason: z.string().nullable(),
    orderCreated: z.string(),
    orderId: z.number().nullable(),
    orderChanged: z.boolean(),
    // other - fix / local fix / extra, related - related orders, default - not any mentioned before
    orderType: z.union([z.literal('other'), z.literal('default'), z.literal('related')]),
    orderNum: z.string(),
    orderOwner: z.string(),
    client_display_status: z.boolean(),
    orderStatus: orderStatusSchema,
    orderStatusId: orderStatusIdSchema,
    orderTitle: z.string().min(1, { message: 'Order Title is required' }),
    orderParentId: z.number().nullable(),
    ownerInitials: z.string(),
    parentOrder: z.number().optional(),
    originalOrder: z.number().optional(),
    childrenOrders: z.object({ orderId: z.number(), orderNum: z.string() }).array(),
    inquiry: z.string(),
    mounting_crews: crewSchema.array(),
    mounting_duration: z.coerce.number().min(1, { message: 'Value cannot be less than 1' }).nullable(),
    mounting_range: z.object({ startDate: z.coerce.date(), endDate: z.coerce.date(), key: z.string() }).array().nullable(),
    shippingAddress: z.string(),
    factory_object: FactorySchema,
    shippingDate: z.custom((value) => moment.isMoment(value)),
    shippingNo: z.string(),
    shippingStatus: z.any(),
    changeReason: z.string(),
    deadlineDate: z.custom((value) => moment.isMoment(value)),
    overdueDate: z.custom((value) => moment.isMoment(value)),
    author: z.string().nullable(),
    notifications_disabled: z.boolean(),
    shippingId: z.number().nullable(),
    representative: z.any()
});

export type Order = z.infer<typeof orderSchema>;

export const shippingStatusSchema = z.union([z.literal('waiting_for_pickup'), z.literal('en_route'), z.literal('delivered')]);

export type ShippingStatus = z.infer<typeof shippingStatusSchema>;

export const shippingStatusTableSchema = z.union([
    z.literal('all'),
    z.literal('waiting_for_pickup'),
    z.literal('en_route'),
    z.literal('delivered')
]);

export type ShippingStatusTable = z.infer<typeof shippingStatusTableSchema>;

export const employeeSchema = z.object({
    factories: z.string(),
    id: z.number(),
    role: z.string(),
    readable_role: z.string(),
    username: z.string()
});
export type Employee = z.infer<typeof employeeSchema>;

export const numberSortSchema = z.union([z.literal('desc'), z.literal('asc')]);

export type NumberSort = z.infer<typeof numberSortSchema>;

export const mainStatusSchema = z.union([z.literal('all'), z.literal('Open'), z.literal('Finished'), z.literal('Cancelled')]);

export type MainStatus = z.infer<typeof mainStatusSchema>;

export const minimalOrderSchema = orderSchema.pick({
    orderTitle: true,
    orderStatus: true,
    clientId: true,
    mounting: true,
    shippingAddress: true,
    factoryId: true,
    onHoldReason: true
});

export type MinimalOrder = z.infer<typeof minimalOrderSchema>;

export const orderCreateSchema = minimalOrderSchema.extend({
    client: MinimalClientSchema,
    customAddress: z.string().nullable(),
    factory: FactorySchema,
    orderStatus: orderStatusSchema
});

export type OrderCreate = z.infer<typeof orderCreateSchema>;

export const orderManageSchema = orderSchema.extend({
    orderParent: compiledOrderSchema.nullable(),
    client: MinimalClientSchema,
    country: CountrySchema.nullable(),
    factory: FactorySchema,
    customAddress: z.string().nullable(),
    orderStatus: orderStatusSchema,
    employees: z.array(employeeSchema).nullable(),
    owners: z.array(employeeSchema).nullable()
});

export type OrderManage = z.infer<typeof orderManageSchema>;

export const shippingProjectStatusSchema = z.union([z.literal('not_shipped'), z.literal('partial'), z.literal('full')]);

export type ShippingProjectStatus = z.infer<typeof shippingProjectStatusSchema>;

export const shippingManageProjectSchema = z
    .object({
        variant: z.union([z.literal('custom'), z.literal('project')]),
        id: z.string(),
        order: z
            .object({
                id: z.number(),
                orderTitle: z.string(),
                orderNum: z.string(),
                deliveryAddress: z.string().optional(),
                clientId: z.number().optional()
            })
            .nullable()
            .optional(),
        deliveryAddress: z.string(),
        custom: z.string().nullable().optional(),
        client: z.object({ id: z.number(), title: z.string() }).nullable(),
        status: shippingProjectStatusSchema.nullable().optional(),
        isNew: z.boolean().optional()
    })
    .refine((data) => data.custom || data.order);

export type ShippingManageProject = z.infer<typeof shippingManageProjectSchema>;

export const shippingManageSchema = z.object({
    shippingId: z
        .number()
        .nullable()
        .refine((value) => value !== null),
    number: z.string(),
    userId: z.number(),
    factoryId: z
        .number()
        .nullable()
        .refine((value) => value !== null),
    factory: FactorySchema.nullable().refine((value) => value !== null),
    shippingCountryId: z
        .number()
        .nullable()
        .refine((value) => value !== null),
    shippingCounty: CountrySchema.nullable().refine((value) => value !== null),
    shipmentDate: z.custom((value) => moment.isMoment(value)),
    cmr: z.string(),
    deliveryDate: z.custom((value) => moment.isMoment(value)),
    status: shippingStatusSchema.optional(),
    projects: shippingManageProjectSchema.array()
});

export type ShippingManage = z.infer<typeof shippingManageSchema>;

//Products

export const productImageTypesSchema = z.union([z.literal('image/gif'), z.literal('image/jpeg'), z.literal('image/png')]);
export type ProductImageTypes = z.infer<typeof productImageTypesSchema>;
export const ProductImageTypesConst = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];

export const productDocumentTypesSchema = z.union([z.literal('application/pdf'), z.literal('application/json')]);
export type ProductDocumentTypes = z.infer<typeof productDocumentTypesSchema>;
export const ProductDocumentTypesConst = [
    'application/pdf',
    'application/json',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/doc',
    'application/docx'
];

export const productCombinedTypesSchema = z.union([productImageTypesSchema, productDocumentTypesSchema]);
export type ProductCombinedTypes = z.infer<typeof productCombinedTypesSchema>;

export const productInputTypesSchema = z.union([
    z.literal('text'),
    z.literal('select'),
    z.literal('date'),
    z.literal('file')
    // z.literal('wysyg')
]);

export type ProductInputTypes = z.infer<typeof productInputTypesSchema>;

export const fileDataSchema = z.object({
    url: z.string(),
    file_type: productCombinedTypesSchema,
    file_name: z.string(),
    file_id: z.string()
});

export type FileData = z.infer<typeof fileDataSchema>;

export const productInputSchema = z.object({
    id: z.string().optional(),
    uniqueId: z.string().optional(),
    inputTitle: z.string(),
    inputFileInitiallyExists: z.boolean().optional(),
    productId: z.number().optional().nullable(),
    inputType: productInputTypesSchema,
    inputValues: z.string().array().optional(),
    inputValue: z.string().optional(),
    inputFile: fileDataSchema.optional(),
    notify: z.number().optional(),
    width: z.number().min(40)
});

export type ProductInput = z.infer<typeof productInputSchema>;

export const productSchema = z.object({
    productTitle: z.string(),
    inputs: productInputSchema.array(),
    file: z.instanceof(File).optional(),
    imgUrl: z.string().optional().nullable(),
    productId: z.number().optional().nullable(),
    uniqueId: z.string().optional(),
    selected: z.boolean().optional(),
    orderTitle: z.string().optional()
});

export type Product = z.infer<typeof productSchema>;

export const orderProduct = z.object({
    drawingDate: z.string(),
    drawingDateChangeReason: z.string(),
    isDrawingEnabled: z.boolean(),
    products: productSchema.array()
});

export type OrderProduct = z.infer<typeof orderProduct>;

// export interface Product {
//     productTitle: string;
//     inputs: ProductInput[];
//     file?: File;
//     img_url?: string;
//     productId?: string;
// }

// export const productValuesSchema = z.object({
//     productTitle: z.string(),
//     inputs: productInputSchema.array(),
//     inputsValues: z.array(
//         z.object({
//             inputValue: z.union([z.string(), fileDataSchema, z.any()]).optional(),
//             inputFile: z.union([z.instanceof(FileList), fileDataSchema]).optional()
//         })
//     ),
//     file: z.instanceof(File).optional(),
//     img_url: z.string().optional(),
//     orderTitle: z.string().optional(),
//     productId: z.string().optional(),
//     unique_id: z.string().optional()
// });

// export type ProductValues = z.infer<typeof productValuesSchema>;

// export const productValuesExportImageSchema = z.object({
//     productTitle: z.string(),
//     inputs: productInputSchema.array(),
//     inputsValues: z.object({ inputValue: z.string() }).array(),
//     file: z.instanceof(File).optional(),
//     img_url: z.string().optional(),
//     productId: z.string().optional(),
//     unique_id: z.string().optional()
// });

// export type ProductValuesExportImage = z.infer<typeof productValuesExportImageSchema>;

// Files

export const orderFileStatusSchema = z.union([z.literal('approved'), z.literal('waiting'), z.literal('disproved')]);

export type OrderFileStatus = z.infer<typeof orderFileStatusSchema>;

export const orderFileWithAdditionsSchema = FileSchema.extend({
    status: orderFileStatusSchema,
    author: z.string(),
    date: z.string(),
    comment: z.string(),
    client_approved: z.boolean(),
    client_approved_date: z.string(),
    first_approved_date: z.string().nullable(),
    first_approved_user: z.object({ role: z.string(), username: z.string(), id: z.number() }).nullable(),
    disapprove_reason: z.string().nullable(),
    disapproved_files: FileSchema.array(),
    disapproved_date: z.string(),
    disapproved_user: z.string(),
    name: z.string(),
    primary_id: z.number().optional(),
    second_approved_date: z.string().nullable(),
    second_approved_user: z.object({ role: z.string(), username: z.string(), id: z.number() }).nullable(),
    sent_to_client: z.boolean(),
    sent_to_client_date: z.string().nullable(),
    sent_to_client_user: z.string().nullable(),
    sent_to_client_notification_id: z.number().nullable(),
    disapproved_by_client: z.string(),
    disapproved_by_client_date: z.string(),
    resolve_deadline: z.number().nullable(),
    resolve_deadline_comment: z.string().nullable(),
    resolve_deadline_notification_id: z.number().nullable()
});

export type OrderFileWithAdditions = z.infer<typeof orderFileWithAdditionsSchema>;

export const orderFileWithAdditionsRootSchema = orderFileWithAdditionsSchema.extend({
    improved_files: orderFileWithAdditionsSchema.array()
});

export type OrderFileWithAdditionsRoot = z.infer<typeof orderFileWithAdditionsRootSchema>;

export const orderFileSchema = FileSchema.extend({
    user_name: z.string(),
    upload_date: z.string()
});

export type OrderFile = z.infer<typeof orderFileSchema>;

export const shippingProjectSchema = z.object({
    projectPartialStatus: z.string(),
    projectNum: z.string(),
    projectNumLink: z.string(),
    projectId: z.number(),
    owner: z.number(),
    ownerName: z.string(),
    client: z.number(),
    clientLink: z.string(),
    clientName: z.string(),
    shipAddr: z.string()
});

export type ShippingProject = z.infer<typeof shippingProjectSchema>;

export const shippingSchema = z.object({
    shippingNum: z.string(),
    shippingNumLink: z.string(),
    shippingID: z.number(),
    projectNum: z.number(),
    addedProjects: z.object({ project: z.number(), delivery_address: z.string(), client: z.number() }).array(),
    projects: shippingProjectSchema.array().optional(),
    dispatchment: z.number(),
    delivery: z.number(),
    status: z.string(),
    status_val: z.string(),
    countryId: z.number(),
    country: CountrySchema,
    factoryId: z.number(),
    factory: FactorySchema
});

export type Shipping = z.infer<typeof shippingSchema>;

export const AllshippingSchema = z.object({
    ship_status: z.string(),
    partial_reason: z.string(),
    partial_shipment_contents: z.string(),
    partial_status: z.string(),
    ship_date: z.string(),
    ship_id: z.string(),
    ship_number: z.string()
});

export type AllShipping = z.infer<typeof AllshippingSchema>;

export const galleryFileSchema = FileSchema.extend({
    link: z.string(),
    text: z.string(),
    categories: z.string().array(),
    title: z.string().optional(),
    order_id: z.number().optional()
});

export type GalleryFile = z.infer<typeof galleryFileSchema>;

export const galleryDisplaySchema = z.union([z.literal('list'), z.literal('masonry')]);

export type GalleryDisplay = z.infer<typeof galleryDisplaySchema>;

export const orderFieldsSchema = z.object({
    orderTitle: z.boolean(),
    orderStatus: z.boolean(),
    shippingAddress: z.boolean(),
    onHoldReason: z.boolean(),
    customAddress: z.boolean(),
    client: z.boolean(),
    factory: z.boolean(),
    mounting: z.boolean(),
    country: z.boolean(),
    employees: z.boolean(),
    deadlineDate: z.boolean(),
    shippingDate: z.boolean(),
    inquiry: z.boolean(),
    relatedOrders: z.boolean(),
    shippingOrders: z.boolean(),
    products: z.boolean(),
    orderSurveyFiles: z.boolean(),
    orderFactoryFiles: z.boolean(),
    orderExternalDesignerFiles: z.boolean(),
    orderFabricationFiles: z.boolean(),
    order3dFiles: z.boolean(),
    globalChat: z.boolean(),
    orderChat: z.boolean(),
    globalNotifications: z.boolean(),
    orderNotifications: z.boolean(),
    orderAllowChange: z.boolean(),
    scheduler: z.boolean(),
    gallery: z.boolean()
});

export type OrderFields = z.infer<typeof orderFieldsSchema>;

export const orderFieldsEnumSchema = z.enum([
    'orderTitle',
    'orderStatus',
    'shippingAddress',
    'onHoldReason',
    'customAddress',
    'client',
    'factory',
    'mounting',
    'country',
    'employees',
    'deadlineDate',
    'shippingDate',
    'inquiry',
    'relatedOrders',
    'shippingOrders',
    'products',
    'orderSurveyFiles',
    'orderFactoryFiles',
    'orderExternalDesignerFiles',
    'orderFabricationFiles',
    'order3dFiles',
    'orderChat',
    'globalChat',
    'orderNotifications',
    'globalNotifications',
    'scheduler',
    'gallery',
    'orderAllowChange'
]);

export type OrderFieldsEnum = z.infer<typeof orderFieldsEnumSchema>;
