import { useEffect, useMemo, useRef, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Button } from '@mui/material';
import { Day, Inject, Month, ScheduleComponent, ViewDirective, ViewsDirective, Week } from '@syncfusion/ej2-react-schedule';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useFetchCalendarInformationQuery, useLazyFetchEventDurationQuery, useLazyFetchJobsQuery } from 'store/api/mounting.api';
import Cover from 'ui-component/Cover';
import EventCreateForm from 'ui-component/modals/mountingScheduler/EventCreateForm';
import EventViewModal from 'ui-component/modals/mountingScheduler/EventViewModal';
import { isUserClient } from 'helpers/users.helper';
import { useTranslation } from 'react-i18next';

interface Props {
    orderId?: number;
    loadButton?: boolean;
    height?: number | undefined;
    weekOnly?: boolean;
    viewOnly?: boolean;
}

function Scheduler({ orderId, loadButton = false, height = 650, weekOnly = false, viewOnly = false }: Props) {
    const { calendarId } = useParams();
    const { user } = useAuth();
    const { t } = useTranslation();

    const { data: crews } = useFetchCalendarInformationQuery(
        { userId: user!.id, calendarId: calendarId },
        { refetchOnMountOrArgChange: true }
    );

    const [fetchJobsQuery, { data: jobs, isFetching: isJobsFetching, isUninitialized: isJobsUninitialized }] = useLazyFetchJobsQuery();

    useEffect(() => {
        if (!loadButton)
            fetchJobsQuery({
                region: crews?.map((item) => parseInt(item.id)),
                userID: user!.id
            });
    }, []);

    useEffect(() => {
        if (loadButton) return;
        fetchJobsQuery({
            region: crews?.map((item) => parseInt(item.id)),
            userID: user!.id
        });
    }, [crews]);

    const [isCalendarReady, setIsCalendarReady] = useState(false);
    const [startEventDate, setStartEventDate] = useState<any>();
    const calendar = useRef(null);

    const [fetchEventDuration] = useLazyFetchEventDurationQuery();

    const cancelEvent = (props: any) => {
        if (!props) return;
        props.cancel = true;
    };

    // True if this role is not allowed to edit scheduler
    const roleReadOnly = isUserClient(user?.role!);

    const cellSelect = (props: any) => {
        if (!jobs) return;
        props.cancel = true;
        if (props.data) {
            fetchEventDuration({ event_id: props.data.Id }).then(({ data }) => {
                if (!data) return;
                const events = data.events;
                let startTime = null;
                let endTime = null;
                if (events.length > 1) {
                    //if multiple days, set start event start date, and end event end date
                    startTime = moment(events[0].start_date).format('YYYY-MM-DD HH:mm');
                    endTime = moment(events[events.length - 1].end_date).format('YYYY-MM-DD HH:mm');
                } else {
                    //if single day, old functionality of just setting time of a single event for that single day
                    startTime = moment(props.data.StartTime).format('YYYY-MM-DD HH:mm');
                    endTime = moment(props.data.EndTime).format('YYYY-MM-DD HH:mm');
                }
                if (props.data) {
                    // Open main editor or event view popup
                    if (props.requestType === 'cellSelect')
                        NiceModal.show(EventCreateForm, { calendarId, endTime, startTime, orderId: orderId });
                    else if (props.requestType === 'eventSelect')
                        NiceModal.show(EventViewModal, {
                            event: { ...props.data, StartTime: startTime, EndTime: endTime },
                            calendarId,
                            readOnly: roleReadOnly
                        });
                }
            });
        }
    };

    const eventRendered = (args: any) => {
        if (orderId && orderId !== args.data.project) {
            args.element.style.opacity = 0.3;
        }
        args.element.style.backgroundColor = args.data.color;
    };

    const getDate = (dates: any[]) => {
        const currentDate = new Date();
        dates.sort((a, b) => a - b);
        // Check if there's only one date
        if (dates.length === 1) {
            return dates[0];
        }

        // Check if all the dates are in the future
        if (dates[0] > currentDate) {
            return dates[0];
        }

        // Check if all the dates are in the past
        if (dates[dates.length - 1] < currentDate) {
            return dates[dates.length - 1];
        }

        // Find the first date in the future
        let i = 0;
        if (dates[dates.length - 1] > currentDate) {
            while (i < dates.length && dates[i] < currentDate) {
                i++;
            }
        }

        // Check if the first date in the future is the closest to the current date
        if (dates[i] === currentDate) {
            return currentDate;
        } else {
            return dates[i];
        }
    };

    useEffect(() => {
        if (!jobs) return;
        if (orderId) {
            const filteredEvents = jobs.events.filter((i) => i.project === orderId);
            const dateArray: Date[] = [];
            filteredEvents.forEach((element) => {
                dateArray.push(new Date(element.StartTime));
            });
            if (filteredEvents && filteredEvents.length > 0 && filteredEvents[0].project !== 0) {
                setStartEventDate(getDate(dateArray));
            }
            setIsCalendarReady(true);
        } else {
            setStartEventDate(new Date());
            setIsCalendarReady(true);
        }
    }, [jobs]);

    return useMemo(() => {
        return (
            <>
                {loadButton && isJobsUninitialized ? (
                    <Box sx={{ height, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                fetchJobsQuery({
                                    region: crews?.map((item) => parseInt(item.id)),
                                    userID: user!.id
                                });
                            }}
                        >
                            {t('pages.mounting_scheduler_page.load_scheduler')}
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ height, overflowY: 'scroll' }}>
                        {isCalendarReady && !isJobsFetching ? (
                            <ScheduleComponent
                                ref={calendar}
                                readonly={roleReadOnly ? true : viewOnly ? true : false}
                                firstDayOfWeek={1}
                                currentView="Week"
                                eventSettings={{ dataSource: jobs?.events }}
                                cellClick={cancelEvent}
                                select={cellSelect}
                                eventClick={cancelEvent}
                                popupOpen={cancelEvent}
                                cellDoubleClick={cancelEvent}
                                moreEventsClick={cancelEvent}
                                eventRendered={eventRendered}
                                onClick={cancelEvent}
                                selectedDate={startEventDate}
                                startHour="08:00"
                                endHour="22:00"
                            >
                                <ViewsDirective>
                                    {!weekOnly && <ViewDirective option="Day" />}
                                    <ViewDirective option="Week" />
                                    {!weekOnly && <ViewDirective option="Month" />}
                                </ViewsDirective>
                                <Inject services={[Day, Week, Month]} />
                            </ScheduleComponent>
                        ) : (
                            <Cover sx={{ height: 650, width: '100%' }} />
                        )}
                    </Box>
                )}
            </>
        );
    }, [jobs?.events, isCalendarReady, isJobsFetching]);
}

export default Scheduler;
