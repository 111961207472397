import { Box, BoxProps } from '@mui/material';

type Props = BoxProps;

const Dot = ({ ...props }: Props) => {
    return (
        <Box
            {...props}
            sx={{
                width: '10px',
                height: '10px',
                display: 'block',
                marginTop: '0px',
                marginBottom: '2px',
                marginLeft: '4px',
                bgcolor: 'red',
                borderRadius: 36,
                '&:hover': { cursor: 'pointer' },
                ...props.sx
            }}
        />
    );
};

export default Dot;
