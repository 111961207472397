import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Box,
    Button,
    Grid2 as Grid,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { t } from 'i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAddCountryMutation, useDeleteCountryMutation, useEditCountryMutation, useFetchCountriesQuery } from 'store/api/system.api';
import Swal from 'sweetalert2';
import { Country, CountrySchema } from 'types/global.types';
import MainCard from 'ui-component/cards/MainCard';
import ModalFormCard from 'ui-component/cards/ModalCard';
import { getMessage } from 'utils/messages';

const SystemCountriesPage = () => {
    const { control, handleSubmit } = useForm<Country>({
        resolver: zodResolver(CountrySchema.omit({ id: true })),
        defaultValues: { abbr: undefined, name: undefined }
    });
    const { t } = useTranslation();

    const { data: countries } = useFetchCountriesQuery({});

    const [addCountry] = useAddCountryMutation();
    const [deleteCountry] = useDeleteCountryMutation();

    const onSubmit: SubmitHandler<Country> = (data) => {
        addCountry({
            country_name: data.name,
            country_abbreviation: data.abbr
        }).then(() => toast.success(getMessage('added')));
    };

    const handleDelete = (countryId: number) => {
        Swal.fire({
            title: t('notifications.swal.title.are_you_sure'),
            text: t('notifications.swal.text.you_wont_be_able_to_revert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('notifications.swal.confirm_button.yes_delete_it')
        }).then((result) => {
            if (result.value) {
                deleteCountry({
                    country_id: countryId
                }).then(() => toast.success(t('notifications.success.deleted')));
            }
        });
    };

    return (
        <MainCard
            title={t('pages.countries_manage_page.title')}
            divider
            dense
            sx={{
                width: '100%'
            }}
            content={false}
            Header={
                <Grid
                    component={'form'}
                    container
                    spacing={2}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        mx: 2,
                        alignItems: 'center'
                    }}
                >
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.countries_manage_page.country_name_field')}
                                    size="small"
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                    required
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.countries_manage_page.country_abbr_field')}
                                    size="small"
                                    sx={{
                                        '.MuiInputBase-inputSizeSmall': { padding: '5px 7px !important' }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    {...field}
                                    error={!!error}
                                    fullWidth
                                    helperText={error?.message}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 4 }}>
                        <Button variant="contained" type="submit" size="small" fullWidth>
                            {t('global.buttons.add_new')}
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pages.countries_manage_page.country_name')}</TableCell>
                            <TableCell>{t('pages.countries_manage_page.country_name_abbr')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {countries &&
                            countries.map((country) => {
                                return (
                                    <TableRow
                                        key={country.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell>{country.name}</TableCell>
                                        <TableCell>{country.abbr}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => NiceModal.show(EditModal, { country })}
                                                    sx={{ width: 80 }}
                                                >
                                                    {t('global.buttons.edit')}
                                                </Button>
                                                <Button variant="contained" color="error" onClick={() => handleDelete(country.id)}>
                                                    {t('global.buttons.delete')}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};

const EditModal = NiceModal.create(({ country }: { country: Country }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<Country>({
        resolver: zodResolver(CountrySchema),
        defaultValues: country
    });
    const [editCountry] = useEditCountryMutation();

    const handleEdit: SubmitHandler<Country> = (data) => {
        editCountry({
            country_id: data.id,
            edited_country_name: data.name,
            edited_abbreviation_name: data.abbr
        }).then(() => toast.success(t('notifications.success.edited')));
        modal.remove();
    };

    return (
        <Modal open={modal.visible} onClose={() => modal.remove()}>
            <ModalFormCard title={t('pages.countries_manage_page.country_edit_modal_title')}>
                <Paper
                    component={'form'}
                    onSubmit={handleSubmit(handleEdit)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: 1
                    }}
                >
                    <Stack gap={2} sx={{ width: '100%' }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.countries_manage_page.country_name_field')}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="abbr"
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    label={t('pages.countries_manage_page.country_abbr_field')}
                                    {...field}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />

                        <Button sx={{ height: 50 }} variant="contained" type="submit">
                            {t('global.buttons.submit')}
                        </Button>
                    </Stack>
                </Paper>
            </ModalFormCard>
        </Modal>
    );
});

export default SystemCountriesPage;
