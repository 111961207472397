import { zodResolver } from '@hookform/resolvers/zod';
import { Edit, Help, Info, Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Autocomplete,
    Avatar,
    Badge,
    Box,
    Button,
    FormControl,
    Grid2 as Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { hideToast } from 'helpers/global.helper';
import { generatePassword, isUserClient, isUserSuperAdmin } from 'helpers/users.helper';
import useAuth from 'hooks/useAuth';
import { supportedLngsArray } from 'i18n';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddUserMutation, useEditUserDataMutation, useFetchMiscQuery, useFetchUserDataQuery } from 'store/api/user.api';
import { gridSpacing } from 'store/constant';
import { ComponentSettings } from 'types/global.types';
import { ClientUserSchema, UserSchema } from 'types/user.types';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import WideTooltip from 'ui-component/extended/WideTooltip';
import { roles } from 'utils/data/roles';
import { z } from 'zod';

export const UserFormSchema = UserSchema.extend({
    currentPassword: z.string().optional(),
    newPassword: z.string().min(4).optional(),
    repeatNewPassword: z.string().min(4).optional(),
    adminPassword: z.string().min(4).optional()
});

export type UserFormType = z.infer<typeof UserFormSchema>;

const defaultValues: UserFormType = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    avatar: '',
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    adminPassword: '',
    language: 'en',
    factories: [],
    countries: [],
    crews: [],
    userOrigin: null,
    role: null
};

type Props = {
    targetUserId?: number;
} & ComponentSettings;

const UserForm = forwardRef<HTMLFormElement, Props>(({ targetUserId, disableToasts }, ref) => {
    const { user } = useAuth();
    const { i18n } = useTranslation();

    const navigate = useNavigate();
    // True if editing another user, false otherwise and null if creating user.
    const isEditingOtherUser = targetUserId ? (targetUserId === user?.id! ? false : true) : null;

    const [passwordGeneratingToggle, setPasswordGeneratingToggle] = useState(false);

    // hidden password states
    const [isAdminPasswordHidden, setIsAdminPasswordHidden] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { data: userData } = useFetchUserDataQuery(
        {
            userId: targetUserId!
        },
        { skip: !targetUserId }
    );

    const { data: miscData } = useFetchMiscQuery(null);
    const [addUser] = useAddUserMutation();
    const [editUser] = useEditUserDataMutation();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        formState: { errors }
    } = useForm<UserFormType>({
        defaultValues,
        resolver: zodResolver(isUserClient(userData?.user_role.id!) ? ClientUserSchema : UserSchema)
    });
    console.log({ errors });

    useEffect(() => {
        if (!userData) return;
        const userValues: UserFormType = {
            username: userData.username,
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.user_email,
            phone: userData.phone_number,
            avatar: userData.avatar,
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            language: userData.language,
            factories: userData.user_factories,
            countries: userData.user_countries,
            crews: userData.user_crews,
            userOrigin: userData.user_origin_country[0],
            role: userData.user_role
        };
        reset(userValues);
    }, [userData]);

    useEffect(() => {
        if (isEditingOtherUser === null) {
            setPasswordGeneratingToggle(true);
            setValue('adminPassword', generatePassword());
        }
    }, []);

    const onSubmit: SubmitHandler<UserFormType> = () => {
        const data = getValues();
        if (isEditingOtherUser === null) {
            toast.promise(
                addUser({ userData: data, userId: user!.id }).then((result) => {
                    if ('data' in result) {
                        navigate(`/user/manage/${result.data}`);
                    } else {
                        // If there is an error in the response, throw an error to trigger the "error" state
                        throw new Error();
                    }
                }),
                {
                    error: t('notifications.error.createError'),
                    pending: t('notifications.pending.createPending'),
                    success: t('notifications.success.created')
                },
                { style: hideToast(disableToasts) }
            );
        } else {
            i18n.changeLanguage(data.language);
            if (!isUserSuperAdmin(user?.role!)) {
                delete data.adminPassword;
            } else {
                if (isEditingOtherUser) {
                    delete data.newPassword;
                    delete data.currentPassword;
                    delete data.repeatNewPassword;
                } else {
                    delete data.adminPassword;
                }
            }
            toast.promise(
                editUser({ targetId: targetUserId!, userData: data, userId: user!.id }),
                {
                    success: t('notifications.success.edited'),
                    error: t('notifications.error.editError'),
                    pending: t('notifications.pending.editPending')
                },
                { style: hideToast(disableToasts) }
            );
        }
    };

    const handleResetAvatar = () => {
        if (!userData?.avatar) setValue('avatar', '');
        else setValue('avatar', userData?.avatar!);
    };

    return (
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)} ref={ref}>
            <Grid container spacing={gridSpacing}>
                <Grid size={{ xs: 12, sm: 6 }}>
                    <SubCard title={t('pages.user_manage_page.edit_account_details')}>
                        <Grid container spacing={gridSpacing}>
                            <Grid size={12}>
                                <Controller
                                    control={control}
                                    name="username"
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('pages.user_manage_page.username_field')}
                                            disabled={isEditingOtherUser !== null}
                                            helperText={
                                                isEditingOtherUser !== null
                                                    ? t('pages.user_manage_page.username_cannot_be_edited')
                                                    : error?.message
                                            }
                                            size="small"
                                            error={!!error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={12}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('global.fields.first_name')}
                                            error={!!error}
                                            helperText={error?.message}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={{ md: 6, xs: 12 }}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('global.fields.last_name')}
                                            error={!!error}
                                            helperText={error?.message}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={{ md: 6, xs: 12 }}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('global.fields.email')}
                                            disabled={targetUserId ? (targetUserId > 2 ? false : true) : false}
                                            error={!!error}
                                            helperText={error?.message}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={{ md: 6, xs: 12 }}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('global.fields.phone_number')}
                                            type="tel"
                                            error={!!error}
                                            helperText={error?.message}
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={{ md: 6, xs: 12 }}>
                                <Controller
                                    name="language"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ minWidth: 120, width: '100%' }} size="small">
                                            <InputLabel>{t('global.fields.language')}</InputLabel>
                                            <Select
                                                value={value}
                                                fullWidth
                                                onChange={(event) => {
                                                    onChange(event.target.value);
                                                }}
                                                label={t('global.fields.language')}
                                            >
                                                {supportedLngsArray.map((language) => (
                                                    <MenuItem value={language.abbr}>{language.title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
                {user?.role! !== 'client' && miscData && (
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <SubCard title={t('pages.user_manage_page.admin_settings')}>
                            <Grid container spacing={gridSpacing}>
                                <Grid size={12}>
                                    <Controller
                                        name="factories"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                                            <Autocomplete
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                                isOptionEqualToValue={(option, value) => option.abbr === value.abbr}
                                                disabled={!isUserSuperAdmin(user?.role!)}
                                                multiple
                                                options={miscData!.all_factories}
                                                getOptionLabel={(data) => data.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('pages.user_manage_page.factories_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <Controller
                                        name="countries"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                multiple
                                                options={miscData!.all_countries}
                                                isOptionEqualToValue={(option, value) => option.abbr === value.abbr}
                                                disabled={!isUserSuperAdmin(user?.role!)}
                                                getOptionLabel={(data) => data.name}
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('pages.user_manage_page.countries_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid size={{ md: 6, xs: 12 }}>
                                    <Controller
                                        name="crews"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.crew_abbreviation === value.crew_abbreviation
                                                }
                                                multiple
                                                disabled={!isUserSuperAdmin(user?.role!) || miscData!.all_crews.length === 0}
                                                options={miscData!.all_crews}
                                                getOptionLabel={(data) => data.crew_name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('pages.user_manage_page.mounting_crews_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={{ md: 6, xs: 12 }}>
                                    <Controller
                                        name="userOrigin"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                                isOptionEqualToValue={(option, value) => option.abbr === value.abbr}
                                                disablePortal
                                                disabled={!isUserSuperAdmin(user?.role!)}
                                                options={miscData!.all_countries}
                                                getOptionLabel={(data) => data.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('pages.user_manage_page.user_origin_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <Controller
                                        name="role"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Autocomplete
                                                value={value}
                                                onChange={(event, value) => onChange(value)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                disablePortal
                                                disabled={isEditingOtherUser ? !isUserSuperAdmin(user?.role!) : false}
                                                options={miscData!.all_roles}
                                                getOptionLabel={(data) => data.title}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('pages.user_manage_page.user_role_field')}
                                                        error={!!error}
                                                        helperText={
                                                            <WideTooltip
                                                                title={
                                                                    <Grid container sx={{ p: 2 }}>
                                                                        {roles.map((role) => (
                                                                            <Grid size={{ xs: 12, sm: 6 }} sx={{ p: 1 }}>
                                                                                <Typography variant="h5" sx={{ color: 'white' }}>
                                                                                    {role.title}
                                                                                </Typography>
                                                                                <Typography sx={{ color: 'white' }}>
                                                                                    {t(
                                                                                        // @ts-ignore
                                                                                        `pages.user_manage_page.role_explanations.${role.id}`
                                                                                    )}
                                                                                </Typography>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                }
                                                            >
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                    <Typography variant="subtitle2">
                                                                        {t('pages.user_manage_page.what_roles_do')}
                                                                    </Typography>
                                                                    <Info sx={{ width: 15, height: 15 }} />
                                                                </Box>
                                                            </WideTooltip>
                                                        }
                                                    />
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                </Grid>
                                {isUserSuperAdmin(user?.role!) && (
                                    <Grid size={12}>
                                        <Button variant="contained" fullWidth size="small" color="error">
                                            {t('pages.user_manage_page.suspend_button')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </SubCard>
                    </Grid>
                )}
                <Grid size={{ xs: 12, sm: 3 }}>
                    <SubCard title={t('pages.user_manage_page.profile_picture')} contentSX={{ textAlign: 'center' }}>
                        <Controller
                            control={control}
                            name="avatar"
                            render={({ field: { value }, fieldState: { isDirty } }) => (
                                <Grid container spacing={2}>
                                    <Grid size={12}>
                                        {isDirty && value instanceof FileList ? (
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <Avatar sx={{ width: 24, height: 24 }} variant="rounded">
                                                        <Tooltip
                                                            title={
                                                                <Typography>
                                                                    {t('pages.user_manage_page.avatar_is_edited')},{' '}
                                                                    <Typography
                                                                        display={'inline'}
                                                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                                        onClick={handleResetAvatar}
                                                                    >
                                                                        {t('global.buttons.reset')}
                                                                    </Typography>
                                                                </Typography>
                                                            }
                                                        >
                                                            <Edit sx={{ color: 'black', p: 0.1 }} />
                                                        </Tooltip>
                                                    </Avatar>
                                                }
                                            >
                                                <Avatar
                                                    src={URL.createObjectURL(value[0])}
                                                    sx={{ width: 100, height: 100, margin: '0 auto' }}
                                                />
                                            </Badge>
                                        ) : (
                                            <Avatar src={value as string} sx={{ width: 100, height: 100, margin: '0 auto' }} />
                                        )}
                                    </Grid>
                                    <Grid size={12}>
                                        <Typography variant="subtitle2" align="center">
                                            {t('pages.user_manage_page.upload_change')}
                                        </Typography>
                                    </Grid>
                                    <Grid size={12}>
                                        <AnimateButton>
                                            <Button component="label" variant="contained">
                                                {t('pages.user_manage_page.upload_avatar_button')}
                                                <input accept="image/*" type="file" {...register(`avatar`)} hidden />
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </SubCard>
                </Grid>

                <Grid size={{ xs: 12, sm: 4 }}>
                    <SubCard title={t('pages.user_manage_page.security')}>
                        <Grid container spacing={gridSpacing}>
                            {(isEditingOtherUser !== null &&
                                (isUserSuperAdmin(user?.role!) && isEditingOtherUser ? (
                                    <>
                                        <Grid size={6}>
                                            <AnimateButton>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={() => {
                                                        setPasswordGeneratingToggle(true);
                                                        setValue('adminPassword', generatePassword());
                                                    }}
                                                >
                                                    {t('pages.user_manage_page.set_new_password')}
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        {passwordGeneratingToggle && (
                                            <Grid size={6}>
                                                <AnimateButton>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        onClick={() => {
                                                            setPasswordGeneratingToggle(false);
                                                            setValue('adminPassword', '');
                                                        }}
                                                    >
                                                        {t('global.buttons.cancel')}
                                                    </Button>
                                                </AnimateButton>
                                            </Grid>
                                        )}
                                        {passwordGeneratingToggle && (
                                            <Grid size={12}>
                                                <Controller
                                                    name="adminPassword"
                                                    control={control}
                                                    render={({ field, fieldState: { error } }) => (
                                                        <TextField
                                                            {...field}
                                                            type={isAdminPasswordHidden ? 'password' : 'text'}
                                                            fullWidth
                                                            label={t('global.fields.password')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => setIsAdminPasswordHidden((old) => !old)}
                                                                        >
                                                                            {isAdminPasswordHidden ? <Visibility /> : <VisibilityOff />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Grid size={12}>
                                            <Controller
                                                name="currentPassword"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type={showPassword ? 'text' : 'password'}
                                                        label={t('pages.user_manage_page.current_password_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        size="small"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        onMouseDown={(event) => event.preventDefault()!}
                                                                        edge="end"
                                                                        size="large"
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid size={6}>
                                            <Controller
                                                name="newPassword"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <TextField
                                                        {...field}
                                                        type={showPassword ? 'text' : 'password'}
                                                        fullWidth
                                                        label={t('pages.user_manage_page.new_password_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid size={6}>
                                            <Controller
                                                name="repeatNewPassword"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <TextField
                                                        {...field}
                                                        type="password"
                                                        fullWidth
                                                        label={t('pages.user_manage_page.reenter_new_password_field')}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid size={12}>
                                            <AnimateButton>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    size="small"
                                                    endIcon={
                                                        <Tooltip title={t('pages.user_manage_page.did_you_lose')}>
                                                            <Help sx={{ my: 'auto' }} />
                                                        </Tooltip>
                                                    }
                                                >
                                                    {t('pages.user_manage_page.log_out_everywhere_else')}
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </>
                                ))) || (
                                <>
                                    <Grid size={12}>
                                        <AnimateButton>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    setValue('adminPassword', generatePassword());
                                                }}
                                            >
                                                {t('pages.user_manage_page.set_new_password')}
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                    {passwordGeneratingToggle && (
                                        <Grid size={12}>
                                            <Controller
                                                name="adminPassword"
                                                control={control}
                                                render={({ field, fieldState: { error } }) => (
                                                    <TextField
                                                        {...field}
                                                        type={isAdminPasswordHidden ? 'password' : 'text'}
                                                        fullWidth
                                                        label={t('global.fields.password')}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => setIsAdminPasswordHidden((old) => !old)}
                                                                    >
                                                                        {isAdminPasswordHidden ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        </Box>
    );
});

export default UserForm;
