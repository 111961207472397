import { z } from 'zod';
import { nullableAndRefine } from './global.types';
import { crewSchema } from './mounting.types';

export const UserSchema = z.object({
    username: z.string().min(1),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().min(1).email(),
    phone: z.string(),
    avatar: z.union([z.string(), z.instanceof(FileList)]).nullable(),
    factories: z.array(z.object({ id: z.number(), abbr: z.string(), name: z.string() })).min(1),
    crews: z.array(crewSchema),
    language: z.union([z.literal('en'), z.literal('nb'), z.literal('de')]),
    countries: z.array(z.object({ id: z.number(), abbr: z.string(), name: z.string() })).min(1),
    role: nullableAndRefine(z.object({ id: z.string(), title: z.string() })),
    userOrigin: nullableAndRefine(z.object({ id: z.number(), abbr: z.string(), name: z.string() }))
});

export type User = z.infer<typeof UserSchema>;

export const ClientUserSchema = z.object({
    username: z.string().min(2),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email(),
    phone: z.string().optional().nullable(),
    avatar: z.union([z.string(), z.instanceof(FileList)]).nullable(),
    role: nullableAndRefine(z.object({ id: z.string(), title: z.string() }))
});

export type ClientUser = z.infer<typeof UserSchema>;

export interface MinimalUser {
    id: number;
    username: string;
    email: string;
    notification_emails_disabled: boolean;
    real_name: string;
    role: RoleId;
    unread_notifications: number;
}

export const roleTitleSchema = z.union([
    z.literal('Country Manager'),
    z.literal('Designer'),
    z.literal('Designer Admin'),
    z.literal('Factory Manager'),
    z.literal('Factory Worker'),
    z.literal('Manager'),
    z.literal('Mounting Worker'),
    z.literal('Super Admin'),
    z.literal('Worker'),
    z.literal('Client')
]);

export type RoleTitle = z.infer<typeof roleTitleSchema>;

export const roleIdSchema = z.union([
    z.literal('country_manager'),
    z.literal('designer'),
    z.literal('designer_admin'),
    z.literal('factory_manager'),
    z.literal('factory_worker'),
    z.literal('manager'),
    z.literal('mounting_worker'),
    z.literal('super_admin'),
    z.literal('worker'),
    z.literal('administrator'),
    z.literal('client')
]);

export type RoleId = z.infer<typeof roleIdSchema>;

export const roleSchema = z.object({
    title: roleTitleSchema,
    id: roleIdSchema
});

export type Role = z.infer<typeof roleSchema>;

export const roles: Role[] = [
    { title: 'Country Manager', id: 'country_manager' },
    { title: 'Designer', id: 'designer' },
    { title: 'Designer Admin', id: 'designer_admin' },
    { title: 'Factory Manager', id: 'factory_manager' },
    { title: 'Factory Worker', id: 'factory_worker' },
    { title: 'Manager', id: 'manager' },
    { title: 'Mounting Worker', id: 'mounting_worker' },
    { title: 'Super Admin', id: 'super_admin' },
    { title: 'Worker', id: 'worker' }
];
