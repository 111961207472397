import NiceModal from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add, Close } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    FormHelperText,
    FormLabel,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFetchClientsQuery } from 'store/api/clients.api';
import { useCreateOrderMutation } from 'store/api/orders.api';
import { useFetchUserFactoriesQuery } from 'store/api/system.api';
import { Nullable } from 'types/global.types';
import { OrderCreate, orderCreateSchema } from 'types/orders.types';
import SubCard from 'ui-component/cards/SubCard';
import Loader from 'ui-component/Loader';
import { orderStatuses } from 'utils/data/orders';
import { getMessage } from 'utils/messages';
import CompanyForm from '../clients/CompanyForm';
import NaturalPersonForm from '../clients/NaturalPersonForm';
import { useTranslation } from 'react-i18next';

const OrderCreateForm = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { handleSubmit, watch, control, setValue } = useForm<Nullable<OrderCreate>>({
        defaultValues: {
            orderTitle: '',
            orderStatus: null,
            client: null,
            clientId: null,
            factory: null,
            factoryId: null,
            mounting: 'supply_only',
            shippingAddress: 'storage',
            customAddress: null,
            onHoldReason: null
        },
        resolver: zodResolver(orderCreateSchema)
    });
    const onSubmit: SubmitHandler<OrderCreate> = (data) => {
        if (!user) return;
        toast
            .promise(createOrder({ ...data, user_id: user.id }), { pending: getMessage('createPending'), success: getMessage('created') })
            .then((response) => {
                if ('data' in response) {
                    navigate(`/order/${response.data.order_id}`);
                }
                NiceModal.remove('order-create-modal');
            });
    };

    const orderStatus = watch('orderStatus');
    const shippingAddress = watch('shippingAddress');

    const { data: clients, isLoading: isClientsLoading, isFetching: isClientsFetching } = useFetchClientsQuery(null);
    const { data: factories } = useFetchUserFactoriesQuery({ userId: user?.id! }, { skip: !user });
    const [createOrder] = useCreateOrderMutation();

    const [clientTabIndex, setClientTabIndex] = useState(0);
    const [clientCreationEnabled, setClientCreationEnabled] = useState(false);
    const [firstFetch, setFirstFetch] = useState(true);

    useEffect(() => {
        if (!clients) return;

        if (firstFetch === true) {
            setFirstFetch(false);
            return;
        } else {
            setClientCreationEnabled(false);
            setValue('client', clients[clients.length - 1]);
        }
    }, [clients]);

    return (
        <Stack direction={{ xs: 'column-reverse', md: 'row' }} gap={2}>
            <Stack
                component={'form'}
                // @ts-ignore
                onSubmit={handleSubmit(onSubmit)}
                sx={{ width: '100%', height: '100%', maxWidth: { md: 250 } }}
                gap={2}
            >
                <Controller
                    control={control}
                    name="orderTitle"
                    render={({ field, fieldState: { error } }) => (
                        <TextField {...field} label="Title" size={'small'} error={!!error} helperText={error?.message} />
                    )}
                />
                <Controller
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Autocomplete
                            value={value}
                            onChange={(event, value) => onChange(value)}
                            options={orderStatuses}
                            isOptionEqualToValue={(option) => option.id === value?.id}
                            // @ts-ignore
                            getOptionLabel={(option) => t(`pages.order_manage_page.main_details.status.${option.id}`)}
                            getOptionDisabled={(option) => option.id === 'supplier_confirmed' || option.id === 'supplier_request'}
                            renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth label="Status" error={!!error} helperText={error?.message} />
                            )}
                        />
                    )}
                    name="orderStatus"
                />

                {orderStatus?.id === 'confirmed' && (
                    <Controller
                        control={control}
                        name="shippingAddress"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Stack gap={0.5}>
                                <FormLabel error={!!error}>Shipping Address</FormLabel>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    sx={{ width: '100%', whiteSpace: 'nowrap' }}
                                    value={value}
                                    onChange={(event, value) => onChange(value)}
                                >
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="storage">
                                        Storage
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="client">
                                        Client
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: '100%' }} fullWidth value="custom">
                                        Custom
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                            </Stack>
                        )}
                    />
                )}
                {orderStatus?.id === 'on_hold' && (
                    <Controller
                        control={control}
                        name="onHoldReason"
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="On Hold Reason"
                                placeholder="Logistics problems"
                                size="small"
                                helperText={error?.message}
                                error={!!error}
                            />
                        )}
                    />
                )}

                {orderStatus?.id === 'confirmed' && shippingAddress === 'custom' && (
                    <Controller
                        control={control}
                        name="customAddress"
                        render={({ field, fieldState: { error } }) => (
                            <TextField {...field} label="Custom Shipping Address" placeholder="London" size="small" fullWidth />
                        )}
                    />
                )}

                {clients ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Controller
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Autocomplete
                                    value={value}
                                    options={clients}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(event, value) => {
                                        onChange(value);
                                    }}
                                    sx={{ width: '100%' }}
                                    renderInput={(options) => (
                                        <TextField
                                            {...options}
                                            label="Client"
                                            size="small"
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                            name="client"
                        />
                        <Box bgcolor={'lightgreen'} sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                            <Tooltip title="Add new representative">
                                <IconButton size="small" onClick={() => setClientCreationEnabled((value) => !value)}>
                                    {clientCreationEnabled ? <Close /> : <Add />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                ) : (
                    <Skeleton width={'100%'} height={40} />
                )}
                {factories ? (
                    <Controller
                        control={control}
                        name="factory"
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <Autocomplete
                                value={value}
                                options={factories}
                                onChange={(event, value) => {
                                    onChange(value);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderInput={(options) => (
                                    <TextField
                                        {...options}
                                        label="Factory"
                                        size="small"
                                        fullWidth
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        )}
                    />
                ) : (
                    <Skeleton width={'100%'} height={40} />
                )}
                <Controller
                    control={control}
                    name="mounting"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={value}
                                exclusive
                                onChange={onChange}
                                sx={{ width: '100%', whiteSpace: 'nowrap' }}
                            >
                                <ToggleButton sx={{ width: '100%' }} fullWidth value="supply_only">
                                    Supply Only
                                </ToggleButton>
                                <ToggleButton sx={{ width: '100%' }} fullWidth value="mounting_by_us">
                                    Mounting by us
                                </ToggleButton>
                            </ToggleButtonGroup>
                            {/* {value === 'mounting_by_us' && (
                            <IconButton onClick={() => NiceModal.show(EventCreateForm, {})}>
                                <Add />
                            </IconButton>
                        )} */}
                        </Box>
                    )}
                />
                <Button variant="contained" size="small" type="submit">
                    Save
                </Button>
            </Stack>
            {clientCreationEnabled && (
                <>
                    {isClientsLoading || isClientsFetching ? (
                        <Loader />
                    ) : (
                        <SubCard
                            title="Create Client"
                            sx={{ width: { md: 600 } }}
                            secondary={
                                <ToggleButtonGroup value={clientTabIndex} exclusive onChange={(event, value) => setClientTabIndex(value)}>
                                    <ToggleButton size="small" value={0}>
                                        Person
                                    </ToggleButton>
                                    <ToggleButton size="small" value={1}>
                                        Business
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            }
                        >
                            {clientTabIndex === 0 ? (
                                <NaturalPersonForm isMinimal={true} isCompaniesEnabled={false} />
                            ) : (
                                <CompanyForm isMinimal={true} isRepresentativesEnabled={false} />
                            )}
                        </SubCard>
                    )}
                </>
            )}
        </Stack>
    );
};

export default OrderCreateForm;
