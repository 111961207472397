import { Edit, Launch, PlayArrow } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, BoxProps, Chip, Paper, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useLightbox from 'hooks/useLightbox';
import { atom, useAtom } from 'jotai';
import { Masonry as MasonicMasonry } from 'masonic';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, RowsChangeData, SelectColumn, SortColumn } from 'react-data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { GalleryDisplay, GalleryFile } from 'types/orders.types';
import FileIcon from 'ui-component/files/FileIcon';
import { useImmer } from 'use-immer';

export const gallerySelectedImagesAtom = atom<undefined | ReadonlySet<number>>(undefined);

interface Props extends BoxProps {
    images: GalleryFile[];
    isImagesFetching?: boolean;
    onDelete?: (imageIds: number[]) => void;
    onEdit?: (imageId: number) => void;
    display?: GalleryDisplay;
    onRowsChange?: (data: GalleryFile[], edited: RowsChangeData<GalleryFile>) => void;
    categories?: string[];
    rowsEditEnabled?: boolean;
    additionalColumn?: Column<GalleryFile>;
}

const Gallery = (props: Props) => {
    return <>{props.display === 'list' ? <List {...props} /> : <Masonry {...props} />}</>;
};

const Masonry = ({ images, onDelete, onEdit, ...props }: Props) => {
    const { show } = useLightbox();
    const { t } = useTranslation();

    const MasonryItem = ({ index, data }: { index: number; data: GalleryFile }) => {
        const [isHovered, setIsHovered] = React.useState(false);

        const deleteFile = (id: number) => {
            Swal.fire({
                title: t('notifications.swal.title.are_you_sure'),
                text: t('pages.gallery_page.this_will_delete_selected_file'),
                icon: 'warning',
                showCancelButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    onDelete!([id]);
                }
            });
        };

        return (
            <Box
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    '&:hover': {
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    }
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {data.type === 'image' ? (
                    <MasonryImage data={data} index={index} />
                ) : data.type === 'video' ? (
                    <MasonryVideo data={data} index={index} />
                ) : (
                    <>{t('pages.gallery_page.file_type_not_supported')}</>
                )}
                {isHovered && (
                    <Paper
                        sx={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            transition: 'opacity 100ms ease-in-out',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 0.5,
                            m: 1
                        }}
                        elevation={4}
                    >
                        {data.title && <Chip label={data.title} size="small" variant="outlined" sx={{ my: 'auto', ml: 1 }} />}
                        {data.link && (
                            <Link to={data.link}>
                                <IconButton size="medium" edge="start" sx={{ m: 0 }} color="primary">
                                    <Launch fontSize="inherit" />
                                </IconButton>
                            </Link>
                        )}
                        {onEdit && (
                            <IconButton
                                size="medium"
                                edge="start"
                                sx={{ m: 0 }}
                                color="primary"
                                onClick={() => {
                                    onEdit(data.id);
                                }}
                            >
                                <Edit fontSize="inherit" />
                            </IconButton>
                        )}
                        {onDelete && (
                            <IconButton
                                size="medium"
                                edge="start"
                                sx={{ m: 0 }}
                                color="error"
                                onClick={() => {
                                    deleteFile(data.id);
                                }}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        )}
                    </Paper>
                )}
            </Box>
        );
    };

    const MasonryImage = ({ index, data }: { index: number; data: GalleryFile }) => {
        return (
            <Box
                component={'img'}
                sx={{
                    width: '100%',
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 1,
                    display: 'block',
                    transition: 'transform 400ms ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)'
                    },
                    '&:not(:hover)': {
                        transform: 'scale(1)'
                    }
                }}
                src={data.thumbnail || data.src}
                alt={data.name}
                style={{ cursor: 'pointer' }}
                onClick={() => show(images.map((gallery) => gallery.src)!, { index })}
            />
        );
    };

    const MasonryVideo = ({ index, data }: { index: number; data: GalleryFile }) => {
        return (
            <>
                <Box
                    component={'video'}
                    sx={{
                        width: '100%',
                        borderTopLeftRadius: 1,
                        borderTopRightRadius: 1,
                        display: 'block',
                        transition: 'transform 400ms ease-in-out',
                        '&:hover': {
                            transform: 'scale(1.1)'
                        },
                        '&:not(:hover)': {
                            transform: 'scale(1)'
                        }
                    }}
                    width="100%"
                    height="100%"
                    src={data.thumbnail || data.src}
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => {
                        event.preventDefault();
                        show(images.map((gallery) => gallery.src)!, { index });
                    }}
                >
                    <source src={data.src} type="video/mp4" />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        pointerEvents: 'none'
                    }}
                >
                    <PlayArrow
                        sx={{
                            width: 100,
                            height: 100,
                            color: 'White',
                            filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                        }}
                    />
                </Box>
            </>
        );
    };

    return (
        <Box {...props} sx={{ ...props.sx, height: 'initial' }}>
            {images.length > 0 ? (
                <MasonicMasonry items={images} columnGutter={8} columnWidth={300} overscanBy={2} render={MasonryItem} key={images.length} />
            ) : (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {t('pages.gallery_page.no_images_found')}
                </Box>
            )}
        </Box>
    );
};

const List = ({ images, isImagesFetching, onRowsChange, categories, additionalColumn, rowsEditEnabled, ...props }: Props) => {
    const { show } = useLightbox();
    const { t } = useTranslation();

    const [selectedImages, setSelectedImages] = useAtom(gallerySelectedImagesAtom);

    const column: readonly Column<GalleryFile>[] = [
        {
            ...SelectColumn,
            width: 35,
            minWidth: 35
        },
        {
            key: 'src',
            name: t('pages.gallery_page.image'),
            width: 100,
            minWidth: 100,
            frozen: true,
            cellClass: 'reactDataGridCellDisablePadding',
            formatter(props) {
                const value = props.row;
                if (value.type === 'video')
                    return (
                        <>
                            <Box
                                component={'video'}
                                sx={{
                                    width: '100%',
                                    borderTopLeftRadius: 1,
                                    borderTopRightRadius: 1,
                                    display: 'block',
                                    transition: 'transform 400ms ease-in-out',
                                    objectFit: 'cover',
                                    '&:hover': {
                                        transform: 'scale(1.1)'
                                    },
                                    '&:not(:hover)': {
                                        transform: 'scale(1)'
                                    }
                                }}
                                width="100%"
                                height="100%"
                                src={value.thumbnail || value.src}
                                style={{ cursor: 'pointer' }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    show(images.map((gallery) => gallery.src)!, { index: props.rowIdx });
                                }}
                            >
                                <source src={value.src} type="video/mp4" />
                            </Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '60%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents: 'none'
                                }}
                            >
                                <PlayArrow
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        color: 'White',
                                        filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'
                                    }}
                                />
                            </Box>
                        </>
                    );
                else if (value.type === 'image')
                    return (
                        <Tooltip title={value.name} placement="top">
                            <Box
                                component={'img'}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    mx: 'auto',
                                    borderTopLeftRadius: 1,
                                    borderTopRightRadius: 1,
                                    display: 'block',
                                    transition: 'transform 400ms ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.1)'
                                    },
                                    '&:not(:hover)': {
                                        transform: 'scale(1)'
                                    }
                                }}
                                src={value.thumbnail || value.src}
                                alt={value.name}
                                style={{ cursor: 'pointer' }}
                                onClick={() => show(images.map((gallery) => gallery.src)!, { index: props.rowIdx })}
                            />
                        </Tooltip>
                    );
                else
                    return (
                        <Tooltip title={value.name} placement="top">
                            {/* <Box
                                component={'img'}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    mx: 'auto',
                                    borderTopLeftRadius: 1,
                                    borderTopRightRadius: 1,
                                    display: 'block',
                                    transition: 'transform 400ms ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.1)'
                                    },
                                    '&:not(:hover)': {
                                        transform: 'scale(1)'
                                    }
                                }}
                                src={value.thumbnail || value.src}
                                alt={value.name}
                                style={{ cursor: 'pointer' }}
                                onClick={() => show(images.map((gallery) => gallery.src)!, { index: props.rowIdx })}
                            /> */}
                            <FileIcon {...value} live={true} sx={{ width: '100%', height: '100%' }} />
                        </Tooltip>
                    );
            }
        },
        {
            key: 'text',
            name: t('pages.gallery_page.description'),
            resizable: false,
            frozen: true,
            sortable: true,
            cellClass: 'reactDataGridCellDisablePadding ',
            editable: isImagesFetching ? false : true,
            editorOptions: { editOnClick: rowsEditEnabled },
            editor: (props) => {
                const calculatedRows = 2 + (props.row.categories.length - 1) * 1.9;
                return (
                    <TextField
                        multiline
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.stopPropagation();
                            }
                        }}
                        rows={calculatedRows > 2 ? calculatedRows : 2}
                        value={props.row.text}
                        onChange={(event) => {
                            props.onRowChange({ ...props.row, text: event.currentTarget.value });
                        }}
                        InputProps={{
                            sx: {
                                my: 'auto'
                            }
                        }}
                        sx={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            '& .MuiInputBase-root': { borderRadius: 0 },
                            '& fieldset': { borderRadius: 0 },
                            bgcolor: 'transparent',
                            resize: 'none',
                            '& textarea': { p: 0.5 }
                        }}
                    />
                );
            },

            formatter(props) {
                const value = props.row;
                const calculatedRows = 2 + (props.row.categories.length - 1) * 1.9;

                return (
                    <TextField
                        multiline
                        rows={calculatedRows > 2 ? calculatedRows : 2}
                        value={value.text}
                        InputProps={{ readOnly: true, sx: { my: 'auto' } }}
                        sx={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            outline: 'none',
                            bgcolor: 'transparent',
                            resize: 'none',
                            '&:focus-visible': { border: 'none', outline: 'none' },
                            '&:focus': { border: 'none', outline: 'none' },
                            '& textarea': { p: 0.5 },
                            '& .MuiInputBase-root': { borderRadius: 0 },
                            '& fieldset': { borderRadius: 0 }
                        }}
                    />
                );
            }
        },
        {
            key: 'categories',
            name: t('pages.gallery_page.categories'),
            width: 300,
            resizable: false,
            frozen: true,
            sortable: false,
            editorOptions: { editOnClick: rowsEditEnabled },
            editable: rowsEditEnabled && !isImagesFetching ? true : false,
            editor: (props) => (
                <Autocomplete
                    freeSolo
                    multiple
                    value={props.row.categories}
                    onChange={(event, value) => {
                        props.onRowChange({ ...props.row, categories: JSON.parse(JSON.stringify(value)) });
                    }}
                    disableCloseOnSelect
                    noOptionsText={t('pages.gallery_page.no_categories_found')}
                    getOptionLabel={(option) => option}
                    options={categories || []}
                    sx={{
                        display: 'initial'
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                height: '100%',
                                '& input': { height: '100%' },
                                '& .MuiInputBase-root': { borderRadius: 0 },
                                '& fieldset': { borderRadius: 0 },
                                '& .MuiAutocomplete-inputRoot': {
                                    flexWrap: 'nowrap !important'
                                }
                            }}
                            InputProps={{ ...params.InputProps, sx: { height: '100%' } }}
                            inputProps={{
                                ...params.inputProps,

                                onKeyDown: (event) => {
                                    if (event.key === 'Enter') {
                                        const value = event.currentTarget.value;
                                        event.stopPropagation();
                                        event.preventDefault();
                                        if (!props.row.categories.includes(value) && value !== '') {
                                            props.onRowChange({
                                                ...props.row,
                                                categories: JSON.parse(JSON.stringify([...props.row.categories, value]))
                                            });
                                            event.currentTarget.value = '';
                                        }
                                    }
                                }
                            }}
                        />
                    )}
                    renderTags={(value: readonly string[], getTagProps) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                maxWidth: '70%',
                                height: '100%',
                                overflow: 'auto',
                                my: 'auto'
                            }}
                        >
                            {[...value].reverse().map((option: string, index: number) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    size="small"
                                    {...getTagProps({ index: value.length - 1 - index })}
                                />
                            ))}
                        </Box>
                    )}
                />
            ),
            formatter(props) {
                const value = props.row;
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            my: 2,
                            gap: 2,
                            overflow: 'hidden'
                        }}
                    >
                        {value.categories.map((category) => (
                            <Chip size="small" variant="outlined" sx={{ lineHeight: 19 }} label={category} />
                        ))}
                    </Box>
                );
            }
        },
        {
            name: 'Additional',
            key: t('pages.gallery_page.additional'),
            width: 0,
            minWidth: 0,
            ...additionalColumn
        }
    ];

    function createRows(): GalleryFile[] {
        const rows: GalleryFile[] = [];

        for (let i = 0; i < images.length; i++) {
            rows.push(images[i]);
        }

        return rows;
    }

    const initialTableData: GalleryFile[] = createRows();
    const [filteredData, setFilteredData] = useImmer<GalleryFile[]>(initialTableData);

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
        setSortColumns(sortColumns.slice(-1));
    }, []);

    const sortedRows = useMemo((): readonly GalleryFile[] => {
        if (sortColumns.length === 0) return filteredData;
        const { columnKey, direction } = sortColumns[0];

        let sortedRows: GalleryFile[] = [...filteredData];

        switch (columnKey) {
            case 'name':
                sortedRows = sortedRows.sort((a, b) => {
                    return a['name'].localeCompare(b['name']);
                });
                break;
            case 'src':
                sortedRows = sortedRows.sort((a, b) => {
                    return a[columnKey].localeCompare(b[columnKey]);
                });
                break;
            default:
        }
        return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
    }, [filteredData, sortColumns]);

    useEffect(() => {
        setSelectedImages((): ReadonlySet<number> => new Set());
    }, []);

    useEffect(() => {
        setFilteredData(createRows());
    }, [images]);

    const rowKeyGetter = (row: GalleryFile) => {
        return row.id;
    };

    return (
        <Box {...props}>
            <DataGrid
                rowHeight={(props) => {
                    let calculatedHeight = 0;
                    const data: GalleryFile = props.row as GalleryFile;
                    calculatedHeight = 32 + data.categories.length * (24 + 16) - 16;
                    if (calculatedHeight > 56) return calculatedHeight;
                    else return 56;
                }}
                style={{
                    height: '100%',
                    overflowY: 'scroll'
                }}
                columns={column}
                rows={sortedRows}
                onRowsChange={onRowsChange}
                selectedRows={selectedImages}
                onSelectedRowsChange={setSelectedImages}
                rowKeyGetter={rowKeyGetter}
                className="fill-grid rdg-light"
                sortColumns={sortColumns}
                onSortColumnsChange={onSortColumnsChange}
            />
        </Box>
    );
};

export default Gallery;
