import { NavItemType } from 'types/index.types';
import {
    Factory,
    Person,
    Public,
    Settings,
    LocalShipping,
    Notes,
    Group,
    Visibility,
    AlternateEmail,
    CalendarMonth
} from '@mui/icons-material';

import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const UsersTablePage = Loadable(lazy(() => import('views/pages/users/UsersTablePage')));
const SystemFactoriesPage = Loadable(lazy(() => import('views/pages/system/SystemFactoriesPage')));
const SystemCountriesPage = Loadable(lazy(() => import('views/pages/system/SystemCountriesPage')));
const SystemMountingCrewsPage = Loadable(lazy(() => import('views/pages/system/SystemMountingCrewsPage')));
const SystemProductsPage = Loadable(lazy(() => import('views/pages/system/SystemProductsPage')));
const SystemVersionLogPage = Loadable(lazy(() => import('views/pages/system/SystemVersionPage')));
const SystemOrderVisibilityPage = Loadable(lazy(() => import('views/pages/system/SystemOrdersVisibilityPage')));
const SystemEmailSettingsPage = Loadable(lazy(() => import('views/pages/system/SystemEmailSettingsPage')));

const systemMenuItems: NavItemType = {
    id: 'system',
    title: undefined,
    icon: Settings,
    type: 'group',
    url: '/system',
    children: [
        {
            id: 'factories',
            title: 'components.menu.system.factories',
            type: 'item',
            url: '/system/factories',
            component: <SystemFactoriesPage />,
            icon: Factory,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'countries',
            title: 'components.menu.system.factories',
            type: 'item',
            url: '/system/countries',
            component: <SystemCountriesPage />,
            icon: Public,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'mountingCrews',
            title: 'components.menu.system.mounting_crews',
            type: 'item',
            url: '/system/mounting',
            component: <SystemMountingCrewsPage />,
            icon: Group,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'components.menu.system.users',
            icon: Person,
            type: 'item',
            url: '/users',
            breadcrumbs: false,
            isAdminOnly: true,
            component: <UsersTablePage />
        },
        {
            id: 'products',
            title: 'components.menu.system.products',
            type: 'item',
            url: '/system/products',
            component: <SystemProductsPage />,
            icon: LocalShipping,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'version',
            title: 'components.menu.system.version_log',
            type: 'item',
            url: '/system/version',
            component: <SystemVersionLogPage />,
            icon: Notes,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'orderVisability',
            title: 'components.menu.system.order_visibility',
            type: 'item',
            url: '/system/orders/visability',
            component: <SystemOrderVisibilityPage />,
            icon: Visibility,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'emailSettings',
            title: 'components.menu.system.email_settings',
            type: 'item',
            url: '/system/emails',
            component: <SystemEmailSettingsPage />,
            icon: AlternateEmail,
            isAdminOnly: true,
            breadcrumbs: false
        },
        {
            id: 'subscription',
            title: 'components.menu.system.subscription',
            type: 'item',
            external: true,
            url:
                process.env.NODE_ENV === 'development'
                    ? 'http://localhost:5173/login?redirect=false'
                    : `https://my.scaleerp.com/login?redirect=false`,
            icon: CalendarMonth,
            hidden: JSON.parse(process.env.REACT_APP_SALES_ENABLED!) === false,
            isAdminOnly: true,
            breadcrumbs: false
        }
    ]
};

export default systemMenuItems;
