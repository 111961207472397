export const roles = [
    {
        id: 'super_admin',
        title: 'Super Admin'
    },
    {
        id: 'country_manager',
        title: 'Country Manager'
    },
    {
        id: 'factory_worker',
        title: 'Factory Worker'
    },
    {
        id: 'designer_admin',
        title: 'Designer Admin'
    },
    {
        id: 'client',
        title: 'Client'
    },
    {
        id: 'other',
        title: 'All Other Roles'
    }
];
