import { Person2 } from '@mui/icons-material';
import { lazy } from 'react';
import { NavItemType } from 'types/index.types';
import Loadable from 'ui-component/Loadable';

const ClientsTablePage = Loadable(lazy(() => import('views/pages/clients/ClientsTablePage')));

const clients: NavItemType = {
    id: 'clients',
    title: 'components.menu.clients',
    icon: Person2,
    type: 'item',
    url: '/clients',
    breadcrumbs: false,
    component: <ClientsTablePage />,
    probhitedRoles: ['client', 'mounting_worker']
};

export default clients;
